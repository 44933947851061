import React from "react";

function Breadcrumb({ pageName, pageTitle, id, img_url, mobile_banner }) {
  return (
    <>
      <section className="bannerSection">
        <div
          id="bannerSection-Carousel"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="item active">
              <img
                src={mobile_banner}
                alt="banner-img"
                className="mob__banner"
              />
              {/*<img src="img/Footballstadium.jpg" alt="banner-img" class="other__banner" /> */}
              <img src={img_url} alt="banner-img" className="other__banner" />
              <div className="container">
                <div className="bannercarousel carousel-caption">
                  <div className="carousel-caption__area animated bounceInRight slower">
                    <div className="carousel-caption__areaInner">
                      <h2 className="carousel-caption__areaTitle">
                        {/* हमारे बारे में{" "} */}
                      </h2>
                    </div>
                    {/* <p class="carousel-caption__areaText">
                      It is a multi-purpose International sports complex in
                      Bhubaneswar. It has facilities for athletics, football,
                      hockey, tennis, basketball, Sports climbing, and swimming.
                      It has hosted many marquee events and is the hub of many
                      High Performance Centers.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Breadcrumb;
