import { SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import styled from "./style.module.css";
import styled from "../tenders/style.module.css";
import Container from "react-bootstrap/Container";
import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
import { Helmet } from "react-helmet";
const NoticesCirculars = () => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      // const res = await api.fetchData("/noticescirculars");
      const res = await api.fetchData("/noticescirculars");

      setData(res);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);


  // {
  //   "id": 22,
  //   "name": "BSSA NEWSLETTER JUNE",
  //   "date": "",
  //   "expiryDate": "",
  //   "url": "img/notices/BSSA_NEWSLETTER_JUNE.pdf",
  //   "img": ""
  // },

  return (
    // <div>
    <div className={styled.Container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notices and Circulars | Bihar State Sports Authority</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की सूचनाएं: इस पेज पर विभिन्न खेल संबंधी सूचनाएं और अधिसूचनाएं उपलब्ध हैं। यहां पर नवीनतम जानकारी और महत्वपूर्ण घोषणाओं को नियमित रूप से अद्यतन किया जाता है।"
        />
      </Helmet>
      <h1 className="pt-2 " style={{ marginBottom: "10px" }}>
        सूचनाएं एवं परिपत्र
      </h1>
      {/* input field for search the item */}
      {/* <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for notice & circulars"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div> */}
      <div style={{ marginTop: "30px" }}>
        <table class="table table-striped ">
          <thead>
            <tr class="table-primary">
              <th className={`${Styles.th}`} scope="col">
                क्र.सं
              </th>
              <th className={`${Styles.head}`} scope="col">
                विषय
              </th>
              {/* <th className={`${Styles.head}`} scope="col">
              {" "}
              तिथि
            </th>
            <th className={`${Styles.head}`} scope="col">
              अंतिम तिथि
            </th> */}
              <th className={`${Styles.head}`} scope="col">
                डाउनलोड
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                // .filter((item) =>
                //   item.name.toLowerCase().includes(search.toLowerCase())
                // )
                ?.map((item, i) => (
                  <tr>
                    <th scope="row">{i + 1}</th>
                    <td
                      className={Styles.table_cell}
                      style={{ textAlign: "left" }}
                    >
                      {item.name}
                      {item.img ? (
                        <img
                          src={item.img}
                          style={{
                            height: "35px",
                            width: "35px",
                            display: "inline",
                          }}
                          alt="blink"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td
                    className={Styles.table_cell}
                    style={{ textAlign: "left" }}
                  >
                    {item.date}
                  </td>

                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "left" }}
                  >
                    {item.expiryDate}
                  </td> */}

                    <td
                      className={Styles.table_cell}
                      style={{ textAlign: "left" }}
                    >
                      <a href={item.url} target="_blank">
                        <img className={Styles.table_image} src={img} />
                      </a>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table></div>
    </div>
    // </div>
  );
};

export default NoticesCirculars;
