import React, { useState, useEffect } from "react";
import styled from "../tenders/style.module.css";
import { useStatStyles } from "@chakra-ui/react";
import goldImg from "../../assets/images/gold_icon.png";
import silverImg from "../../assets/images/silver_icon.png";
import bronzeImg from "../../assets/images/bronze_icon.png";
import api from "../../utils/ApiMethod";
import { Heading, useMediaQuery } from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

// import Styles from "../../styles/Tender.module.css";

const Styles = {
  th: {
    backgroundColor: "#007BFF",
    color: "white",
    padding: "10px",
    textAlign: "center",
  },
  head: {
    backgroundColor: "#28A745",
    color: "white",
    padding: "10px",
    textAlign: "center",
  },
  table_cell: {
    padding: "8px",
    borderBottom: "1px solid #dee2e6",
    backgroundColor: "#f8d7da", // Light red background
    color: "#721c24", // Dark red text color
  },
  colorfulContent: {
    display: "flex !important",
    justifyContent: "space-between !important",
    backgroundColor: "#28A745 !important", // Green background
    color: "white !important", // White text color
    padding: "8px !important",
    borderRadius: "4px !important",
  },
};
const ScheduleTable = () => {
  const [data, setData] = useState([]);
  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("http://localhost:5000/api/latestinformation");
      const res = await api.fetchData("/prokabaddipointtable");
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  // console.log('data', data)
  return (
    <>
      <div>
        <Breadcrumb
          pageName="Nation games 37"
          pageTitle="national games"
          id="#nationalGames"
          img_url="img/olympic banner 1920 by 400.jpg"
          mobile_banner="img/mobile view 576 by 600.jpg"
        />

        <div className={`container `}>
          <Heading pt={35} fontSize="24px">
            ओलिंपिक खेल पेरिस 2024 में भारत
          </Heading>
          <h4
            className="section-heading"
            style={{
              color: "black",
              fontWeight: "800",
              fontSize: "18px",
              marginTop: "20px",
              textAlign: "justify",
            }}
          >
            26 जुलाई 2024 - 11 अगस्त 2024
          </h4>
        </div>
      </div>
      <div></div>

      <Container style={{ marginBottom: "35px" }}>
        <Row>
          <Col md={8}>
            <h5
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                backgroundColor: "#08549c",
                fontSize: "18px",
                color: "white",
              }}
            >
              {" "}
              <center>
                ओलिंपिक खेल 2024 में भारत द्वारा पदक प्राप्ति की वर्तमान स्थिति
              </center>
            </h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>स्वर्ण पदक</th>
                  <th>रजत पदक</th>
                  <th>कांस्य पदक</th>
                  <th>कुल पदक</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>पदकों की संख्या</td>
                  <td>0</td>
                  <td>1</td>
                  <td>5</td>
                  <td>6</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5">08 अगस्त 2024 तक की स्थिति</td>
                </tr>
              </tfoot>
            </Table>
          </Col>
          <Col
            md={4}
            className="d-flex align-items-center justify-content-center mt-5 "
          >
            <h5
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                backgroundColor: "#08549c",
                fontSize: "18px",
                color: "white",
                lineHeight: "2",
              }}
            >
              पेरिस ओलिंपिक खेल 2024 में भारत के खेल कार्यक्रम और परिणाम की
              विस्तृत जानकारी के लिए निचे दिए गए बटन पर क्लिक करें।{" "}
            </h5>
            <div style={{ marginTop: "10px" }}>
              {" "}
              <a
                href="https://olympics.com/en/paris-2024/schedule/india?day=undefined"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="success" className="p-2  w-100">
                  यहाँ क्लिक करें
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <div className={` ${styled.medal} `} style={{ width: "100%" }}>
          <div style={{ background: "#08549c", borderRadius: "5px", paddingTop: "20px" }}>
            <h4
              style={{
                color: "white",
                fontWeight: "800",
                fontSize: "18px",
                marginTop: "40px",
                textAlign: "center",
                marginBottom: "20px"
              }}
            >
              प्रो कबड्डी लीग 10वां सीजन 2023-24
            </h4>
            <div className={Styles.resp} style={{ paddingBottom: "15px" }}>
              <Row className="d-flex align-items-center justify-content-center mt-5 ">
                <Col md={5} className="d-flex align-items-center justify-content-center mt-5 ">
                  <div style={{ border: "2px solid black", padding: "50px" }}>

                  </div>
                </Col>
                <Col md={5} className="d-flex align-items-center justify-content-center mt-5 ">
                  <div style={{ border: "2px solid black", padding: "50px" }}>

                  </div>
                </Col>
              </Row>


            </div>
          </div>
        </div>
      </Container> */}
    </>
  );
};

export default ScheduleTable;
