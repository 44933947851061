import React, { useEffect, useState } from "react";
import { SimpleGrid, Center, Square, Circle, Text } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import { Box, Image } from "@chakra-ui/react";
import Styles from "./SportLegecy.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
import api from "../../utils/ApiMethod";
import { Helmet } from "react-helmet";

const SportLegecy = () => {
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/sportlegecy");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const container = {
    display: "flex",
    width: "100%",
    padding: "4px",
    marginLeft: "22px",
    marginRight: "22px",
  };

  const box30 = {
    backgroundColor: "tomato",
    height: " auto",
    width: "40%",
  };

  const box70 = {
    textAlign: "left",
    backgroundColor: "red",
    padding: "10px",
    height: "auto",
    width: " 55%",
  };

  console.log(data, "data");
  return (
    <div>
      {/* Our-Sports-Legacy-desktop.jpg */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sports Legecy | Sporting Heritage | Bihar State Sports Authority |
          BSSA
        </title>
        <meta
          name="description"
          content="बिहार खेल प्राधिकरण की खेल विरासत: राज्य के खेल इतिहास, प्रमुख खिलाड़ियों और उनकी उपलब्धियों की विस्तृत जानकारी।"
        />
      </Helmet>

      <Breadcrumb
        pageName="Our Sports Legacy"
        pageTitle="Our Sports Legacy"
        id="#Our-Sports-Legacy"
        img_url="./img/our_legacy_desktop_banner.jpeg"
        mobile_banner="./img/our_leagacy_mobile_banner.jpeg"
      />

      {/* -------------------- our sport legecy ------------- */}
      <SimpleGrid
        columns={1}
        spacing={0}
        alignItems="flex-start"
        height="auto"
        w="100%"
        pl="2%"
        pr="2%"
        bg={"#252839"}
        mt="10"
        back
      >
        <Text bgClip="text" fontSize="6xl" fontWeight="extrabold" pt={"8px"}>
          <div className={Styles.textone}>
            <h2 data-text="हमारी खेल विरासत">हमारी खेल विरासत</h2>
          </div>
        </Text>

        <Box
          as={"paragraph"}
          height="100%"
          w="100%"
          textAlign={"left"}
          alignItems="flex-start"
          pt="2%"
          pb="2%"
          fontSize="1xl"
          fontWeight="extrabold"
          color={"white"}
        >
          <p>
            समृद्धशाली इतिहास, सांस्कृतिक विरासत तथा अकादमिक कौशल के लिए प्राचीन
            समय से विख्यात बिहार का खेल इतिहास भी गौरवपूर्ण रहा है। अगर बात करें
            बिहार के खेल इतिहास की तो बिहार में खेल क्रांंति की बुनियाद वर्ष
            1937 मेंं मोईन-उल-हक़ एवं जेआरडी टाटा के नेतृत्व में पड़ी थी ।
            <br></br> <br></br> 1974 में हुए एशियाई खेलों में बिहार के
            खिलाड़ियों की व्यापक भागीदारी ने यह स्पष्ट कर दिया कि बिहार के युवा
            खेलों में विशेष रुची रखते हैं। परंतू सही मार्गदर्शन एवं उपयुक्त मंचो
            के अभाव के कारण खेल क्षेत्र में बिहार को अपेक्षित परिणाम नही मिल
            सका। बिहार एक ऐसा राज्य है जहां लोगो में खेलो के प्रति एक अलग उत्साह
            नजर आता है। बिहार की माटी ने हॉकी, एथलेटिक्स, तिरंदाजी सहित कबड्डी
            और क्रिकेट के क्षेत्र में देश को कई प्रतिभावान खिलाड़ी दिए हैं।
            जिनमें शिवनाथ सिंह, सी. प्रसाद , संजीव सिंह , राजीव कुमार सिंह एवं
            कीर्ति आजाद जैसे कई बड़े नाम शामिल हैं।
            <br></br> <br></br> आज बिहार राज्य खेल प्राधिकरण और बिहार सरकार के
            संयुक्त तत्वावधान में बिहार के प्रतिभावान खिलाड़ी खेल के क्षेत्र में
            नित्य नए आयाम गढ़ रहे हैं। खेल बुनियादी सुविधाओं, बेहतर उपकरण की
            पहुंच, उचित मार्गदर्शन तथा बेहतर मंच के अवसर के मिलने पर आज राज्य भर
            से प्रतिभावान खिलाड़ी ना सिर्फ उभर कर सामने आ रहे हैं बल्कि बेहतर
            प्रतिभा के बल पर विश्वभर में बिहार तथा भारत का नाम रोशन कर रहें हैं।
          </p>
        </Box>
      </SimpleGrid>
      {/* --------------------  ehdsour sport legecy ------------- */}

      {data.map((elem, index) => (
        <SimpleGrid
          columns={1}
          spacing={0}
          alignItems="flex-start"
          spacingX="40px"
        >
          <Box className={Styles.container}>
            <Box className={Styles.box30}>
              <Image
                boxSize="400px"
                w="100%"
                src={elem.ImageUrl}
                alt={elem.name}
              />
            </Box>

            <br></br>
            <Box className={Styles.box70}>
              <h1 style={{ paddingBottom: "3rem" }}>{elem.name}</h1>
              <p className={{ paddingTop: "30px" }}>{elem.description}</p>
            </Box>
          </Box>
          <br></br>
        </SimpleGrid>
      ))}
    </div>
  );
};
export default SportLegecy;
