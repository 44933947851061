import React from "react";

import stylesgfi from "../../pages/SGFI/NationalSchoolGames.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
// import Button from "react-bootstrap/Button";
import { Button, Container, Row, Col } from "react-bootstrap";

// import Athlite from "./Athlite";

export default function BiharVolleyballLeague() {
  return (
    <>
      <Breadcrumb
        pageName={BiharVolleyballLeague}
        pageTitle={"BiharVolleyballLeague"}
        img_url={"./img/DestopBVL.jpg"}
        mobile_banner="./img/mobileview_vallyball.jpeg"
      />
      <div></div>
      {/* <div>
        <h1 className={`${stylesgfi.title}`}>
          प्रथम बिहार वॉलीबॉल लीग 2024
        </h1>
      </div> */}

      {/* <Container className="button-container mt-5"> */}
      <div className="mt-5 container" style={{ marginTop: "40px" }}>
        <Row>
          <Col
            xs={12}
            md={4}
            className="text-center "
            style={{ marginTop: "20px" }}
          >
            <Button
              className="animated-button"
              variant="success"
              href="https://dz8zbd8amw7pd.cloudfront.net/BVL/Fixture/fixture.html"
              target="_blank"
            >
              मैच शेड्यूल ↗
            </Button>
          </Col>
          <Col
            xs={12}
            md={4}
            className="text-center "
            style={{ marginTop: "20px" }}
          >
            <Button
              className="animated-button"
              variant="success"
              href="https://dz8zbd8amw7pd.cloudfront.net/BVL/PointsTable/index.html"
              target="_blank"
            >
              लेटेस्ट परिणाम ↗
            </Button>
          </Col>

          <Col
            xs={12}
            md={4}
            className="text-center"
            style={{ marginTop: "20px" }}
          >
            <Button
              className="animated-button"
              variant="success"

              // href="https://www.youtube.com/live/sOB4a9_7sq8?si=7vN3hLxvBWrYqU5p"
              href="https://www.youtube.com/live/DwlnoMEH6pQ?si=0JvA6AQJHSqBpcKx"
              target="_blank"
            >
              मैच का सीधा प्रसारण ↗
            </Button>
          </Col>
          {/* <Col
            xs={12}
            md={3}
            className="text-center"
            style={{ marginTop: "20px" }}
          >
            <Button
              className="animated-button"
              variant="success"
              href="#"
              target="_blank"
            >
              विस्तृत जानकारी के लिए क्लिक करें ↗
            </Button>
          </Col> */}
        </Row>


      </div>




      {/* </Container> */}

      {/* <div className={`container ${stylesgfi.game_sgfi}`}>
        <a
     
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/teams4.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>पाटलिपुत्र एसर्स</p>
          </div>
        </a>

        <a
       
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/teams2.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>मिथिला स्पाइकर्स </p>
          </div>
        </a>

        <a
    
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/teams6.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>विक्रमशिला ब्लॉकर्स</p>
          </div>
        </a>

        <a
     
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/teams1.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>मगध सेटर्स </p>
          </div>
        </a>

        <a
     
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/teams5.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p> तक्षशिला सर्वर्स</p>
          </div>
        </a>
        <a
       
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/teams3.jpeg"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p> नालंदा डिफेंडर्स</p>
          </div>
        </a>


      </div> */}
      <a
      // href="https://sgfionline.net/reports/MedalTally.aspx?NsgNbr=66"
      // target="_blank"
      >
        {/* <Button
          variant="warning"
          style={{ marginBottom: "20px", color: "black" }}
        >
          मेडल तालिका ↗
        </Button> */}
      </a>

      <div className="mt-5 mb-5  container" style={{ marginTop: "40px", marginBottom: "100px", overflowX: "auto" }}>
        <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "900",
            fontSize: "36px",
            marginTop: "20px",
            textAlign: "justify",
          }}
        >
          लेटेस्ट परिणाम
        </h4>

        <div style={{ overflowX: 'auto' }}>

          <iframe
            // src="https://dz8zbd8amw7pd.cloudfront.net/BVL/MatchCenter/index.html?m=6"
            src="https://dz8zbd8amw7pd.cloudfront.net/BVL/PointsTable/index.html"
            id="kadamba-iframefxfl"
            style={{ width: '100%', height: '80vh', border: 'none' }} // Correct JSX style
            scrolling="auto"
          ></iframe>
          <script
            src="https://dz8zbd8amw7pd.cloudfront.net/BVL/Fixture/script/frameresizer.js"
            type="text/javascript"
          ></script>
        </div>
        <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "900",
            fontSize: "36px",
            marginTop: "20px",
            textAlign: "justify",
          }}
        >
          मैच शेड्यूल
        </h4>
        <div style={{ overflowX: 'auto', alignItems: "center" }}>

          <iframe
            src="https://dz8zbd8amw7pd.cloudfront.net/BVL/Fixture/fixture.html"
            id="kadamba-iframefxfl"
            style={{ width: '100%', height: '80vh', border: 'none', overflowX: 'auto' }} // Correct JSX style
            scrolling="auto"
          ></iframe>

          <script
            src="https://dz8zbd8amw7pd.cloudfront.net/BVL/Fixture/script/frameresizer.js"
            type="text/javascript"
          ></script>
        </div>
      </div>

    </>
  );
}
