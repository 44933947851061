import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";

import { useMediaQuery } from "@chakra-ui/react";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/navigation";
// Import Swiper React components
// import { delay, motion, useAnimation } from 'framer-motion';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
// import './styles.css';

// import required modules
import { Navigation } from "swiper/modules";
import Akash from "../assets/images/Akash Kumar.jpeg";

import Anuj from "../assets/images/Anuj Kumar.jpeg";
import NishiAndSoni from "../assets/images/Nishi and soni.jpeg";
import PromodSingh from "../assets/images/Promod singh.jpeg";
import RugbyGirls from "../assets/images/lawn_bawls_team_bihar.jpeg";
import Anjani from "../assets/images/Anjani.jpeg";
import ShibuKumar from "../assets/images/Shibu Kumar.jpeg";

function Gift() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");

  const slidesPerView = isSmallScreen ? 1 : 1;

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fcb606",
          "--swiper-pagination-color": "#fcb606",
        }}
        autoplay={{
          delay: 2000,
        }}
        // autoplay={{
        //   delay: 2000, // Autoplay delay in milliseconds
        //   disableOnInteraction: true, // Autoplay stops even after user interaction
        // }}
        slidesPerView={slidesPerView}
        // rewind={true}
        navigation={true}
        modules={[Navigation]}
        // modules={[FreeMode, Pagination]}
        loop={true}
        spaceBetween={10}
        bgGradient={[
          "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
          //   'linear(to-t, blue.200, teal.500)',
          //   'linear(to-b, orange.100, purple.300)',
        ]}
        className={styles.mySwiper}
        // style={{"height" : "600px", border:"20px solid lightgray", margin:"0px"}}
      >
        {/* <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/SwetaSahi.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>स्वेता साही</h4>
                <h5>स्वेता साही को ओलंपिक क्वालिफाइड टीम के लिए चुना गया है</h5>
                <h5>रग्बी</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/sunnyRaj.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>सन्नी राज</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(रजत पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/RohitRaj.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>रोहित राज</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(स्वर्ण पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/piyushRaj.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>पीयूष राज</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(स्वर्ण पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/nisha_kumari.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>निशा कुमारी</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(स्वर्ण पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/kisu_singh.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>किसु सिंह</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(स्वर्ण पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/38th_nationalatheletics.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>आयुवर्ग</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(रजत पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/DurgaSingh.jpeg" alt="game" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>दुर्गा सिंह</h4>
                <h5>38वें नेशनल जूनियर एथलेटिक्स चैंपियनशिप 2023</h5>
                <h5>(स्वर्ण पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  src="img/Shailesh_kumar_high_jump_gold_medal.jpeg"
                  alt="game"
                />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>शैलेश कुमार</h4>
                <h5>चौथा एशियन पैरा गेम्स 2023 </h5>
                <h5>(स्वर्ण पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  src="img/shashi-bhooshan-singh-open-national-championship.jpeg"
                  alt="game"
                />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>शशि भूषण</h4>
                <h5>ओपन नेशनल एथेलेटिक्स चैंपियनशिप </h5>
                <h5>(रजत पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  src="img/sapna-kumari-100meter-champion-bihar.jpeg"
                  alt="game"
                />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>सपना कुमारी</h4>
                <h5>100 मीटर हर्डल प्रतिस्पर्धा</h5>
                <h5>(रजत पदक)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  src="img/sub-junior-boys-national-championship-2023.jpg"
                  alt="game"
                />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>सब जूनियर बॉयज फुटबॉल </h4>
                <h5>नेशनल चैंपियनशिप</h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  src="img/mobile_view_asian_games_mobile_banner.jpg"
                  alt="Asian game"
                />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>एशियन गेम्स 2023 में </h4>
                <h5>चयनित बिहार के खिलाड़ी</h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src={RugbyGirls} alt="RugbyGirls" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "10%" }}
              >
                <h4 style={{ color: "black", fontWeight: "bold" }}>
                  बिहार की लॉन बाउल्स टीम
                </h4>
                <h5>नेशनल गेम्स 2023</h5>
                <h5>के लिए हुई क्वालीफाई ।</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                <img src="img/highlightOne.jpeg" alt="Md.Jalaluddin" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>मो. जलालुद्दीन अंसारी</h4>
                <h5>पारा साइक्लिंग</h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src="img/highlightFour.jpeg" alt="Rugbby team" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>भारतीय रग्बी टीम में </h4>
                <h5>चयनित बिहार के खिलाड़ी</h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src={ShibuKumar} alt="Chandan" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4> सिबु कुमार</h4>
                <h5>बास्केटबॉल</h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src={NishiAndSoni} alt="NishiAndSoni" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>निशी कुमारी और सोनी कुमारी</h4>
                <h5>जेवलिन थ्रो और डिस्कस थ्रो </h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src={Anuj} alt="Anuj" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>अनुज कुमार सिंह</h4>
                <h5>वॉलीबॉल</h5>
                <h5>(2022)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide>
        <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                <img src={PromodSingh} alt="Shuhani" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>प्रमोद सिंह (असिस्टेंट कोच)</h4>
                <h5>वॉलीबॉल </h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>

        </SwiperSlide> */}

        {/* <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                <img src="img/reyan.jpg" alt="reyan" />
              </div>
              <div className="whatsNewCarousel__text">
                <h4> सुशांत कुमार</h4>
                <h5> वॉलीबॉल</h5>
                <h5>(2023)</h5>
              </div>
            </div>
          </div>
       </SwiperSlide> */}

        <SwiperSlide>
          <div className="swiper-slide zoom">
            <div className="whatsNewCarousel__card">
              <div
                className="award__img"
                style={{ width: "100%", height: "100%" }}
              >
                <img src={Anjani} alt="Anjani" />
              </div>
              <div
                className="whatsNewCarousel__text"
                style={{ padding: "12%" }}
              >
                <h4>अंजनी कुमारी</h4>
                <h5>जेवलिन थ्रो</h5>
                <h5>(2022)</h5>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
export default Gift;
