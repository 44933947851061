import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import {
  GridItem,
  SimpleGrid,
  Box,
  background,
  Button,
  Stack,
  Card,
  CardBody,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  CardFooter,
  Image,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const ContactUs = () => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^[0-9]{10}$/; // Assumes a 10-digit phone number
    return regex.test(phone);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const email = formData.get('user_email');
    const phone = formData.get('user_phone');

    // Email validation
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      return;
    }

    // Phone number validation
    if (!validatePhone(phone)) {
      setPhoneError('Invalid phone number');
      return;
    }

    setEmailError('');
    setPhoneError('');

    emailjs.sendForm('dummy', 'dummy', form.current, 'dummy')
      .then((result) => {
        console.log(result.text);
        console.log("Message sent");
        setFormSubmitted(true);
        setLoading(false);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div>
      <Breadcrumb
        pageName="Contact us"
        pageTitle="Contact us"
        id="#contact-us"
        img_url="./img/Message-banner-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <div style={{ backgroundColor: "#187446" }}>
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          gap={10}
          w="90%"
          m="0px auto 0px auto"
          paddingTop={10}
        >
          <GridItem>
            <Stack
              spacing={4}
              boxShadow="outline"
              rounded="md"
              p="15px 20px"
              bg="#cfd9de"
            >
              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                alignItems="center"
                p="8"
                rounded="xl"
              >
                <Button colorScheme="red">
                  <i className="fas fa-map-marker-alt"></i>
                </Button>

                <Stack>
                  <CardBody>
                    <Heading size="lg" textAlign="justify">
                      पता
                    </Heading>

                    <Text py="5" size="md">
                      बिहार राज्य खेल प्राधिकरण कंकड़बाग, पटना
                    </Text>
                  </CardBody>

                  {/* <CardFooter>
                  <Button variant="solid" colorScheme="blue">
                    Buy Latte
                  </Button>
                </CardFooter> */}
                </Stack>
              </Card>

              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                alignItems="center"
                p="8"
                rounded="xl"
              >
                <Button colorScheme="red">
                  <i className="fas fa-map-marker-alt"></i>
                </Button>

                <Stack>
                  <CardBody>
                    <Heading size="lg" textAlign="justify">
                      स्टेडियम
                    </Heading>

                    <Text py="5" size="md">
                      पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना
                    </Text>
                  </CardBody>

                  {/* <CardFooter>
                  <Button variant="solid" colorScheme="blue">
                    Buy Latte
                  </Button>
                </CardFooter> */}
                </Stack>
              </Card>

              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                alignItems="center"
                p="8"
                rounded="xl"
              >
                <Button colorScheme="blue">
                  <i className="fas fa-phone"></i>
                </Button>

                <Stack>
                  <CardBody>
                    <Heading size="lg" textAlign="justify">
                      संपर्क करें
                    </Heading>

                    <Text py="5" size="md">
                      0612-2665510
                    </Text>
                  </CardBody>

                  {/* <CardFooter>
                  <Button variant="solid" colorScheme="blue">
                    Buy Latte
                  </Button>
                </CardFooter> */}
                </Stack>
              </Card>
            </Stack>
          </GridItem>
          {/* -------------------------------------------------------------------- example from here ------------------------------ */}
          {/* <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form> */}

          {/* ---------------------------------------------------------------example ends ------------------------------------------- */}

          <GridItem>
            {isFormSubmitted ? (
              <div style={{ backgroundColor: "green", fontWeight: "800", borderRadius: "1.5rem", height: "76vh" }}>
                <p style={{ paddingTop: "40%" }}>Email send successfully</p>
                {/* Additional content or actions after successful submission */}
              </div>

            ) : (
              <Box
                boxShadow="outline"
                p="8"
                rounded="md"
                bg="white"
              // style={{ height: "455px", maxWidth: "400px", margin: "auto" }}
              >
                <h3 style={{ fontSize: "1.5rem", marginBottom: "20px" }}>अपना सवाल लिखें</h3>

                <form ref={form} onSubmit={sendEmail}>
                  <div style={{ marginBottom: "15px", textAlign: "left" }}>
                    <label style={{ fontSize: "1.5rem", marginLeft: "3%" }}>नाम</label>
                    <input
                      type="text"
                      // name="user_name"
                      name="from_name"
                      style={{ height: "35px", fontSize: "1.5rem", fontWeight: "600", width: "100%", padding: "0px 8px", border: "2px solid lightgreen", borderRadius: ".8rem" }}
                      placeholder="Name"
                      required
                    />
                  </div>

                  <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label style={{ fontSize: "1.5rem", marginBottom: "1px", marginLeft: "3%" }}>ईमेल</label>
                    <input
                      type="email"
                      name="user_email"
                      style={{ height: "35px", fontSize: "1.5rem", fontWeight: "600", width: "100%", padding: "8px", border: "2px solid lightgreen", borderRadius: ".8rem" }}
                      placeholder="Email Id"
                      required
                    />
                    {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                  </div>

                  <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <label style={{ fontSize: "1.5rem", marginBottom: "1px", fontSize: "1.5rem", marginLeft: "3%" }}>फ़ोन नंबर</label>
                    <input
                      type="tel"
                      name="user_phone"
                      style={{ height: "35px", fontSize: "1.5rem", fontWeight: "600", width: "100%", padding: "8px", border: "2px solid lightgreen", borderRadius: ".8rem" }}
                      placeholder="Phone No."
                      required
                    />
                    {phoneError && <span style={{ color: 'red' }}>{phoneError}</span>}
                  </div>

                  <div style={{ marginBottom: "5px", textAlign: "left" }}>
                    <label style={{ fontSize: "1.5rem", marginLeft: "3%" }}>संदेश</label>
                    <textarea
                      name="message"
                      style={{ height: "80px", fontSize: "1.5rem", fontWeight: "600", width: "100%", padding: "8px", border: "2px solid lightgreen", borderRadius: ".8rem" }}
                      placeholder="Message........"
                      required
                    />
                  </div>

                  <Button
                    mt={4}
                    colorScheme="teal"
                    type="submit"
                    style={{ fontSize: "1.5rem", padding: "15px 15px" }}
                    disabled={isLoading} // Disable the button while loading
                  >
                    {isLoading ? 'sending...' : 'भेजें'}
                  </Button>
                </form>

              </Box>
            )
            }
          </GridItem>



        </SimpleGrid>

        <SimpleGrid
          columns={[1, 1, 1, 1]}
          gap={5}
          w="90%"
          m="0px auto 0px auto"
          paddingTop={10}
          paddingBottom={10}
        >
          <GridItem>
            <Box
              boxShadow="outline"
              p="2"
              rounded="md"
              bg="white"
              className=""
              //   style={{ filter: "grayscale(100%)" }}
              style={{}}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28785.863667417623!2d85.1391295!3d25.5971741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed592fc4cedecb%3A0x4e5bdb0f099cecf0!2sBihar%20State%20Sports%20Authority!5e0!3m2!1sen!2sin!4v1692875071145!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </GridItem>
        </SimpleGrid>
      </div>
    </div>
  );
};
export default ContactUs;
