import React, { useEffect, useState } from "react";
import football from "../../assets/images/football.gif";
import archery from "../../assets/images/archery.gif";
import cycling from "../../assets/images/Cycling.gif";
import volleyBall from "../../assets/images/volleyBall.gif";
import weightLifting from "../../assets/images/weightLifting.gif";
import Styles from "../../styles/PrioritySports.module.css";

import {
  Container,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  border,
  useStatStyles,
} from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import axios from "axios";
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const IMAGE =
  "https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80";

const PrioritySports = () => {
  const [sportsData, setSportsData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/prioritysports");
      setSportsData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const bg = useColorModeValue("red.500", "red.200");

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Priority Sports in Bihar | Bihar State Sports Authority | BSSA
        </title>
        <meta
          name="description"
          content="बिहार सरकार द्वारा प्राथमिकता वाले खेल: फुटबॉल, भारोत्तोलन, साइक्लिंग, निशानेबाजी, बैडमिंटन, वॉलीबॉल, ऐथलेटिक्स, रग्बी, शतरंज, हॉकी, कबड्डी, कुश्ती, फेंसिंग, सेपक टाकरा, और बॉक्सिंग। इन खेलों के विकास और आयोजन के लिए सरकार वित्तीय सहयोग दे रही है।"
        />
      </Helmet>
      {/* Priority-Sports-of-Bihar-desktop */}

      <Breadcrumb
        pageName="Priority Sports of Bihar"
        pageTitle="Priority Sports of Bihar"
        id="#priority-sport"
        img_url="./img/Priority-Sports-of-Bihar-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <div
        style={{
          background: "linear-gradient(to bottom, #ffffff, #87CEFA)", // White to blue gradient
        }}
      >
        {/*--------------------------------------  first image start----------------------------  */}
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          pt={"30px"}
          boxShadow="dark-sm"
          fontSize={["8", "16"]}
          // letterSpacing="wide"
          color="teal.800"
          px={[4, 10, 20]}
          fontFamily="'Oswald', sans-serif;"
          textAlign={"left"}
          // style={border}
          style={{
            background: "linear-gradient(to bottom, #4fa94f, white)", // White to blue gradient
          }}
        >
          बिहार सरकार द्वारा राज्य में खेल और खिलाड़ियों के सर्वांगीण विकास के
          लिए कुछ खेलों को अपनी प्राथमिकता वाले खेल की सूची में रखा गया है।
          सरकार के साथ साथ इन खेलों के विकास के लिए वित्तीय सहयोग की जिम्मेदारी
          सरकार के विभिन्न निगमों और उद्यमों को भी सौंपी गई है जिससे इन खेलों का
          आयोजन और संचालन और बेहतर हो सके । बिहार के प्राथमिकता वाले खेल इस
          प्रकार हैं :-
          <br />
          फुटबॉल, भारोत्तोलन, साइक्लिंग, निशानेबाजी, बैडमिंटन, वॉलीबॉल,
          ऐथलेटिक्स, रग्बी, शतरंज, हॉकी, कबड्डी, कुश्ती, फेंसिंग,सेपक टाकरा,
          बॉक्सिंग ।
        </Text>
        <div
          style={{ backgroundColor: "gray", height: "2px", marginTop: "10px" }}
        ></div>
        <SimpleGrid
          columns={[1, 1, 1, 1]}
          spacing="5px"
          mt="40px"
          pb={"40px"}
          alignItems={"center"}
        >
          {/* <Box
            p={0}
            display={{ md: "flex" }}
            h="70vh"
            width="100%"
            px={[4, 10, 20]}
            py={[4, 10, 20]}
          > */}

          {sportsData.map((item, i) => (
            <>
              <Box className={Styles.container} alignItems={"center"}>
                <Box className={Styles.box30}>
                  <Image
                    boxSize="250px"
                    w="80%"
                    src={item?.img}
                    alt="Dan Abramov"
                  />
                </Box>
                <Box
                  className={Styles.box70}
                  fontWeight="bold"
                  textTransform="uppercase"
                  fontSize={["8", "16"]}
                  letterSpacing="wide"
                  color="teal.800"
                  pt={"3px"}
                >
                  <h1 style={{ paddingBottom: "3rem" }}>{item?.sportName}</h1>
                  <p>{item?.paragraph} </p>
                </Box>
              </Box>
              <div className={Styles.hotizontal}></div>
            </>
          ))}

          {/* </Box> */}

          {/*--------------------------------------  first image end----------------------------  */}
        </SimpleGrid>
      </div>
    </div>
  );
};
export default PrioritySports;
