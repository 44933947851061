import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const OrganizationalStru = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Art Culture and Youth Department | Bihar State Sports Authority | BSSA
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की संगठनात्मक संरचना: माननीय मंत्री (कला, संस्कृति एवं युवा विभाग), अपर मुख्य सचिव (कला, संस्कृति एवं युवा विभाग), महानिदेशक सह मुख्य कार्यकारी अधिकारी (बिहार राज्य खेल प्राधिकरण), और निदेशक सह सचिव (बिहार राज्य खेल प्राधिकरण) शामिल हैं।"
        />
      </Helmet>
      <Breadcrumb
        pageName="Organizational Structure"
        pageTitle="Organizational Structure"
        id="#organizational-structure"
        img_url="./img/dil-se-khelo-milke-jito-banner-desktop.jpg"
        mobile_banner="./img/dil-se-khelo-milke-jito-banner-mobile.jpg"
      />
      <GridItem
        paddingBottom={"30px"}
        paddingTop={"10px"}
        // bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
        bg="#063a7a"
      >
        <h1>संगठनात्मक संरचना</h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"14px"}
        paddingLeft={"20%"}
        paddingRight={"20%"}
        bg="#063a7a"
      >
        <GridItem
          border={"1px solid lightblue"}
          borderRadius={"8px"}
          alignItems={"center"}
          color={"white"}
          paddingTop={"1%"}
          paddingBottom={"1%"}
          _hover={{
            bg: "white",
            color: "black",
          }}
          fontSize={"2rem"}
        >
          माननीय मंत्री,(कला,संस्कृति एवं युवा विभाग )
        </GridItem>
        <GridItem
          paddingLeft={"50%"}
          paddingTop={".5%"}
          paddingBottom={".5%"}
          color={"white"}
        >
          <img src="img/gg.png" style={{ width: "13px" }} />
        </GridItem>

        {/* ============================================ */}
        <GridItem
          border={"1px solid lightblue"}
          borderRadius={"8px"}
          alignItems={"center"}
          color={"white"}
          paddingTop={"1%"}
          paddingBottom={"1%"}
          _hover={{
            bg: "white",
            color: "black",
          }}
          fontSize={"2rem"}
        >
          अपर मुख्य सचिव,(कला,संस्कृति एवं युवा विभाग )
        </GridItem>
        <GridItem paddingLeft={"50%"} paddingTop={".5%"} paddingBottom={".5%"}>
          <img src="img/gg.png" style={{ width: "13px" }} />
        </GridItem>

        <GridItem
          border={"1px solid lightblue"}
          borderRadius={"8px"}
          alignItems={"center"}
          color={"white"}
          paddingTop={"1%"}
          paddingBottom={"1%"}
          _hover={{
            bg: "white",
            color: "black",
          }}
          w={"100%"}
          fontSize={"2rem"}
        >
          महानिदेशक सह मुख्य कार्यकारी अधिकारी,( बिहार राज्य खेल प्राधिकरण ){" "}
        </GridItem>
        <GridItem paddingLeft={"50%"} paddingTop={".5%"} paddingBottom={".5%"}>
          <img src="img/gg.png" style={{ width: "13px" }} />
        </GridItem>

        <GridItem
          border={"1px solid lightblue"}
          borderRadius={"8px"}
          alignItems={"center"}
          color={"white"}
          paddingTop={"1%"}
          paddingBottom={"1%"}
          marginBottom={"5.5%"}
          _hover={{
            bg: "white",
            color: "black",
          }}
          fontSize={"2rem"}
        >
          निदेशक सह सचिव,(बिहार राज्य खेल प्राधिकरण )
        </GridItem>
      </SimpleGrid>
    </div>
  );
};
export default OrganizationalStru;
