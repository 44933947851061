import React from "react";
// import styles from "../styles/FacebookPageEmbed.module.css";

const FacebookPageEmbed = () => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <iframe
        title="Facebook Page"
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBSSABihar&amp;tabs=timeline&amp;width=358&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId=701410420753236"
        width="358"
        height="500"
        style={{
          border: "none",
          overflow: "hidden",
          //   borderRadius: "14px",
          width: "100%",
          margin: "auto",
        }}
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};

export default FacebookPageEmbed;
