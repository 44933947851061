import React, { useEffect, useState } from "react";
import "./Indivisiual.css";
import { SimpleGrid, border } from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import { delay, motion, useAnimation } from "framer-motion";

import { Box, Text, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const DeputyChiefMinister = () => {
  const [harjotPageData, setHarjotPageData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/deputychiefminister");
      setHarjotPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  console.log("ycm", harjotPageData);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Honorable Deputy Chief Minister | Shri Samrat Chaudharya | Sports
          Authority | BSSA
        </title>
        <meta
          name="description"
          content="बिहार खेल प्राधिकरण पर उपमुख्यमंत्री का संदेश: राज्य में खेलों के विकास के महत्व, युवा खिलाड़ियों को प्रोत्साहन और खेल क्षेत्र में नई पहल के प्रति सरकार की प्रतिबद्धता को दर्शाता है।"
        />
      </Helmet>
      {harjotPageData &&
        harjotPageData.map((item, index) => (
          <SimpleGrid columns={[1, 1, 2, 2]} mt="20" mb="10" key={index}>
            <motion.Box
              flexShrink={0}
              initial={{
                opacity: 0,
                translateX: -50,
                translateY: 0,
              }}
              whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
              transition={{ duration: 0.3, delay: 1 }}
            >
              <Image
                // style={{
                //   borderTop: "6px solid red",
                //   borderBottom: "6px solid red",
                // }}
                borderRadius="lg"
                px={[5, 10, 20]}
                src={item?.image}
                alt="Woman paying for a purchase"
                h="350px"
                w="100%"
              />
            </motion.Box>

            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
              <Text
                fontWeight="bold"
                textTransform="uppercase"
                fontSize={["14", "32"]}
                letterSpacing="wide"
                color="teal.800"
                px={[4, 10, 20]}
              >
                {item?.title}
              </Text>

              <Text
                fontWeight="bold"
                textTransform="uppercase"
                align={"right"}
                fontSize={["10", "13"]}
                letterSpacing="wide"
                color="black"
                px={[4, 10, 20]}
              >
                {item?.designation}
              </Text>

              <Text
                mt={2}
                color="gray.500"
                align={"left"}
                px={[5, 10, 20]}
                letterSpacing="wide"
                fontWeight="bold"
                py="2"
              >
                {expanded ? (
                  <p>{item?.paragraph}</p>
                ) : (
                  <p>{item?.paragraph?.slice(0, 850)}...</p>
                )}
                <button
                  onClick={toggleExpand}
                  style={{ color: "blue", fontWeight: "500" }}
                >
                  {expanded ? "कम पढ़ें" : "और पढ़ें"}
                </button>

                {/* Coming soon */}
              </Text>
            </Box>
          </SimpleGrid>
        ))}
    </>
  );
};
export default DeputyChiefMinister;
