import React from "react";
// import "../../styles/policy.module.css";
// import "./policy.module.css";
import styles from "../../styles/policy.module.css";
import Header from "../../components/Header";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { SimpleGrid, Box, Text, Center } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const SportsWelfareFund = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Players Welfare Fund | Sports and Youth Welfare | BSSA</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण द्वारा संचालित खिलाड़ी कल्याण कोष का उद्देश्य प्रशिक्षण या प्रतियोगिता के दौरान चोटिल या खेलने में अयोग्य खिलाड़ियों को चिकित्सा उपचार और खेल उपकरणों के लिए आर्थिक सहायता प्रदान करना है।"
        />
      </Helmet>
      <Breadcrumb
        pageName="Policy"
        pageTitle="Policy"
        id="#policy"
        img_url="./img/backdropImg/WelfareFundMoodel.jpeg"
        mobile_banner="./img/backdropImg/9 Players Welfare Fund mobile view.jpg"
      />
      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
        }}
      >
        <Text
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="black"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          खिलाड़ी कल्याण कोष
        </Text>

        <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
          <Center
            as={"text"}
            h="100px"
            textAlign={"left"}
            p="3%"
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["8", "16"]}
            letterSpacing="wide"
            color="teal.800"
          >
            बिहार राज्य खेल प्राधिकरण द्वारा संचालीत खिलाड़ी कल्याण कोष का
            लक्ष्य प्रशिक्षण अवधि अथवा प्रतियोगिता अवधि में चोटग्रस्त होने एवं
            अन्य कारणों से खेलने में अयोग्य उत्कृष्ट/प्रतिभावान खिलाड़ियों को
            चिकित्सा उपचार तथा खेल उपकरणों हेतु आवश्यकतानुसार आर्थिक सहायता
            प्रदान करना है।
          </Center>
          <Center>
            <a
              href="img/khiladi_kalyan_kosh.pdf"
              target="_blank"
              style={{
                fontSize: "18px",
                fontWeight: "800",
                textDecoration: "none", // Remove underline from the link
                display: "inline-block", // Ensure the button takes only the necessary width
                padding: "10px 20px", // Adjust padding to your liking
                backgroundColor: "#007bff", // Button background color
                color: "#fff", // Button text color
                borderRadius: "5px", // Add rounded corners to the button
                cursor: "pointer", // Change cursor to indicate it's clickable
              }}
            >
              खिलाड़ी कल्याण कोष PDF <span> 👆</span>
            </a>
          </Center>
        </SimpleGrid>
      </div>
    </>
  );
};

export default SportsWelfareFund;
