import React, { useEffect, useState } from "react";
import img from "../../assets/images/Download.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../../styles/Gift.module.css";
import Styles from "../../styles/Tender.module.css";
import api from "../../utils/ApiMethod";
import { Heading, useMediaQuery } from "@chakra-ui/react";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Button, Container } from "react-bootstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MedalTally from "./MedalTally";
function ProKabaddi() {


  return (
    <div  >
      <Breadcrumb
        pageName="Nation games 37"
        pageTitle="national games"
        id="#nationalGames"
        img_url="img/proKbbadiDesktopBanner.jpg"
        mobile_banner="img/prokabbadiMobileBanner.jpg"
      />

      <div className={`container `} >
        <Heading p={5} fontSize="24px">
        प्रो कबड्डी लीग 10वां सीजन 2023-24
        </Heading>
        <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "800",
            fontSize: "18px",
            marginTop: "40px",
            textAlign: "justify",
          }}
        >
           प्रो कबड्डी लीग 10वां सीजन 2023-24
        </h4>
          <div className={`${Styles.resp} col-8`}>
            <MedalTally />
          </div>
      </div>
    </div>
  );
}
export default ProKabaddi;
