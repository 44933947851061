import React from "react";
// import "../../styles/policy.module.css";
// import "./policy.module.css";
import styles from "../../styles/policy.module.css";
import Header from "../../components/Header";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { SimpleGrid, Box, Text, Center } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const GrantsSportsFederations = () => {
  return (
    // <div>
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Grants to Sports Federations | Bihar State Sports Authority | BSSA
        </title>
        <meta
          name="description"
          content="बिहार सरकार और बिहार राज्य खेल प्राधिकरण द्वारा खेल संघों को अनुदान: राज्य के खिलाड़ियों के सर्वांगीण विकास और उपकरण संबंधी आवश्यकताओं की पूर्ति के लिए विभिन्न खेल संघों को समय-समय पर अनुदान राशि प्रदान की जाती है।"
        />
      </Helmet>
      <Breadcrumb
        pageName="Policy"
        pageTitle="Policy"
        id="#policy"
        img_url="./img/backdropImg/Grantsfromsportassociatio.jpeg"
        mobile_banner="./img/backdropImg/13 grants from sports associations mobile view.jpg"
      />
      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
        }}
      >
        <Text
          // fontWeight="bold"
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          // align={"right"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="black"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          खेल संघो को अनुदान
        </Text>

        <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
          <Center
            as={"text"}
            h="100px"
            textAlign={"left"}
            p="3%"
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["8", "16"]}
            letterSpacing="wide"
            color="teal.800"
          >
            बिहार सरकार एवं बिहार राज्य खेल प्राधिकरण द्वारा राज्य के खिलाड़ियों
            के सर्वागींण विकास के लिए राज्य में व्यापक स्तर पर नीति एवं योजनाओं
            के निर्माण का कार्य जारी है।विभिन्न खेलों से जुड़े खिलाड़ियों के
            बेहतर विकास एवं उपकरण संबंधित कमी के पूर्ती के लिए राज्य में कई खेल
            संघो का निर्माण किया गया है। इन खेल संघो को समय समय पर बिहार राज्य
            खेल प्राधिकरण एवं बिहार सरकार के द्वारा अनुदान राशी का वितरण किया
            जाता रहा है जिसके कारण आज खेल के क्षेत्र में बिहार विकास की नई
            ऊचाइयों को छू रहा है।{" "}
          </Center>

          <Center>
            <a
              href="img/khel_sangh_ka_anudan.pdf"
              target="_blank"
              style={{
                fontSize: "18px",
                fontWeight: "800",
                textDecoration: "none", // Remove underline from the link
                display: "inline-block", // Ensure the button takes only the necessary width
                padding: "10px 20px", // Adjust padding to your liking
                backgroundColor: "#007bff", // Button background color
                color: "#fff", // Button text color
                borderRadius: "5px", // Add rounded corners to the button
                cursor: "pointer", // Change cursor to indicate it's clickable
              }}
            >
              खेल संघो को अनुदान PDF<span> 👆</span>
            </a>
          </Center>
        </SimpleGrid>
      </div>

      {/* <Header /> */}
      {/* <h1>{`
          http://localhost:5000/${resultUrl}`}</h1> */}

      {/* <Breadcrumb
        pageName="About Us"
        pageTitle="About Us"
        id="#about"
        img_url={`
        https://bssadev.brandradiator.in/img/medal-laao-newbanner-desktop.jpg`}
      /> */}

      {/* <Footer /> */}
      {/* </div> */}
    </>
  );
};

export default GrantsSportsFederations;
