import React, { useState } from "react";
import stylesgfi from "../../pages/SGFI/NationalSchoolGames.module.css"; // Assuming you're using CSS for other styles
import Breadcrumb from "../../components/common/Breadcrumb";
import { Button, Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { Box, Text, Image, Stack, Heading, SimpleGrid, SlideFade } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'; // Assuming Chakra UI for Tabs
import { Flex, VStack, HStack } from "@chakra-ui/react";

export default function BiharACT() {
  const [selectedContent, setSelectedContent] = useState("about");
  const matches = [
    {
      id: 1,
      date: "11 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 2,
      date: "11 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 3,
      date: "11 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 4,
      date: "12 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "THAILAND",
      player1Img: "/img/Mosquto.jpeg",
      player2: "JAPAN",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 5,
      date: "12 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 6,
      date: "12 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 7,
      date: "14 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "KOREA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 8,
      date: "14 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "CHINA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 9,
      date: "14 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "THAILAND",
      player1Img: "/img/Mosquto.jpeg",
      player2: "INDIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 10,
      date: "16 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "MALAYSIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "JAPAN",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 11,
      date: "16 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "KOREA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 12,
      date: "16 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "CHINA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 13,
      date: "17 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "MALAYSIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 14,
      date: "17 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 15,
      date: "17 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "INDIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 16,
      date: "19 November 2024",
      time: "14:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (5th/6th Place)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 17,
      date: "19 November 2024",
      time: "17:00 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Semi-final 1)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 18,
      date: "19 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Semi-final 2)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 19,
      date: "20 November 2024",
      time: "17:00 IST",
      tournament: "Women's Asian Champions Trophy 2024 (3rd/4th Place)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 20,
      date: "20 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Final)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
  ];

  const [selectedMatch, setSelectedMatch] = useState(matches[0]); // Default to the first match

  // Handler to update the selected match
  const handleMatchClick = (match) => {
    setSelectedMatch(match);
  };

  const items = [
    {
      title: "Signature Hockey Stick",
      description: "Looking for the perfect gift for a hockey fan or a unique memento to commemorate an unforgettable game? Crafted as a tribute to this prestigious tournament, each stick is beautifully designed with high-quality materials and features autographs from top players participating in the tournament. This unique collector’s item celebrates the spirit of international competition and makes the perfect keepsake for hockey enthusiasts. Whether you're a fan or a collector, this hockey stick will be a prized addition to your collection.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Celebratory T-Shirts",
      description: "Take home the spirit of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive Tournament T-Shirts. These stylish, comfortable T-shirts are perfect for fans who want to show their support for their favorite teams and celebrate this prestigious tournament. Available in a range of sizes (S/M/L/XL), these T-shirts feature the tournament logo, organizer logos, and vibrant designs, making them the perfect keepsake for anyone attending the tournament. Grab Yours T-Shirts to Cheer your Team!",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Commemorative Caps",
      description: "Celebrate the excitement of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive Tournament Caps! These stylish caps, featuring the official tournament logo, are the perfect accessory for showing your support while keeping cool during the tournament. Whether you're cheering from the stands or strolling around town, these caps make a great keepsake and a fashionable reminder of this international tournament.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Mascot Fridge Magnet",
      description: "Take home a piece of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive MASCOT (Fridge Magnet)! Featuring GUDIYA, the official tournament mascot, this fun and colorful magnet is a perfect keepsake to remember the excitement of the tournament. Stick it on your fridge or any magnet support surface and relive the thrilling moments of the tournament every day. It's a great collectible for hockey fans and a charming gift for friends and family.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Tournament Logo Fridge Magnet",
      description: "Celebrate the Bihar Women’s Asian Champions Trophy 2024 with an exclusive “Tournament Logo Fridge Magnet”. Featuring the official tournament emblem reflecting a blend of sports and heritage, this sleek and stylish magnet is a perfect way to commemorate this historic event. Add a touch of sports pride to your home or office with this collectible magnet, and keep the memory of the tournament alive every day.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "White Tournament Logo Fridge Magnet",
      description: "Take home a piece of the “Bihar Women’s Asian Champions Trophy 2024” with our special White Tournament Logo Fridge Magnet. Featuring a clean and sleek white design with the official tournament logo, reflecting a blend of sports and heritage. Perfect for your fridge or any magnetic surface, this elegant keepsake is a stylish way to commemorate the tournament and support your favorite teams.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Mascot 'Gudiya' Soft Toy",
      description: "Bring home the charm of the Bihar Women’s Asian Champions Trophy 2024 with the adorable Gudiya soft toy. Inspired by the tournament’s official mascot, this cuddly soft toy is the perfect keepsake for fans of all ages. Made with soft, high-quality materials, the Gudiya toy is not only a great collector’s item but also a wonderful gift for children and hockey enthusiasts alike.",
      imgSrc: "/img/Mosquto.jpeg",
    },
  ];
  // Content data for the right side
  const contentData = {
    about: (
      <div className="card-container">
        <div className="card-body" style={{ lineHeight: "2" }}>
          <h3 className="card-title" style={{ fontSize: "22px" }}>Bihar Women’s Asian Champions Trophy 2024</h3>

          <p className="card-text">
            The Women’s Asian Champions Trophy (WACT) is a renowned international field hockey tournament, organized by the Asian Hockey Federation (AHF) since 2010. This event brings together the top women’s hockey teams from across Asia, competing for regional dominance. Previously held in Ranchi, Jharkhand, the tournament is now set for an exciting edition in 2024.
          </p><br />

          <p className="card-text">
            In 2024, the Government of Bihar will play a key role as the title sponsor and co-host of the Women’s Asian Champions Trophy, scheduled from 11th - 20th November 2024. This event marks a significant moment for Bihar, highlighting its emergence as a major supporter of international sports. It aims to showcase Bihar’s world-class sporting infrastructure, cultural heritage, and organizational skills. The tournament is also expected to boost the local economy through increased tourism and global attention.
          </p><br />

          <p className="card-text">
            The event is themed as <strong>“Hockey Ka Parv, Bihar Ka Garv”,</strong> emphasizing the pride Bihar takes in hosting such a prestigious tournament. Six leading Asian teams—India, Malaysia, China, South Korea, Japan, and Indonesia—will compete in this thrilling competition.
          </p><br />

          <p className="card-text">
            For more information, visit the official websites of Hockey India
            <b>
              <a
                href="https://hockeyindia.org"
                target="_blank"
                rel="noopener noreferrer"
                className="highlight-link"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                (https://hockeyindia.org)
              </a>
            </b>.
            <p>and Asian Hockey Federation at
              <b>
                <a
                  href="http://www.asiahockey.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="highlight-link"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  (http://www.asiahockey.org)
                </a>.
              </b>
            </p>
          </p>

          <img
            src="./img/ACTrajgir.jpg"
            alt="Description of the image"
            style={{ width: '100%', height: 'auto', objectFit: 'cover', marginTop: "15px" }}
          />
        </div>
      </div>
    ),
    logoMascot: (
      <div style={{ lineHeight: "2", padding: "20px", border: "1px solid #ddd", borderRadius: "8px" }}>
        {/* Tournament Logo Section */}
        <div style={{ marginBottom: "30px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>TOURNAMENT LOGO</h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            The logo for the Women's Asian Champions Trophy (WACT) 2024 symbolizes Bihar's rise on the international sports stage, blending its rich cultural and historical heritage with modern progress. Hosted in Rajgir, the logo integrates key elements that represent the essence of Bihar and its commitment to sports and women's empowerment.
          </p>
          <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Key Components of the Logo:</h5>

          <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "0px" }}>
            <div style={{ flex: "3" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>Bodhi Tree: Represents wisdom and resilience, a core part of Bihar's identity.</li>
                <li style={{ listStyle: "auto" }}>Hockey Stick and Ball: Highlights the essence of the tournament and Bihar’s cultural legacy.</li>
                <li style={{ listStyle: "auto" }}>Rajgir’s Sports Heritage: Emphasizes Rajgir’s historical connection to sports, now a modern sports hub.</li>
                <li style={{ listStyle: "auto" }}>Bihar as Host and Title Sponsor: Reflects Bihar's pride in being the host and sponsor of this prestigious event.</li>
                <li style={{ listStyle: "auto" }}>Competition Name: Highlights the significance of this international event.</li>
                <li style={{ listStyle: "auto" }}>Font and Typography: A combination of traditional and modern styles, symbolizing women’s empowerment through sports.</li>
              </ol>
            </div>
            <div style={{ flex: "1", marginLeft: "20px" }}>
              <img
                src="./img/LogoACT.jpeg"
                alt="Description of the image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          </div>
          <p style={{ textAlign: "justify" }}>
            The logo is a vibrant representation of Bihar’s dedication to sports and its rich cultural heritage.
          </p>
        </div>

        {/* Mascot Section */}
        <div style={{ lineHeight: "2", marginTop: "50px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>MASCOT “GUDIYA”</h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            The official mascot for the Bihar Women’s Asian Champions Trophy 2024 is “Gudiya,” inspired by the house sparrow, the state bird of Bihar. This small yet resilient bird symbolizes identity and resilience, reflecting the challenges it faces due to habitat loss. Through Gudiya, Bihar pays tribute to these qualities—qualities mirrored by the athletes competing in the tournament.
          </p>
          <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Key Symbolism of the Mascot: </h5>

          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={{ flex: "3" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>Mascot GUDIYA: "Gudiya" is a term of endearment for young girls, resonating with women across Bihar.</li>
                <li style={{ listStyle: "auto" }}>Athletic Design: Gudiya’s stance, holding a hockey stick, represents determination and readiness.</li>
                <li style={{ listStyle: "auto" }}>Colors:
                  <ol type="a" style={{ paddingLeft: "20px", marginTop: "10px", listStyleType: "lower-alpha" }}>
                    <li style={{ listStyle: "lower-alpha" }}>Yellow: Signifies energy and positivity.  </li>
                    <li style={{ listStyle: "lower-alpha" }}>Blue: Represents confidence and loyalty.  </li>
                    <li style={{ listStyle: "lower-alpha" }}>Brown and Gold: Reflects strength and progress.  </li>
                  </ol>
                </li>
                <li style={{ listStyle: "auto" }}>Strength: Gudiya’s bold pose embodies the resilience and athletic spirit of the players, connecting with Bihar’s natural heritage and pride.</li>
              </ol>
            </div>
            <div style={{ flex: "1", marginLeft: "20px" }}>
              <img
                src="./img/Mosquto.jpeg"
                alt="Description of the image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          </div>
          <p style={{ textAlign: "justify" }}>
            Gudiya captures the essence of the tournament, blending resilience with athletic excellence, and stands as a proud symbol of the event.
          </p>
        </div>
      </div>


    ),
    trophyTour: (
      <div style={{ marginBottom: "30px" }}>
        <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>Trophy Tour  (Trophy Gaurav Yatra)</h2>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          The Trophy Tour, known as "Trophy Gaurav Yatra," is a key promotional event leading up to the Women’s Asian Champions Trophy 2024 in Bihar.
          This initiative aims to engage the public, create excitement around the tournament, and promote hockey across three states and all 38 districts of Bihar.
          The tour offers communities a chance to see the coveted trophy up close, fostering widespread support and enthusiasm for the event.
        </p>
        <div>
          <video
            src="/img/TrophitourVideo.mp4"
            autoPlay
            muted
            loop
            playsInline
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Why Trophy Tour?</h5>

        <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "0px" }}>
          <div style={{ flex: "3" }}>
            <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
              <li style={{ listStyle: "auto" }}>Build Excitement: Generate anticipation for the Women’s Asian Champions Trophy.</li>
              <li style={{ listStyle: "auto" }}>Celebrate Bihar: Create a buzz for the tournament, highlighting Bihar’s role in hosting its first international event at the Rajgir Sports Academy.</li>
              <li style={{ listStyle: "auto" }}>Unify Through Sports: Invite the entire sporting community to join this celebration, showcasing Rajgir to the world through sports.</li>
              <li style={{ listStyle: "auto" }}>Increase Media Exposure: Maximize visibility through media coverage and digital campaigns, attracting fans and raising awareness.</li>
            </ol>
          </div>

        </div>
        <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Tour Timeline above to Highlights</h5>

        <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "0px" }}>
          <div style={{ flex: "3" }}>
            <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
              <li style={{ listStyle: "auto" }}>
                Trophy Showcase: The trophy is the centerpiece of the tour, displayed at various stops to generate local interest and pride.
              </li>
              <li style={{ listStyle: "auto" }}>
                Souvenir Distribution:
                <ul style={{ paddingLeft: "20px" }}>
                  <li>20 Hockey Signature Sticks will be distributed as special souvenirs in each district.</li>
                  <li>100 Fridge Magnets per district will be given out to promote the Women’s Asian Champions Trophy.</li>
                </ul>
              </li>
              <li style={{ listStyle: "auto" }}>Selfie Points: Designated selfie points with branded backgrounds will encourage fans to capture and share their excitement on social media.</li>
            </ol>
          </div>

        </div>
        <img
          src="./img/Trophitour.jpeg"
          alt="Description of the image"
          style={{ width: '100%', height: 'auto', objectFit: 'cover', marginTop: "15px", marginBottom: "20px" }}
        />
        <p style={{ textAlign: "justify" }}>
          The Trophy Gaurav Yatra aims to honor the sport of hockey, inspire future champions, and celebrate Bihar's commitment to sports excellence. Join us on this exciting journey leading up to the tournament’s grand kickoff!
        </p>
        <img
          src="./img/TrophitourNational.jpeg"
          alt="Description of the image"
          style={{ width: '100%', height: 'auto', objectFit: 'cover', marginTop: "15px", marginBottom: "20px" }}
        />

      </div>
    ),
    matchFixtures: (
      // <Card>
      //   <Card.Body>
      //     <Card.Title style={{ fontSize: "22px" }}>Match Fixtures & Results</Card.Title>
      //     <Card.Text className="text-justify">
      //       Explore upcoming matches and view past results here. Click the link below to see the complete schedule and results.
      //     </Card.Text>
      //     <Button>
      //       <a
      //         href="https://www.hockeyindia.org/schedule/matches/womensasianchampionstrophy2024-3348" // Replace with actual match fixtures URL
      //         target="_blank"
      //         rel="noopener noreferrer"
      //         className="light-link btn"

      //       >
      //         View Match Fixtures & Results
      //       </a>
      //     </Button>
      //   </Card.Body>
      // </Card>

      <div style={{
        backgroundImage: `url('/img/ACTLOGOFORZPAGE.png')`, backgroundSize: 'contain', // Or 'cover' depending on the behavior you want
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',


      }}>
        <div style={{ marginTop: "20px" }}>
          <h3>Bihar Women’s Asian Champions Trophy Rajgir 2024</h3>
          <h5 style={{ color: "#FF3E5D", marginTop: "8px" }}>11 Nov, 2024 - 20 Nov, 2024</h5>
          <h5 style={{ marginTop: "8px" }}>
            Rajgir, Bihar
          </h5>
        </div>

        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          p={8}
          w="100%"
          maxW="1200px"
          mx="auto"
        >



          {/* Upcoming Matches Section */}
          <Box flex="1">
            <Text fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" style={{ color: "#421B16" }}>Upcoming Matches</Text>
            <Text color="gray.500" mt={2}>
              Find a full schedule of upcoming events, news, and updates on tournaments and players.
            </Text>
            <VStack spacing={6} mt={8} maxH="300px" overflowY="scroll">
              {matches.map((match) => (
                <HStack
                  key={match.id}
                  w="100%"
                  justify="space-between"
                  cursor="pointer"
                  onClick={() => handleMatchClick(match)} // Click handler to update the left side
                  _hover={{ bg: "gray.100" }}
                  p={4}
                  borderRadius="md"
                >
                  <VStack>
                    <Text fontSize="5xl" fontWeight="bold" color="green.400">{match.date.split(' ')[0]}</Text>
                    <Text fontSize="md" color="gray.500">{match.date.split(' ')[1]}</Text>
                  </VStack>
                  <Box flex="1" ml={4}>
                    <Text fontWeight="bold">{match.tournament}</Text>
                    <Text color="gray.500">{match.time}, {match.player1} - {match.player2}</Text>
                  </Box>
                </HStack>
              ))}
            </VStack>
          </Box>
          {/* Match Details Section */}
          <Box flex="1" mr={{ base: 0, md: 8 }} mb={{ base: 8, md: 0 }} >
            <Text fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" style={{ color: "#2C4089" }}>Match Details</Text>
            <Text color="gray.500" mt={2}>
              Find out updates on major tournament results, including scores, stats, and standings for this season.
            </Text>
            <Flex
              direction={{ base: "column", md: "row" }} // Column layout for small screens, row for larger
              align={{ base: "center", md: "center" }}
              mt={8}
              style={{ marginTop: "50px" }}
            >
              <VStack spacing={4}>
                <Image
                  boxSize={{ base: "100px", md: "150px" }} // Adjust image size based on screen size
                  objectFit="cover"
                  src={selectedMatch.player1Img}
                  alt={selectedMatch.player1}
                />
                <Text fontWeight="bold" fontSize={{ base: "3xl", md: "5xl" }} style={{ marginTop: "30px" }}>{selectedMatch.player1}</Text>
              </VStack>

              <VStack mx={{ base: 0, md: 6 }} my={{ base: 6, md: 0 }}>
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.500">
                  {selectedMatch.date} {selectedMatch.time}
                </Text>
                <Text fontSize={{ base: "3xl", md: "4xl" }} fontWeight="bold">
                  {selectedMatch.score ? selectedMatch.score : "TBD"}
                </Text>
                <Text color="gray.500" textAlign="center">
                  {selectedMatch.tournament} <br />
                  {selectedMatch.venue}
                </Text>
              </VStack>

              <VStack spacing={4}>
                <Image
                  boxSize={{ base: "100px", md: "150px" }} // Adjust image size based on screen size
                  objectFit="cover"
                  src={selectedMatch.player2Img}
                  alt={selectedMatch.player2}
                />
                <Text fontWeight="bold" fontSize={{ base: "3xl", md: "5xl" }}
                  style={{ marginTop: "30px" }}
                >{selectedMatch.player2}</Text>
              </VStack>
            </Flex>
          </Box>
        </Flex></div>
    ),
    howToreachRajgir: (
      <div className="travel-guide-container">

        {/* Card displaying the entire travel information */}
        <div className="travel-card">
          <h2 className="travel-title">How To Reach Rajgir</h2>

          <p className="card-description">
            <strong>Rajgir is an ancient city, originally known as Rajagriha, located about 15 km from Nalanda. It holds historical significance in Buddhism, Jainism, and ancient Indian history. Here’s how you can reach Rajgir through various modes of transportation:</strong>
          </p>

          <h3 className="section-title">1. Nearest Airport</h3>
          <ul className="card-list">
            <li style={{ listStyle: "auto" }}>
              <strong>Jay Prakash Narayan International Airport (Patna)</strong> – About 100 km from Rajgir.
              <ul>
                <li style={{ listStyle: "outside" }}><em>From the Airport:</em> You can take a taxi or bus from Patna to Rajgir. It takes around 2.5 to 3 hours to reach Rajgir by road.</li>
              </ul>
            </li>
            <li style={{ listStyle: "auto" }}>
              <strong>Gaya International Airport (Gaya)</strong> – About 69 km from Rajgir.
              <ul>
                <li style={{ listStyle: "outside" }}><em>From the Airport:</em> You can take a taxi or bus from Gaya to Rajgir. It takes around 1.5 to 2 hours by road.</li>
              </ul>
            </li>
          </ul>

          <h3 className="section-title">2. By Train</h3>
          <ul className="card-list">
            <li style={{ listStyle: "auto" }}><strong>Rajgir Railway Station:</strong> Located within the town, connected to major cities like Patna, Gaya, and Nalanda.</li>
            <li style={{ listStyle: "auto" }}><strong>Gaya Junction:</strong> About 78 km from Rajgir. It is well-connected to major Indian cities.</li>
            <li style={{ listStyle: "auto" }}><strong>Patna Junction:</strong> About 100 km from Rajgir. It serves as a major railway hub with frequent trains to various parts of India.</li>
            <li style={{ listStyle: "auto" }}><em>From Patna/Gaya:</em> You can take a local train or bus from these stations to Rajgir.</li>
          </ul>

          <h3 className="section-title">3. By Road</h3>
          <ul className="card-list">
            <li style={{ listStyle: "auto" }}>Rajgir is well-connected by road to major cities in Bihar and neighboring states.</li>
            <li style={{ listStyle: "auto" }}><strong>From Patna:</strong> The distance is around 100 km. You can take a bus, taxi, or self-drive via the Patna-Bihar Sharif-Rajgir road, which takes about 2.5 to 3 hours.</li>
            <li style={{ listStyle: "auto" }}><strong>From Gaya:</strong> The distance is around 78 km, and it takes about 2 hours by road.</li>
          </ul>

          <h3 className="section-title">4. By Bus</h3>
          <ul className="card-list">
            <li style={{ listStyle: "auto" }}>Regular buses run from Patna, Gaya, and Nalanda to Rajgir. The Bihar State Tourism Development Corporation (BSTDC) also offers bus services for tourists.</li>
            <li style={{ listStyle: "auto" }}><strong>From Patna or Gaya:</strong> It takes approximately 2-3 hours by bus.</li>
          </ul>

          <p className="conclusion-text">
            Choose the mode of transportation that suits you best and explore the historical charm of Rajgir.
          </p>
        </div>
      </div>
    ),

    adjacentPlacesofAttraction: (
      <div style={{ lineHeight: "2", padding: "20px", border: "1px solid #ddd", borderRadius: "8px" }}>
        {/* Tournament Logo Section */}
        <div style={{ marginBottom: "30px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}> Adjacent Places of Attraction</h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            <strong>Around RAJGIR</strong><br />
            The ancient city, Rajagriha, now Rajgir lies at 15 km from Nalanda. It has been mentioned in the
            Hindu epic Ramayan that it was named Vasumati by King Vasu. 'The house of kings' as it was known,reached its zenith during the Magadhan period.
            Once the capital of Bimbisara, it was a prominent centre for Hindus, Buddhists and Jains. Also men-
            tioned in the writings of Buddhaghosha, a Buddhist scholar, it had 32 main gates and 64 minor
            gates surrounded by hills . Lord Buddha and Lord Mahavir spent many years here. Rajgir is also fa-
            mous for hot water springs. RajgirMahotsav is an international festival organised by Bihar Tourism.

          </p>



          {/* <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Key Components of the Logo:</h5> */}

          <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "30px" }}>
            <div style={{ flex: "12" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>
                  <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                    <Box flex="1">
                      <Text fontWeight="bold">Rajgir Zoo Safari & Nature Safari</Text>
                      <Text>
                        The biodiversity of Rajgir Zoo Safari includes carnivores, quiet herbivores, avi-fauna, and a large variety of flora that play a significant role in maintaining and balancing the ecosystem. Find the Royal Bengal tiger, lion, leopard, sloth bear, chital, sambar, spiral-horned black buck, hog-deer, barking deer, colourful butterflies, etc. One would be amazed to see umpteen varieties of plants. Nature Safari is present at Rajgir-Jetian valley; one can marvel at the picturesque scenery of verdant greenery. While one's sojourn here; one can witness the natural beauty of rocky hills, immaculate valleys, and a breathtaking landscape.
                      </Text>
                    </Box>
                    <Image
                      src="./img/rajgirSSS.jpg"
                      alt="Description of the image"
                      objectFit="cover"
                      boxSize={{ base: "100%", lg: "50%", height: "10rem" }} // Adjusts size based on screen
                    />
                  </Stack>
                </li>


                <li style={{ listStyle: "auto" }}><strong>Vishwa Shanti Stupa</strong><br />
                  The white marble stupa was built in 1969 on Ratna- giri Hill. Four golden statues of the Buddha adorn its dome. It rises 129 ft and can be reached by a stairway/ropeway.
                </li>
                <li style={{ listStyle: "auto" }}><strong> Gridhakuta Hill-Top</strong><br />
                  The quintessential meditation seat of Lord Buddha is set on the top of Gridhakuta Hill. Many sermons were preached by him to his disciples here. The red brick spot is rectangular in shape, confined within 2 ft high walls on three sides.
                  Make offerings of prayers and coloured flags. An- cient writings of ZuanZang tell that its paths and stairway were constructed by King Bimbisara.
                </li>
                <li style={{ listStyle: "auto" }}><strong>Pandu Pokhar</strong><br />
                  Visit the leisure attraction to marvel at the replicas of the Buddha, take a walk in the rose garden and fun garden; relish different traditional treats in the restaurants or go shopping handicrafts.
                </li>
              </ol>
            </div>

          </div>

        </div>

        {/* Mascot Section */}
        <div style={{ lineHeight: "2", marginTop: "30px" }}>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            <strong>Around NALANDA</strong><br />

          </p>
          {/* <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Key Components of the Logo:</h5> */}

          <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "30px" }}>
            <div style={{ flex: "12" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>
                  <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                    <Box flex="2">
                      <Text fontWeight="bold">Ruins of Vikramshila University</Text>
                      <Text>
                        The university thrived between the 9th-13th century CE. Founded by the Pala King Dharmapala, it was a bustling education centre. Subjects taught included Philosophy, Theology, Metaphysics, Grammar, Logic, Tantrism, etc. A colossal square remnant of the double-storied stupa with a cruciform structure, a library and votive stupas have been unearthed as are a Tibetan and a Hindu temple.
                      </Text>
                    </Box>
                    <Image
                      src="./img/NalandaACT.jpg"
                      alt="Description of the image"
                      objectFit="cover"
                      boxSize={{ base: "100%", lg: "50%", }} // Adjusts size based on screen
                    />

                  </Stack>
                  <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                    <a
                      href="https://tourism.bihar.gov.in/en/destinations/nalanda"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "inline-block",
                        padding: "15px 30px",
                        backgroundColor: "#ff9800", // Button background color (orange)
                        color: "white", // Text color
                        textDecoration: "none", // Remove underline
                        fontWeight: "bold",
                        borderRadius: "50px", // Rounded button
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)", // Shadow effect
                        transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition
                        fontSize: "16px",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#e65100"; // Darker orange on hover
                        e.target.style.transform = "scale(1.1)"; // Slightly enlarge the button
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ff9800"; // Reset background color
                        e.target.style.transform = "scale(1)"; // Reset scaling
                      }}
                    >
                      Explore Nalanda
                    </a>

                    {/* <p style={{ marginTop: "10px", color: "#555", fontSize: "14px" }}>
              Know more by visiting this link
            </p> */}
                  </div>
                </li>


                <li style={{ listStyle: "auto" }}><strong>Nalanda University Ruins</strong><br />
                  Remains of the eminent Nalanda University of the 5th century CE are one of the most popular destinations. It accommodated about 2000 teachers and 10,000 students of Buddhism. Scholars streamedin from far and wide. It shone as an admiration for quality in education, architecture and as a reveredseat of Buddhism. Originally the area covered by the university was quite large as has been mentioned in the writings of famous 7th century CE Chinese traveler Xuanzang, also known as Hiuen Tsang. It reached the zenith of perfection in education and architecture during the periods of Guptas and Palas. Built in red brick, it exhibits ruins of stupas, viharas, etc.

                </li>
                <li style={{ listStyle: "auto" }}><strong>Digambar Jain Temple, Kundalpur</strong><br />
                  Digambar Jain Temple, Kundalpur is a beautiful struc- ture constructed in white marble. As believed by the Digambar sect of Jains, it is the birthplace of Lord Mahavir, the 24th Tirthankar. Two buildings beside the main complex incorporate 72 images of the Jinas.
                </li>
                <li style={{ listStyle: "auto" }}>

                  <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                    <Box flex="2">
                      <Text fontWeight="bold">Jal mandir, Pawapuri</Text>
                      <Text>
                        The revered religious destination for Jains is where Lord Mahavir attained Nirvana. Jal Mandir, the temple in the middle of the vast lotus tank was built at this site where he was cremated. It is situ- ated at a distance of about 35 km from Rajgir.
                      </Text>
                    </Box>
                    <Image
                      src="./img/Nalanda4.jpg"
                      alt="Description of the image"
                      objectFit="cover"
                      boxSize={{ base: "100%", lg: "50%", }} // Adjusts size based on screen
                    />
                  </Stack>

                </li>
                <li style={{ listStyle: "auto" }}><strong>Archaeological Museum</strong><br />
                  It has a collection of statues of Lord Buddha, sculp- tures carved on basalt stones, bronze, etc. from Buddhist and Hindu times. Exhibits include terra- cotta items, iron implements, stuccos, inscriptions on stones and copper, coins and potteries, stupas on leaf of pipal tree, charred rice samples, etc.
                </li>
                <li style={{ listStyle: "auto" }}><strong>Giriyak Hill Stupa</strong><br />
                  Situated on the top of Giriyak Hill, the 21 ft highcylindrical stupa was built out of red brick with a
                  diameter of 28 ft. A stone path, 20 ft wide leadsto the stupa. Marvel at the spectacular sight of the
                  green valley with the serene Ghorakatora Lake.

                </li>
                <li style={{ listStyle: "auto" }}><strong>Ghora Katora</strong><br />
                  Lying near Rajgir, Ghora Katora means 'Horse Bowl'; it is a natural lake. It's outline looks like a horse and is encompassed by hills on three sides. Migratory birds from Siberia and central Asia reach here during winters. Marvel at the seventy ft tall Buddha statue that stands in the centre of the lake.
                </li>
                <li style={{ listStyle: "auto" }}><strong>The Xuanzang Memorial</strong><br />
                  The memorial was built (1984) as a mark of respectto the Chinese scholar and traveller, Xuanzang; hetravelled to India in the 7th century CE and stayedfor about five years, learning and translating theteachings of Buddhism into Chinese. Behold theremarkable statue of ZuanZang, the stone structure with the feet impression of Lord Buddha, worshipped by him; painting of Sheelbhadra, his guru;colourful paintings on the walls and ceilings, etc.
                </li>

              </ol>
            </div>

          </div>
        </div>



        <div style={{ lineHeight: "2", marginTop: "30px" }}>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            <strong>Around GAYA</strong><br />

          </p>
          {/* <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Key Components of the Logo:</h5> */}

          <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "30px" }}>
            <div style={{ flex: "12" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>
                  <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                    <Box flex="2">
                      <Text fontWeight="bold">Mahabodhi Temple, Bodhgaya</Text>
                      <Text>
                        The 170 ft. tall temple lies as a slender Pyramidtill its neck, which is cylindrical in shape. The top
                        has chatras which signify sovereignty of religion.The architecture is a blend of different cultures.
                        It certainly has an influence of the Gupta era andexhibits inscriptions describing visits of pilgrims
                        from Sri Lanka, Myanmar and China between 7thand 10th century CE. Marvel at the huge image of
                        the Buddha in sitting post  ure touching the earthby his right hand (bhoomisparsh); in this posture,
                        Buddha attained enlightenment.

                      </Text>
                    </Box>
                    <Image
                      src="./img/gayaturist.jpg"
                      alt="Description of the image"
                      objectFit="cover"
                      boxSize={{ base: "100%", lg: "50%", }} // Adjusts size based on screen
                    />
                  </Stack>
                </li>


                <li style={{ listStyle: "auto" }}><strong>Mahabodhi Tree</strong><br />
                  The Buddha attained supreme knowledge and enlightenment sitting onVajrasana, the seat of stability, a stone platform under the Bodhi Tree.

                </li>
                <li style={{ listStyle: "auto" }}><strong>80 ft Buddha Statue</strong><br />
                  The first gigantic Buddha statue built in modernIndia, famous as the 80 ft Buddha Statue was com-
                  missioned and consecrated in 1989; the ceremonywas attended by His Holiness the Dalai Lama. It issituated next to the Mahabohdi Temple.

                </li>
                <li style={{ listStyle: "auto" }}><strong>AnimeshLochan Stupa</strong><br />
                  It is believed that the Buddha continued to gaze at the great Bodhi tree, absorbed in divine content- ment, for one week, without blinking his eyes after attaining enlightenment.
                </li>
                <li style={{ listStyle: "auto" }}><strong>Chankramana Asthal</strong><br />
                  After attaining enlightenment, Buddha walked around the sacred place; his footprints are figured in black stone as also are 19 lotus carvings.
                </li>
                <li style={{ listStyle: "auto" }}><strong>Ratnaghar</strong><br />
                  After the enlightenment, Buddha meditated; a holy light in five different colours came out from his persona. As a tribute of that divine event a stat- ue in meditating posture was sculpted. These five colours are present in the Buddhist flag.

                </li>
                <li style={{ listStyle: "auto" }}><strong>Shree Mahabodhi Mahavihar</strong><br />
                  Laying before the access to Mahabodhi Temple, it was built by the Mahabodhi Society of India in 2007; ancient relic coffers of Sariputra and Maham- alyayan, Buddha's two pupils are secured here.
                </li>
                <li style={{ listStyle: "auto" }}><strong>Dungeshwari Hill Cave</strong><br />
                  Gautama is believed to have meditated here for a long period before moving to Bodhgaya. A gold- en bony Buddha statue is enshrined in one of the cave temples; in the other, a tall Buddha's statue is placed. A Hindu goddess Dungeshwari is also en- shrined inside the cave temple. A concreted walk- way leads to the temples. There are many Buddha image carvings.
                </li>
                <li style={{ listStyle: "auto" }}><strong>Vishnupad Temple</strong><br />
                  In 1787, Devi Ahilya Bai Holkar got the present structure of the Vishnu Temple constructed on the bank of River Phalgu. It has a beautiful architec- ture and attracts devotees in large numbers.
                </li>
              </ol>
            </div>

          </div>
        </div>
        <div style={{ lineHeight: "2", marginTop: "30px" }}>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            <strong>Around NAWADA</strong><br />

          </p>
          {/* <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>Key Components of the Logo:</h5> */}

          <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "30px" }}>
            <div style={{ flex: "12" }}>
              <ol style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>
                  <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                    <Box flex="2">
                      <Text fontWeight="bold">Kakolat Waterfalls</Text>
                      <Text>
                        The fall is a major point of attraction for tourists and visitors. Lying about 33 km from Nawada, it is more than 150 ft high.

                      </Text>
                    </Box>

                  </Stack>
                </li>



              </ol>

            </div>


          </div>
          <p><strong>To explore Bihar Tourism, visit on the official website of Bihar Tourism at <a href="https://tourism.bihar.gov.in/en" target="_blank" style={{ color: "blue", textDecoration: "underline" }}>https://tourism.bihar.gov.in/en </a></strong>
          </p>
        </div>
      </div>
    ),

    tournamentSouvenirs: (
      <>

        {/* <Container maxW="container.lg" py={10}> */}
        <Heading textAlign="center" mb={8}>
          Exclusive Tournament Souvenir for Sale
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
          {items.map((item, index) => (
            <SlideFade in={true} offsetY="20px" key={index}>
              <Box
                display="flex"
                alignItems="center"
                boxShadow="lg"
                borderRadius="md"
                overflow="hidden"
                transition="transform 0.2s"
                _hover={{ transform: 'scale(1.02)' }}
              >
                <Stack p={5} spacing={4} flex={1}>
                  <Heading style={{ textAlign: "justify" }} size="lg">{index + 1 + ". "}{item.title}</Heading>
                  <Text style={{ textAlign: "justify" }}>{item.description}</Text>
                </Stack>
                <Box flexShrink={0} width="200px">
                  <Image
                    src={item.imgSrc}
                    alt={item.title}
                    objectFit="contain"
                    boxSize="100%"
                    boxShadow="md"
                    aspectRatio={{ base: '1/2', md: '2/2' }} // Maintain aspect ratio
                  />
                </Box>
              </Box>
            </SlideFade>
          ))}
        </SimpleGrid>
        {/* </Container> */}
      </>
    ),
    ticketLink: (
      <div className="portal-container">
        <div className="portal-body" style={{ lineHeight: "2" }}>
          <h2 className="portal-title">Get You Entry Pass</h2>
          <p className="portal-text">
            Welcome to the official ticket booking portal for the Women's Asian Champions Trophy Hockey Tournament 2024, taking place at the renowned Rajgir Sports Complex, Bihar, from 11th to 20th November. This event offers Bihar a unique opportunity to showcase its world-class sports infrastructure, rich cultural heritage, and exceptional organizational skills. As the host, Bihar aims to leave a lasting impression on the global sporting community, fans, and elevate the nation’s standing on the international stage.
          </p>
          <p className="portal-text">
            The tournament’s tagline, “Hockey Ka Parv, Bihar Ka Garv,” embodies the pride of the region. Six top Asian teams—India, Malaysia, China, South Korea, Japan, and Indonesia—will battle it out for the title. Join us for this thrilling event, featuring three action-packed matches on each non-rest day, and experience the best of women’s hockey!
          </p>

          <h3 className="portal-subtitle">Tournament Details:</h3>
          <ul className="portal-list">
            <li>Dates: 11th November – 20th November</li>
            <li>Venue: Rajgir Sports Complex, Bihar</li>
            <li>Match Days: Three matches per day on non-rest days</li>
          </ul>

          <p className="portal-text">
            Don’t miss the chance to cheer for your favorite teams live! Secure your seats now and be part of this historic event. Book your passes now! Seats available on a first-come, first-served basis.
          </p>

          <p className="portal-text">
            For assistance, reach out at <a href="mailto:support@biharsports.org" className="highlight-link">support@biharsports.org</a> or call our helpline at +91 [Helpline Number] (10:30 AM to 05:30 PM).
          </p>

          <h3 className="portal-subtitle">Terms & Conditions</h3>
          <ol className="portal-terms-list">
            <li>The name of the ticket holder must match the name on their photo ID proof.</li>
            <li>The organizers are not responsible for the misuse of tickets. Any liability resulting from the misuse of this booking confirmation rests solely with the purchaser.</li>
            <li>Each QR-based entry ticket is valid for one person only. Re-entry is NOT permitted. For security reasons, the organizers reserve the right to search or frisk any ticket holder.</li>
            <li>Children aged 3 years and above must have a valid ticket for entry.</li>
            <li>The organizers reserve the right to deny admission or remove individuals for misconduct or violation of terms and conditions.</li>
            <li>The following items are not allowed in the stadium: firecrackers, cigarettes, bags/handbags, helmets, alcohol, food, drinks, bottles, lighters, matchboxes, tins, cans, coins, metal containers, mirrors, and other prohibited substances.</li>
            <li>The organizers are not responsible for any injuries, loss, or theft of personal belongings of the ticket holder.</li>
            <li>All matches are subject to Force Majeure conditions.</li>
            <li>Gates will open 30 minutes before the start of the first match each match day.</li>
            <li>All pass holders are subject to the terms and conditions printed on their pass, in addition to the conditions listed here.</li>
          </ol>

          <p className="portal-text">
            Please ensure you read and comply with these terms for a smooth and enjoyable event experience.
          </p>
        </div>
      </div>
    ),
    contactDetails: (
      <Card>
        <Card.Body>
          <Card.Title>Important Contact Details</Card.Title>
          <Card.Text className="text-justify">Contact information for event organizers and assistance.</Card.Text>
        </Card.Body>
      </Card>
    ),
  };


  const formatKey = (key) => {
    // Define a mapping for specific keys to their corresponding string values
    const keyMapping = {
      about: "About",
      logoMascot: "Logo & Mascot",
      trophyTour: "Trophy Tour",
      matchFixtures: "Match Fixtures",
      howToreachRajgir: "How to reach Rajgir",
      adjacentPlacesofAttraction: "Adjacent Places of Attraction",
      ticketLink: "Book Your Pass",
      contactDetails: "Contact Details",
      tournamentSouvenirs: "Tournament Souvenirs"


    };

    // Return the mapped value if it exists, otherwise format the key
    return keyMapping[key]

  };


  return (
    <>
      <Breadcrumb
        pageName={BiharACT}
        pageTitle={"Bihar ACT"}
        img_url={"./img/ACTBiharbanner.jpeg"}
        mobile_banner="./img/mobileview_vallyball.jpeg"
      />
      <Container style={{ marginTop: "30px", marginBottom: "30px" }}>
        <div
          className={`container`}
          style={{
            borderRadius: "8px",
            marginTop: "15px",
            marginBottom: "5px",
          }}
        >
          <Tabs
            isManual
            variant="enclosed"
            onChange={(index) => {
              // Map the index to the corresponding content key
              const selectedKey = Object.keys(contentData)[index];
              setSelectedContent(selectedKey);
            }}
          >
            {/* Menu Tabs */}
            <TabList
              style={{
                display: 'flex',
                flexWrap: 'wrap', // Allow items to wrap to the next row
                gap: '5px', // Optional: Add spacing between tabs
              }}
            >
              {Object.keys(contentData).map((key, index) => (
                <Tab
                  key={key}
                  className="tabb"
                  as="b"
                  style={{
                    backgroundColor: selectedContent === key ? "rgb(49 62 69)" : "#24528e", // Change background if active
                    color: "white",
                    padding: "5px 5px",
                    cursor: "pointer",
                    marginBottom: '20px',
                    // Responsive font size
                    fontSize: '12px', // Default for medium screen (md)
                  }}
                >
                  {formatKey(key)}

                </Tab>
              ))}
            </TabList>


            {/* Content Panels */}
            <TabPanels
              style={{
                borderLeft: "1px solid #cfd9de",
                borderRight: "1px solid #cfd9de",
                borderBottom: "1px solid #cfd9de",
                borderRadius: "0px 0px 5px 5px",
              }}
            >
              {Object.keys(contentData).map((key) => (
                <TabPanel key={key}>
                  {/* Render the content based on selected tab */}
                  {contentData[key]}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>

      </Container>


    </>
  );
}
