import { SimpleGrid, Square, Text, Box, Center, Container } from '@chakra-ui/react';
import React from 'react';

const Inspiration = () => {
    return (
        <>


            <div style={{ backgroundImage: 'linear-gradient(to bottom, #0a3272, white)' }}>


                <Text
                    // fontWeight="bold"
                    textTransform="uppercase"
                    p={'4px'}
                    pb={'12px'}
                    // align={"right"}
                    fontSize={["30", "33"]}
                    letterSpacing="wide"
                    color="black"
                    pt={[2, 5, 10]}
                    px={[4, 10, 20]}
                    fontWeight='extrabold'
                >
                    प्रेरणा

                </Text>

                <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
                    <Center as={"text"} h='100px' textAlign={'left'} p='3%'
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize={["8", "16"]}
                        letterSpacing="wide"
                        color="teal.800"
                    >
                        इस योजना का उद्देश्य विभिन्न खोज प्रतियोगिताओं द्वारा  चयनित खिलाड़ियों को वित्तीय सहायता प्रदान कर उन तक
                        उच्चतम प्रशिक्षण के साथ अच्छी गुणवत्ता के खेल संबंधी उपकरणों की पहुंच को सुनिश्चित करना  तथा उनके प्रदर्शन में
                        सुधार लाना है।  इस योजना के अंतर्गत राज्य के खिलाड़ियों को वर्ष में एक बार 1.5 लाख रूपये तक की वित्तीय
                        सहायता प्रदान की जाएगी। इस योजना का लक्ष्य स्थानीय स्तर या राज्य स्तर पर बेहतर प्रदर्शन करने वाले खिलडियों के
                        साथ जूनियर राष्ट्रीय प्रतियोगिता में मेडल लाने वाले तथा  सीनियर राष्ट्रीय प्रतियोगिताओं में राज्य का प्रतिनिधित्व करने
                        वाले खिलाड़ियों को सहायता राशि प्रदान करना है।    </Center>

                </SimpleGrid>
            </div>

        </>
    )

}
export default Inspiration