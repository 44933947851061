import { Box, Text, Heading, Image, Badge } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const BlogCard = ({ blog }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <Link to={`/blogsdetail`} state={{ blog }}>
      <Box
        border="1px solid #e2e8f0"
        borderRadius="md"
        p="4"
        cursor="pointer"
        _hover={{ boxShadow: "lg", transform: "scale(1.02)" }}
        transition="all 0.2s"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        w="300px"    // Fixed width
        h="400px"    // Fixed height
        boxShadow="md"
        bg="white"
      >
        {/* Image Section */}
        <Box
          position="relative"
          width="100%"
          height="60%"  // Occupying 60% of the card height
          borderRadius="md"
          overflow="hidden"
        >
          <Image
            src={blog.imageUrl} // Assuming `imageUrl` is part of the blog object
            alt={blog.title}
            objectFit="cover"
            width="100%"
            height="100%"
          />
        </Box>

        {/* Tag Section */}
        <Badge
          colorScheme="red"
          position="absolute"
          top="10px"
          left="10px"
          fontSize="sm"
          padding="4px 10px"
          borderRadius="full"
          bgColor="red.500"
          color="white"
        >
          BLOG
        </Badge>

        {/* Title Section */}
        <Heading size="lg" mt="4" noOfLines={2}>
          {blog.title}
        </Heading>
        
        {/* Content Section */}
        <Text noOfLines={3} mt="2" fontSize="md" color="gray.600">
          {blog.description}
        </Text>

        {/* Footer Section */}
        <Box display="flex" alignItems="center" mt="4" fontSize="md" color="gray.500">
          {/* <Text>By {blog.author}</Text>
          <Text mx="2">|</Text> */}
          <Text>{formatDate(blog.date)}</Text>
        </Box>
      </Box>
    </Link>
  );
};

export default BlogCard;
