import React from "react";

import stylesgfi from "./NationalSchoolGames.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
import Button from "react-bootstrap/Button";

// import Athlite from "./Athlite";

export default function NationalSchoolGames() {
  return (
    <>
      <Breadcrumb
        pageName={NationalSchoolGames}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />
      <div></div>
      <div>
        <h1 className={`${stylesgfi.title}`}>67 वां नेशनल स्कूल गेम्स 2023</h1>
      </div>

      <div className={`container ${stylesgfi.game_sgfi}`}>
        <a href="/athlet">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/running_sgfi.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "50px" }}
            />
            <p>एथलेटिक्स</p>
          </div>
        </a>

        <a href="/cricket">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/cricket_sgfi.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "50px" }}
            />
            <p>क्रिकेट</p>
          </div>
        </a>

        <a href="/sepakTakra">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/net_sgfi.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "50px" }}
            />
            <p>सेपक टाकरा</p>
          </div>
        </a>

        <a href="/weightlifting">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/weight_liftingsgfi.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "50px" }}
            />
            <p>भारोत्तोलन </p>
          </div>
        </a>

        <a href="/football">
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/soccer_player_sgfi.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "50px" }}
            />
            <p>फुटबॉल</p>
          </div>
        </a>

      </div>
      <a href="https://sgfionline.net/reports/MedalTally.aspx?NsgNbr=66" target="_blank">
        <Button variant="warning" style={{ marginBottom: "20px", color: "black" }}>
        मेडल तालिका ↗
        </Button>
      </a>

    </>
  );
}
