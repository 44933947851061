import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import Styles from "../../styles/SiteMap.module.css";
// import img from '../'

const Sitemap = () => {
  return (
    <>
      <Breadcrumb
        pageName="Sports-Equipment-Information"
        pageTitle="Sports-Equipment-Information"
        id="/#Sports-Equipment-Information"
        img_url="./img/Sports-Equipment-Information-desktop.jpg"
        mobile_banner="./img/Sports-Equipment-Information-mobile.jpg"
      />

      <h1>साइट मैप</h1>
      <br />

      <div className={Styles.container}>
        <div className={Styles.row}>
          <div className={`${Styles.boxmodel} `}>
            {/* <span className="top-title sitemap-title" style={{textAlign:"center"}}>
              Sitemap
            </span> */}
            <ul className={Styles.sitemap}>
              <li className="hmenu">
                <h4 className={Styles.mainmenu}>
                  <a href="/">होम </a>
                </h4>
              </li>
              <li>
                <h4 className={Styles.mainmenu}>हमारे बारे में</h4>
                <ul>
                  <li>
                    <a href="/aboutus" style={{ fontSize: "14px" }}>
                      परिचय
                    </a>
                  </li>
                  <li>
                    <a href="/visionandmission" style={{ fontSize: "14px" }}>
                      लक्ष्य, दूरदर्शिता एवं उद्देश्य
                    </a>
                  </li>
                  <li>
                    <a href="/sportlegecy" style={{ fontSize: "14px" }}>
                      हमारा खेल विरासत
                    </a>
                  </li>

                  <li>
                    <li>
                      <b>
                        <a href="/" style={{ fontSize: "16px" }}>
                          संदेश{" "}
                        </a>
                      </b>
                    </li>

                    <ul>
                      <li>
                        <a href="/chiefminister" style={{ fontSize: "14px" }}>
                          माननीय मुख्यमंत्री,बिहार
                        </a>
                      </li>
                      <li>
                        <a
                          href="/deputychiefminister"
                          style={{ fontSize: "14px" }}
                        >
                          माननीय उप मुख्यमंत्री, बिहार
                        </a>
                      </li>
                      <li>
                        <a href="/sports-minister" style={{ fontSize: "14px" }}>
                          माननीय मंत्री  खेल विभाग, बिहार
                        </a>
                      </li>
                      <li>
                        <a href="/principal-secretary-sports-department" style={{ fontSize: "14px" }}>
                          अपर मुख्य सचिव,कला,संस्कृति एवं युवा विभाग
                        </a>
                      </li>
                      <li>
                        <a href="/ravindran" style={{ fontSize: "14px" }}>
                          महानिदेशक सह मुख्य कार्यकारी अधिकारी, बिहार राज्य खेल
                          प्राधिकरण
                        </a>
                      </li>
                      <li>
                        <a href="/directorcumSecretaryBSSA" style={{ fontSize: "14px" }}>
                          निदेशक सह सचिव, बिहार राज्य खेल प्राधिकरण
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/organizationalStru" style={{ fontSize: "14px" }}>
                      संगठनात्मक संरचना
                    </a>
                  </li>
                  <li>
                    <a href="/prioritysports" style={{ fontSize: "14px" }}>
                      बिहार के प्राथमिकता वाले खेल
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <h4 className={Styles.mainmenu}>
                  <a>नीति एवं योजना </a>
                </h4>
                <ul>
                  <li>
                    <a
                      href="img/BSSA_Bye_Laws.pdf"
                      style={{ fontSize: "14px" }}
                    >
                      खेल नियम और विनियम
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/Sport_Rule_2023.pdf"
                      style={{ fontSize: "14px" }}
                    >
                      खेल नीति
                    </a>
                  </li>
                  <li>
                    <a href="/sportsreqrmtpolicy" style={{ fontSize: "14px" }}>
                      खेल नियुक्ति नीति
                    </a>
                  </li>
                  <li>
                    <a href="/kss" style={{ fontSize: "14px" }}>
                      खेल सम्मान समारोह
                    </a>
                  </li>
                  <li>
                    <a href="/sportswelfarefund" style={{ fontSize: "14px" }}>
                      खिलाड़ी कल्याण कोष
                    </a>
                  </li>
                  <li>
                    <a
                      href="/grantssportsfederations"
                      style={{ fontSize: "14px" }}
                    >
                      खेल संघो को अनुदान
                    </a>
                  </li>
                  <li>
                    <li>
                      <b>
                        <a href="/" style={{ fontSize: "16px" }}>
                          आगामी नीतियां{" "}
                        </a>
                      </b>
                    </li>
                    <ul>
                      <li>
                        <a
                          href="/sportsscholarshippolicy"
                          style={{ fontSize: "14px" }}
                        >
                          खेल छात्रवृति नीति
                        </a>
                      </li>
                      <li>
                        <a href="/inspiration" style={{ fontSize: "14px" }}>
                          प्रेरणा
                        </a>
                      </li>

                      <li>
                        <a href="/able" style={{ fontSize: "14px" }}>
                          सक्षम
                        </a>
                      </li>

                      <li>
                        <a href="/udaan" style={{ fontSize: "14px" }}>
                          उड़ान
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/">हमारी उपलब्धियां </a>
                </h4>
                <ul>
                  <li>
                    <a
                      href="/internationalachievement"
                      style={{ fontSize: "14px" }}
                    >
                      अंतर्राष्ट्रीय उपलब्धियां
                    </a>
                  </li>
                  <li>
                    <a href="/nationalachievement" style={{ fontSize: "14px" }}>
                      राष्ट्रीय उपलब्धियां
                    </a>
                  </li>

                  <li>
                    <a
                      href="/statelevelachievement"
                      style={{ fontSize: "14px" }}
                    >
                      राज्य स्तरीय उपलब्धियां
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/">खेल संस्थाएं </a>
                </h4>
                <ul>
                  <li>
                    <a
                      href="img/eklavya_Kendra.pdf"
                      target="_blank"
                      style={{ fontSize: "14px" }}
                    >
                      एकलव्य केंद्र
                    </a>
                  </li>
                  <li>
                    <a
                      href="img/Kic.pdf"
                      target="_blank"
                      style={{ fontSize: "14px" }}
                    >
                      खेलो इंडिया केंद्र
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/khel_bhawan_summary_new .pdf"
                      target="_blank"
                      style={{ fontSize: "14px" }}
                    >
                      खेल भवन
                    </a>
                  </li>

                  <li>
                    <a
                      href="img/Training_Center.pdf"
                      target="_blank"
                      style={{ fontSize: "14px" }}
                    >
                      प्रशिक्षण केंद्र
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/tenders">निविदाएं</a>
                </h4>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/">गैलरी </a>
                </h4>
                <ul>
                  <li>
                    <a href="/pressgallery" style={{ fontSize: "14px" }}>
                      तस्वीरें
                    </a>
                  </li>
                  <li>
                    <a href="/videos" style={{ fontSize: "14px" }}>
                      विडियो
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/">आयोजन एवं कार्यक्रम</a>
                </h4>
                <ul>
                  <li>
                    <a href="/eventorg" style={{ fontSize: "14px" }}>
                      आयोजित कार्यक्रम
                    </a>
                  </li>
                  <li>
                    <a href="/upcomingevents" style={{ fontSize: "14px" }}>
                      आगामी कार्यक्रम
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/">डाउनलोड </a>
                </h4>
                <ul>
                  <li>
                    <a href="/#" style={{ fontSize: "14px" }}>
                      सूचनाएं एवं परिपत्र
                    </a>
                  </li>
                  {/* <li>
                        <a href="/#"  style ={{fontSize: "14px"}}>
                        Press Release
                        </a>
                      </li> */}
                  <li>
                    <a href="/#" style={{ fontSize: "14px" }}>
                      विज्ञापन
                    </a>
                  </li>
                  <li>
                    <a href="/#" style={{ fontSize: "14px" }}>
                      वार्षिक कैलेंडर
                    </a>
                  </li>
                  <li>
                    <a href="/#" style={{ fontSize: "14px" }}>
                      वार्षिक प्रतिवेदन
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/">हमारा संपर्क </a>
                </h4>
                <ul>
                  <li>
                    <a href="/directory" style={{ fontSize: "14px" }}>
                      निर्देशिका
                    </a>
                  </li>
                  <li>
                    <a href="/contactus" style={{ fontSize: "14px" }}>
                      संदेश / प्रश्न छोड़ें
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <h4 className={Styles.mainmenu}>
                  <a href="/dsodirectory">डी.एस.ओ निर्देशिका</a>
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sitemap;
