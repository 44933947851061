import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Football() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67 वां नेशनल स्कूल गेम्स 2023-24 </strong>फुटबॉल चैंपियनशिप
        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
          67वें नेशनल स्कूल गेम्स 2023-24 के अंतर्गत फुटबॉल अंडर-17 (बालिका वर्ग) प्रतियोगिता का आयोजन
            दिनांक 25 दिसंबर 2023 से 29 दिसंबर 2023 तक बिहार के दो अलग अलग
            मैदानों - 1. पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना तथा 2. हार्डिंग
            पार्क, पटना में किया जा रहा है।
          </p>
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
            फुटबॉल प्रतियोगिता में खिलाड़ियों के प्रवेश करने की अंतिम तिथि 14
            दिसंबर 2023 तय की गई है। इसके साथ ही इस चैंपियनशिप में हिस्सा ले रहे
            खिलाड़ियों के लिए रिपोर्टिंग की तारीख 24 दिसंबर 2023 निर्धारित की गई
            है।
          </p>
          <br />
          <p>नियंत्रण कक्ष का पता: पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना</p>
          <p>नियंत्रण कक्ष के प्रभारी का नाम : मो. अफजल आलम</p>

          <p>मोबाइल नंबर: 7870931874</p>
          <br />
          <p>नोडल ऑफिसर का नाम: श्री जय नारायण कुमार, जिला खेल पदाधिकारी</p>
          <p>मोबाइल नंबर : 9661261352</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/soccer_player_sgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>फुटबॉल</p>
          <Button  variant="warning" style={{ margin: "20px", color: "black" }}>
            <a href="img/New_football.PDF" target="_blank">
              विवरण देखें
            </a>
          </Button>
        </div>
      </div>
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          अधिक जानकारी के लिए क्लिक करें ↗
        </Button>
      </a>
    </>
  );
}

export default Football;
