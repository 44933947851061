import React, { useEffect } from "react";
import { Box, Heading, Text, Button, Image } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb";

const BlogDetail = () => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Log the blog content to verify it's being passed correctly
    if (state?.blog?.content) {
      console.log("Blog Content:", state.blog.content);
    }
  }, [state]);

  if (!state?.blog) {
    return (
      <Box maxW="800px" mx="auto" mt="8" textAlign="center">
        <Heading size="lg" mb="4">
          ब्लॉग नहीं मिला
        </Heading>
        <Text>आप जिस ब्लॉग को खोज रहे हैं वह मौजूद नहीं है।</Text>
        <Button mt="4" onClick={() => navigate("/blogs")}>
          ब्लॉग पर वापस जाएँ
        </Button>
      </Box>
    );
  }

  const { blog } = state;

  return (
    <> <Breadcrumb
      pageName="D.S.O-Directory"
      pageTitle="D.S.O-Directory"
      id="#dso-directory"
      img_url="./img/annual khel 01.jpg"
      mobile_banner="./img/annual khel mobile view 576 x600 01.jpg"
    />
      <Box maxW="800px" minH="30.5vh" mx="auto" mt="8" p="4">
        {/* Title */}
        <Heading mb="2">{blog.title}</Heading>

        {/* Date */}
        <Text mb="4" color="gray.600">
          प्रकाशित तिथि: {formatDate(blog.date)}
        </Text>

        {/* Full Size Image */}
        <Box mb="4" borderRadius="md" overflow="hidden">
          <Image
            src={blog.imageUrl} // Assuming the blog object has an image URL property
            alt={blog.title}
            objectFit="cover"
            width="100%"
            height="400px" // Full-size image
          />
        </Box>

        {/* Content - Rendering HTML */}
        <Box
          mb="4"
          dangerouslySetInnerHTML={{ __html: blog.content }}
          style={{
            fontFamily: "Arial, sans-serif", // Adjust font family if necessary
            lineHeight: "1.6",
            color: "#333", // Dark text for better readability
          }}
        />

        {/* Button to go back */}
        <Button
          borderRadius="lg"
          bgColor="lightblue"
          mt="4"
          onClick={() => navigate("/blogs")}
        >
          ब्लॉग पर वापस जाएँ
        </Button>
      </Box></>
  );
};

export default BlogDetail;
