import React from "react";


import {
    Button,
    Checkbox,
    Flex,
    Text,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Image,
} from '@chakra-ui/react'

const RegistrationforKss = () => {
    return (
        <>
            <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}  bgGradient='linear(to-r, green.200, pink.500)' >
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={4} w={'full'} maxW={'md'}>
                        <Heading fontSize={'2xl'}>Registratiorn for kss</Heading>

                        <FormControl id="name">
                            <FormLabel>Full Name</FormLabel>
                            <Input type="name" />
                        </FormControl>

                        <FormControl id="gender">
                            <FormLabel>Gender</FormLabel>
                            <Input type="gender" />
                        </FormControl>

                        <FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input type="email" />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" />
                        </FormControl>
                        <Stack spacing={6}>
                       
                            <Button colorScheme={'blue'} variant={'solid'}>
                                Register
                            </Button>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Image
                        alt={'Login Image'}
                        objectFit={'cover'}
                        src={
                            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
                        }
                    />
                </Flex>
            </Stack>
        </>
    )
}

export default RegistrationforKss