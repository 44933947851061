import React, { useEffect, useState } from "react";

import styled from "../tenders/style.module.css";

import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
import { Helmet } from "react-helmet";
const AnnualReport = () => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/AnnualReport");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   handleFetchData();
  // }, []);

  return (
    // <div>
    <div className={styled.Container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sports Annual Report | Bihar State Sports Authority | BSSA
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की वार्षिक रिपोर्ट: इस पेज पर प्राधिकरण की वार्षिक गतिविधियों, खेल विकास की प्रगति, और विभिन्न कार्यक्रमों का विवरण शामिल है।"
        />
      </Helmet>
      <h1 className="pt-2" style={{ marginBottom: "10px" }}>
        वार्षिक प्रतिवेदन
      </h1>
      {/* input field for search the item */}
      <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for Annual Report"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col">
              क्र.सं
            </th>
            <th className={`${Styles.head}`} scope="col">
              विषय
            </th>
            <th className={`${Styles.head}`} scope="col">
              {" "}
              तिथि
            </th>
            <th className={`${Styles.head}`} scope="col">
              अंतिम तिथि
            </th>
            <th className={`${Styles.head}`} scope="col">
              डाउनलोड
            </th>
          </tr>
        </thead>
        {/* <tbody>
          {data
            .filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, i) => (
              <tr>
                <th scope="row">{i + 1}</th>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.name}
                </td>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.date}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.expiryDate}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  <a href={item.url} target="_blank">
                    <img className={Styles.table_image} src={img} />
                  </a>
                </td>
              </tr>
            ))}
        </tbody> */}
      </table>
    </div>
    // </div>
  );
};

export default AnnualReport;
