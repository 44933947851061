import React from "react";

import { SimpleGrid } from "@chakra-ui/react";
import GalleryCarousal from "./GalleryCarousal";
import VideoCarousal from "./VideoCarousal";

const GallaryAndVideo = () => {
  return (
    <div id="gallery-home">
      <div className="container">
        <SimpleGrid columns={[1, 2, 2, 2]}>
          {/* <GalleryThree /> */}
          {/* <GallerySection /> */}
          <GalleryCarousal />

          <VideoCarousal />
        </SimpleGrid>
      </div>
    </div>
  );
};

export default GallaryAndVideo;
