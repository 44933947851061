import React, { useEffect, useState } from "react";

// import TenderOne from "../"
import styled from "../tenders/style.module.css";
import img from "../../assets/images/Download.jpeg";
import Akash from "../../assets/images/Akash Kumar.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "../../styles/Gift.module.css";
import Styles from "../../styles/Tender.module.css";

import api from "../../utils/ApiMethod";

import { Heading, useMediaQuery } from "@chakra-ui/react";

import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
// import { FreeMode, Pagination } from "swiper/modules";

// import required modules
import { Navigation } from "swiper/modules";
import { Button, Container } from "react-bootstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import GameDetailsPage from "./GameDetailsPage";
import MedalTally38th from "./MedalTally38th";

function NationalGames() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);
  const slidesPerView = isSmallScreen ? 1 : 3;

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("http://localhost:5000/api/latestinformation");
      const res = await api.fetchData("/importantinformation");
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);


  return (
    <div  >
      <Breadcrumb
        pageName="Nation games 37"
        pageTitle="national games"
        id="#nationalGames"
        img_url="./img/national_games_banner_desktop.jpg"
        mobile_banner="./img/national_games_mobile_banner.jpg"
      />
      <div className={`container`} >
        <Heading p={5} fontSize="24px">
          38वीं राष्ट्रीय जूनियर एथलेटिक्स चैंपियनशिप 2023
        </Heading>
        <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "800",
            fontSize: "18px",
            marginTop: "40px",
            textAlign: "justify",
          }}
        >
          नवीनतम जानकारी
        </h4>

        <div className={`${Styles.resp} row`} >
          {/* <div> */}
          <Swiper
            autoplay={{
              delay: 2000,
            }}
            slidesPerView={slidesPerView}
            // rewind={true}
            navigation={true}
            modules={[Navigation]}
            // modules={[FreeMode, Pagination]}
            loop={true}
            spaceBetween={10}
            bgGradient={[
              "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
              //   'linear(to-t, blue.200, teal.500)',
              //   'linear(to-b, orange.100, purple.300)',
            ]}
            className={`${styles.mySwiper}`}
          // style={{"height" : "600px", border:"20px solid lightgray", margin:"0px"}}
          >

            {/* {data.map((item, index) => ( */}

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/sunnyRaj.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/RohitRaj.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/md_kaadir.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>



            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/piyushRaj.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/nisha_kumari.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/kisu_singh.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/38th_nationalatheletics.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    {/* <h1></h1> */}
                    <img src="img/DurgaSingh.jpeg" />

                    {/* <img
                      src={`https://api.biharsports.org/${elem.image.replace(
                        "public/images/",
                        "images/"
                      )}`}
                      alt={elem?.title}
                    /> */}
                    {/* <img   src={`http://localhost:5000/${elem.image.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}
                  </div>
                  {/* <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >

                  <h5>फेंसिंग</h5>
                  <h5>(2023)</h5>
                </div> */}
                </div>
              </div>
            </SwiperSlide>

            {/* ))} */}
          </Swiper>
          {/* </div> */}
        </div>

        {/* <div className={`${Styles.tbls} row container`}> */}
        <div className={`${Styles.resp} col-8`}>
          <MedalTally38th />
        </div>
        <div className={`${Styles.resp} col-4`}>

          {/* <a href="https://37nationalgamesgoa.in/" target="_blank" >
              <Button
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  margin: "15px",
                  fontWeight: "bold",
                }}
              >
                अधिक जानकारी के लिए क्लिक करें ↗
              </Button>
            </a> */}

        </div>

        {/* </div> */}



        {/*
        <div className={Styles.resp}>
          <GameDetailsPage />
        </div> */}
        {/* <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "800",
            fontSize: "18px",
            marginTop: "40px",
            textAlign: "justify",
          }}
        >
          महत्वपूर्ण सूचना
        </h4> */}
        {/* <div className={Styles.resp} style={{ paddingBottom: "15px" }}>
          <table class="table table-striped ">
            <thead>
              <tr class="table-primary">
                <th className={`${Styles.th}`} scope="col">
                  क्र.सं.{" "}
                </th>
                <th className={`${Styles.head}`} scope="col">
                  विषय
                </th>

                <th className={`${Styles.head}`} scope="col">
                  डाउनलोड
                </th>
              </tr>
            </thead>

            {data && data?.map((item, index) => (
              <tbody>
                <tr>
                  <td scope="row">{index + 1}</td>
                  <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                    {item?.name}
                  </td>
                  <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                    <a href={item?.url} target="_blank">
                      <img className={Styles.table_image} src={img} />
                    </a>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div> */}

        {/* <div className="container" style={{width:'50%'}}>
          <img src="img/38thMedalChart.jpeg" />
        </div> */}

      </div>

    </div>
  );
}
export default NationalGames;
