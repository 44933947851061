import React from 'react';

import { motion, isValidMotionProp } from 'framer-motion'
import { Container, Text, chakra, SimpleGrid, GridItem } from '@chakra-ui/react'

const ChakraBox = chakra(motion.div, {
    shouldForwardProp: isValidMotionProp,
})

const UpcommingEvents = () => {
    return (
        <>
            <SimpleGrid m={40} alignContent='center'   
>
            <GridItem>
                <ChakraBox
                    animate={{
                        scale: [.5, 1, 1, .5, .5],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                    }}
                    // @ts-ignore no problem in operation, although type error appears.
                    transition={{
                        duration: 3,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "loop",
                    }}
                    padding="2"
                    bgGradient="linear(to-l, #7928CA, #FF0080)"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100px"
                    height="100px"
                >

                </ChakraBox>
                </GridItem>


                <GridItem>
                <Text
                     fontWeight="bold"
                     textTransform="uppercase"
                     fontSize={["18", "42"]}
                     letterSpacing="wide"
                     color="teal"
                     px={[4, 10, 20]}

                >Comming soooooon</Text>
                </GridItem>
            </SimpleGrid>
        </>
    )
}

export default UpcommingEvents