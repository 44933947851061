import React from "react";
import Marquee from "react-fast-marquee";
import api from "../../utils/ApiMethod";
import { useState } from "react";
import { useEffect } from "react";
import styles from "../../styles/counter.module.css";

const FastMarquee = () => {
  const [sponsorsData, setSponsorsData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/sponsors");
      setSponsorsData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="container">
      <Marquee direction="right">
        {sponsorsData &&
          sponsorsData.map((data, index) => (
            <div
              key={data.id}
              style={{ margin: "10px 30px", cursor: "pointer" }}
              className={styles.sponsore_card}
            >
              <a href={data.url} target="_blank">
                <img
                  src={data.photo}
                  alt={data.title}
                  width="100px"
                  height="162px"
                />
              </a>
            </div>
          ))}
      </Marquee>
    </div>
  );
};

export default FastMarquee;
