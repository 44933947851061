import React from "react";
import api from "../../utils/ApiMethod";
import { useEffect } from "react";
import { useState } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import ProfileCard from "../../components/common/ProfileCard";
import { Container } from "@chakra-ui/react";
import pathConverter from "../../components/common/pathConverter";
import { Helmet } from "react-helmet";

const Introduction = () => {
  const [aboutUsPageData, setAboutUsPageData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/aboutus");
      setAboutUsPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let imgUrl, resultUrl;

  aboutUsPageData.map((data) => {
    imgUrl = data.bannerUrl.split("\\");
    imgUrl.shift();

    resultUrl = imgUrl.join("/");
    // console.log(resultUrl, "frcfcro");
  });

  // const converter = async () => {
  //   let url = await pathConverter(aboutUsPageData[0]?.bannerUrl);
  //   return url;
  // };

  useEffect(() => {
    handleFetchData();
  }, []);

  // console.log(aboutUsPageData, "dadadada");

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Bihar State Sports Authority</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण: खेल संघ, खेल प्रशिक्षक, इनडोर और आउटडोर खेल परिसर, खिलाड़ी प्रशिक्षण, अंडर 14, अंडर 16 और अंडर 19 एथलेटिक्स स्पर्धाओं के लिए समर्पित संस्था।"
        />
      </Helmet>
      {/* <Header /> */}
      {/* <h1>{`
          http://localhost:5000/${resultUrl}`}</h1> */}

      <Breadcrumb
        pageName="About Us"
        pageTitle="About Us"
        id="#about"
        // img_url={`http://localhost:5000/${resultUrl}`}
        // img_url={aboutUsPageData[0]?.bannerUrl}
        img_url="./img/Introduction01.jpg"
        mobile_banner="./img/Introduction-mobile.jpg"
      />

      {/* <h1>About Us</h1> */}
      <div style={{ width: "100%", margin: "20px auto" }} className="container">
        <ProfileCard
          heading={aboutUsPageData[0]?.heading}
          quotes={aboutUsPageData[0]?.paragraph}
        />
        {/* <h3>{aboutUsPageData[0]?.heading}</h3>
        <p>{aboutUsPageData[0]?.paragraph}</p> */}
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default Introduction;
