"use client";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function TopHeadlineCard({ card }) {
  const { title, text, image, url } = card;


  return (
    <Center>
      <a href={url} title="top-headline">
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          boxShadow="md"
          p="6"
          rounded="md"
          bg="#063a7a"
        >
          <Image
            //   objectFit="cover"
            maxW={{ base: "100%", sm: "350px" }}
            src={image}
            alt="headline"
            rounded="lg"
            style={{ borderRadius: "12px" }}
          />

          <Stack style={{ paddingBottom: "5px" }}>
            <CardBody>
              <Heading
                fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}
                color="white"
              >
                {title}
              </Heading>
              <Text py="2" fontSize={{ base: "xl", lg: "3xl" }} color="white">
                {text}
              </Text>
            </CardBody>

            {/* <CardFooter>
            <Button variant="solid" colorScheme="blue">
              Click me!
            </Button>
          </CardFooter> */}
          </Stack>
        </Card>
      </a>
    </Center>
  );
}
