import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import ComingSoon from "../../components/common/ComingSoon";

const SportsServices = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Sports Services"
        pageTitle="Sports Services"
        id="#Sports-Services"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <h1 style={{ margin: "10px auto" }}>खेल संबंधित सेवाएं</h1>
      <ComingSoon />
    </div>
  );
};
export default SportsServices;
