import React,{useState} from "react";
import styled from "../tenders/style.module.css";
import { useStatStyles } from "@chakra-ui/react";
import goldImg from '../../assets/images/gold_icon.png'
import silverImg from '../../assets/images/silver_icon.png'
import bronzeImg from '../../assets/images/bronze_icon.png'

const MedalTally = () => {

    return (
        <>
            <div className={` ${styled.medal} `} style={{  width: "100%" }}>
                <div style={{ background: "#08549c", borderRadius: "5px", paddingTop: "20px" }}>
                    <h4
                        style={{
                            color: "white",
                            fontWeight: "800",
                            fontSize: "18px",
                            // marginTop: "40px",
                            textAlign: "center",
                            marginBottom: "20px"
                        }}
                    >
                        37 वें नेशनल गेम्स 2023 में बिहार के खिलाड़ियों का प्रदर्शन
                    </h4>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr className={`table-primary`}>

                                <th className={`${styled.medalth}`}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>स्वर्ण पदक</h5>
                                        <img src={goldImg} style={{ width: '20%', marginLeft: ".6rem" }} />
                                    </div>
                                </th>

                                <th className={`${styled.medalth}`}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>रजत पदक</h5>
                                        <img src={silverImg} style={{ width: '20%', marginLeft: ".6rem" }} />
                                    </div>
                                </th>

                                <th className={`${styled.medalth}`}>
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>कांस्य पदक</h5>
                                        <img src={bronzeImg} style={{ width: '20%', marginLeft: ".6rem" }} />
                                    </div>
                                </th>

                                <th className={`${styled.issue}`}>
                                    {/* <h5 style={{topPadding:"10px"}}>कुलपदक</h5> */}
                                    <div className={`${styled.texttt}`}>
                                        <h5 style={{ fontWeight: "800", fontSize: "100%" }}>कुल पदक</h5>
                                        {/* <img src={goldImg} style={{ width: '20%', marginLeft: ".6rem" }} /> */}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={`${styled.medaltd}`}>00

                                </td>
                                <td className={`${styled.medaltd}`}>03</td>
                                <td className={`${styled.medaltd}`}>05</td>
                                <td className={`${styled.medaltd}`}>08</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="container" style={{width:"100%", textAlign:"justify",fontWeight:"800", background:"#00A3A3", padding:"1rem", borderRadius:"10px", marginLeft:"15px"}}>
                  <p><span  style={{color:"red", paddingRight:"10px"}}>*</span>तीरंदाजी व्यक्तिगत प्रतियोगिता के लिए बिहार के जय प्रकाश, निर्भय कुमार सिंह एवम् शिवम कुमार गुप्ता ने इंडियन राउंड में किया क्वालीफाई <span style={{color:"#00308F", paddingLeft:"10rem"}}>Last update</span></p>
            </div>
        </>
    )
}
export default MedalTally

