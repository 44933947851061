import React, { useEffect, useState } from "react";

import styled from "../tenders/style.module.css";

import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
import { Helmet } from "react-helmet";
const Letters = () => {
  const LettersData = [
    {
      id: 1,
      letterNo: "2682",
      name: "एकलव्य आवासीय प्रशिक्षण केन्द्र के चयन हेतु चयन ट्रायल में सभी जिलों के सभी सरकारी एवं गैर सरकारी स्कुलों के खिलाड़ियों की सहभागिता सम्बंधित",
      date: "26/07/2024",
      url: "img/tenders/Aklabya2682.PDF",
    },
  ];

  // const [data, setData] = useState([]);

  // const [search, setSearch] = useState("");

  // const handleFetchData = async () => {
  //   try {
  //     const data = await api.fetchData("/Advertisement");
  //     setData(data);
  //     // console.log("Fetched data:", data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   handleFetchData();
  // }, []);

  return (
    // <div>
    <div className={styled.Container}>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sports Advertisements | Bihar Sports News | Bihar State Sports
          Authority
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण के विज्ञापन: इस पेज पर विभिन्न खेल संबंधी विज्ञापन और घोषणाएं उपलब्ध हैं। यहां पर नवीनतम जानकारी और महत्वपूर्ण घोषणाओं को नियमित रूप से अद्यतन किया जाता है।"
        />
      </Helmet> */}
      <h1 className="pt-2" style={{ marginBottom: "10px" }}>
        पत्र
      </h1>
      {/* input field for search the item */}
      {/* <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for Advertisement"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div> */}
      <table class="table table-striped ">
        <thead>
          <tr class="table-primary" >
            <th className={`${Styles.th}`} scope="col" style={{ textAlign: "center" }}>
              क्र.सं
            </th>
            <th className={`${Styles.head}`} scope="col" style={{ textAlign: "center" }}>
              पत्र संख्या
            </th>
            <th className={`${Styles.head}`} scope="col" style={{ textAlign: "center" }}>
              {" "}
              विषय
            </th>
            <th className={`${Styles.head}`} scope="col" style={{ textAlign: "center" }}>
              तिथि
            </th>
            <th className={`${Styles.head}`} scope="col" style={{ textAlign: "center" }}>
              डाउनलोड
            </th>
          </tr>
        </thead>
        <tbody>
          {LettersData &&
            LettersData
              // .filter((item) =>
              //   item.name.toLowerCase().includes(search.toLowerCase())
              // )
              .map((item, i) => (
                <tr >
                  <th scope="row" style={{ textAlign: "center" }}>{i + 1}</th>
                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "center" }}
                  >
                    {item.letterNo}
                  </td>
                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "center" }}
                  >
                    {item.name}
                  </td>
                  <td
                    className={Styles.table_cell}
                    style={{ textAlign: "center" }}
                  >
                    {item.date}
                  </td>



                  <td
                    className={Styles.table_cell}

                  >
                    <a href={item.url} target="_blank" >
                      <center>
                        <img className={Styles.table_image} src={img} />
                      </center>
                    </a>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
    // </div>
  );
};

export default Letters;
