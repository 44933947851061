import React from "react";
import styled from "./style.module.css";

const RecruitmentNotices = () => {
  return (
    <>
      {" "}
      <div>
        <div className={styled.Container}>
          <h1 className="pt-2" style={{ marginBottom: "10px" }}>
            निविदाएं
          </h1>

          <table class="table table-striped">
            <thead>
              <tr class="table-primary">
                <th scope="col" className={styled.th}>
                  क्र.सं
                </th>
                <th scope="col">निविदा क्रमांक एवं विवरण</th>
                <th scope="col">डाउनलोड </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td className="text-left">Jacob</td>
                <td className="text-left">Thornton</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td className="text-left">Larry</td>
                <td className="text-left">the Bird</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default RecruitmentNotices;
