import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function SepakTakra() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong> 67वीं नेशनल स्कूल गेम्स, </strong>भारोत्तोलन चैंपियनशिप </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
            67वीं नेशनल स्कूल गेम्स, भारोत्तोलन चैंपियनशिप
            आयु वर्ग: अंडर-17 (बालक एवं बालिका)
          </p>
          {/* <p>

          </p> */}
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>

            चैंपियनशिप की तारीख: 12 फरवरी - 15 फरवरी 2024
            स्थान: पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना
            खिलाड़ियों के प्रवेश की अंतिम तिथि: 01 फरवरी 2024
            खिलाड़ियों के लिए रिपोर्टिंग की तारीख: 11 फरवरी 2024
          </p>
          <br />
          <p>नियंत्रण कक्ष का पता: पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना</p>
          <p>नियंत्रण कक्ष के प्रभारी का नाम : केशव कुमार</p>

          <p>मोबाइल नंबर: 9162915276</p>
          <br />
          <p>नोडल ऑफिसर का नाम: श्री जय नारायण कुमार, जिला खेल पदाधिकारी</p>
          <p>मोबाइल नंबर : 9661261352</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/weight_liftingsgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>भारोत्तोलन</p>
          <Button  variant="warning" style={{ margin: "20px", color: "black" }}>
            <a href="img/Weightlifting.PDF" target="_blank">
              विवरण देखें
            </a>
          </Button>
        </div>
      </div>
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          अधिक जानकारी के लिए क्लिक करें ↗
        </Button>
      </a>
    </>
  );
}

export default SepakTakra;
