import React from "react";

import stylesgfi from "./NationalSchoolGames.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
// import Button from "react-bootstrap/Button";
import { Button, Container, Row, Col } from "react-bootstrap";

// import Athlite from "./Athlite";

export default function BiharWomenKabaddiMatchSchedule() {
  return (
    <>
      <Breadcrumb
        pageName={BiharWomenKabaddiMatchSchedule}
        pageTitle={"BiharWomenKabaddiMatchSchedule"}
        img_url={"./img/WEBSITE BANNER_Destop.jpg"}
        mobile_banner="./img/WEBSITE BANNER_Mobile.jpg"
      />
      <div></div>
      <div>
        <h1 className={`${stylesgfi.title}`}>
          प्रथम बिहार वुमेन कबड्डी लीग 2024 मैच शेड्यूल
        </h1>
      </div>

      <div className={`container ${stylesgfi.game_sgfi}`}>
        <a
        // href="/athlet"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/biharWomenKabaddiMatchSchedule1.jpeg"
              alt=""
              style={{ height: "auto", width: "200px", padding: "5px" }}
            />
            {/* <p>सीतामढ़ी सेंटीनेल्स </p> */}
          </div>
        </a>

        <a
        // href="/cricket"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/biharWomenKabaddiMatchSchedule2.jpeg"
              alt=""
              style={{ height: "auto", width: "200px", padding: "5px" }}
            />
            {/* <p>मगध वरियर्स</p> */}
          </div>
        </a>

        <a
        //  href="/sepakTakra"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/biharWomenKabaddiMatchSchedule3.jpeg"
              alt=""
              style={{ height: "auto", width: "200px", padding: "5px" }}
            />
            {/* <p>सारण स्ट्राइकर्स</p> */}
          </div>
        </a>

        <a
        //  href="/weightlifting"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/biharWomenKabaddiMatchSchedule4.jpeg"
              alt=""
              style={{ height: "auto", width: "200px", padding: "5px" }}
            />
            {/* <p>पटना पेलिकन </p> */}
          </div>
        </a>

        <a
        //  href="/football"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/biharWomenKabaddiMatchSchedule5.jpeg"
              alt=""
              style={{ height: "auto", width: "200px", padding: "5px" }}
            />
            {/* <p> नालंदा निंजास</p> */}
          </div>
        </a>
        <a
        //  href="/football"
        >
          <div className={`${stylesgfi.game_card}`}>
            <a
              href="https://www.youtube.com/watch?v=QLs67Mnuayg"
              target="_blank"
            >
              <img
                src="./img/biharWomenKabaddiMatchSchedule6.jpeg"
                alt=""
                style={{ height: "auto", width: "200px", padding: "5px" }}
              />
            </a>
          </div>
        </a>
      </div>
      <a
      // href="https://sgfionline.net/reports/MedalTally.aspx?NsgNbr=66"
      // target="_blank"
      >
        {/* <Button
          variant="warning"
          style={{ marginBottom: "20px", color: "black" }}
        >
          मेडल तालिका ↗
        </Button> */}
      </a>
    </>
  );
}
