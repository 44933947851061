import React from 'react'
import Breadcrumb from "../../components/common/Breadcrumb";
import { Heading, Grid } from '@chakra-ui/react';
// import {  } from "@mui/material";
const Rajgirstadiuminauguration = () => {
    return (
        <div>
            <Breadcrumb
                pageName="Nation games 37"
                pageTitle="national games"
                id="#nationalGames"
                img_url="img/Desktop Page Banner.jpg"
                mobile_banner="img/Mobile Page Banner.jpg"
            />

            <div className={`container `}>
                <Heading pt={35} fontSize="24px">
                    राजगीर खेल विश्वविद्यालय एवं खेल अकादमी का उद्घाटन
                </Heading>
                <h4
                    className="section-heading"
                    style={{
                        color: "black",
                        fontWeight: "800",
                        fontSize: "18px",
                        marginTop: "20px",
                        textAlign: "justify",
                    }}
                >
                    29 अगस्त 2024
                </h4>

                <p className='text-justify'>
                    29 अगस्त 2024 को राष्ट्रीय खेल दिवस के अवसर पर माननीय मुख्यमंत्री श्री नीतीश कुमार द्वारा राजगीर खेल विश्वविद्यालय और खेल अकादमी का उद्घाटन किया गया। यह बिहार का पहला  और देश का छठा विश्वविद्यालय है। इस परिसर में हॉकी टर्फ, स्विमिंग पूल, फुटबॉल ग्राउंड, क्रिकेट स्टेडियम, और इनडोर स्टेडियम जैसी सुविधाएं बनाई गई हैं। इसके अलावा, परिसर में खिलाड़ियों के लिए 24 बेड वाला एक आधुनिक सुविधाओं से सुसज्जित अस्पताल भी बनाया गया है। उद्घाटन के अवसर पर एक प्रदर्शन मैच का भी आयोजन हुआ, जिसमें भारतीय महिला हॉकी टीम ने भाग लिया। यह देश का पहला ऐसा खेल गंतव्य होगा, जहाँ एक ही स्थान पर खेल परिसर और खेल विश्वविद्यालय दोनों होंगे।

                    <br />
                    राजगीर खेल परिसर में 24 तरह के इनडोर और आउटडोर खेलों की सुविधाएं उपलब्ध हैं। नई प्रतिभाओं को प्रोत्साहित करने के लिए, परिसर में कुश्ती, वेटलिफ्टिंग और एथलेटिक्स के लिए तीन उत्कृष्टता केंद्र बनाए गए हैं। इसके अलावा इसमें एक खेल पुस्तकालय भी होगी, जो नेशनल इंस्टीट्यूट ऑफ स्पोर्ट्स, पटियाला के बाद सबसे बड़ी होगी।
                </p>
                <Grid container spacing={2} style={{ display: "flex", gap: "10px", marginTop: "20px", marginBottom: "20px" }}>
                    <Grid item xs={3}>
                        <img
                            src="/img/Rajgir1.jpeg"
                            alt="Image 1"
                        // style={{ width: "auto", height: "50%" }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <img
                            src="/img/rajgir3.jpg"
                            alt="Image 3"
                        // style={{ width: "auto", height: "50%" }}
                        />
                    </Grid>


                </Grid>
                <Grid item xs={4}>
                    <img
                        src="/img/Rajgir2.jpeg"
                        alt="Image 2"
                    style={{ width: "auto", height: "50%" }}
                    />
                </Grid>
            </div>
        </div>
    )
}

export default Rajgirstadiuminauguration
