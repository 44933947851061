import { SimpleGrid, Square, Text, Box, Center, Container } from '@chakra-ui/react';
import React from 'react';

const Inspiration = () => {
    return (
        <>



            <div style={{ backgroundImage: 'linear-gradient(to bottom, #0a3272, white)' }}>


                <Text
                    // fontWeight="bold"
                    textTransform="uppercase"
                    p={'4px'}
                    pb={'12px'}
                    // align={"right"}
                    fontSize={["30", "33"]}
                    letterSpacing="wide"
                    color="black"
                    pt={[2, 5, 10]}
                    px={[4, 10, 20]}
                    fontWeight='extrabold'
                >
                    खेल संबंधी शैक्षणिक छात्रवृत्ती
                </Text>

                <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
                    <Center as={"text"} h='100px' textAlign={'left'} p='3%'
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize={["8", "16"]}
                        letterSpacing="wide"
                        color="teal.800"
                    >
                        राज्य के खिलाड़ियों में खेल कला के साथ खेल संबंधित शैक्षणिक ज्ञान के कौशल के विकास हेतु बिहार राज्य खेल प्राधिकरण और बिहार सरकार
                        इस योजना पर विचार कर रही है। इस योजना का उद्देश्य राज्य के खिलाड़ियों में खेल संबंधित तकनीक और खेल के प्रति ज्ञान में बढ़ोतरी करने के
                        लिए उन्हें आर्थिक सहायता प्रदान करना है। जिससे हमारे राज्य के बच्चे विश्व भर में भारत का नाम गौरवान्वित कर सके तथा पूरे देश में कुशल
                        और सक्षम बिहार का प्रतिनिधित्व कर सकें।    </Center>

                </SimpleGrid>
            </div>

        </>
    )

}
export default Inspiration