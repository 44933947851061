import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import api from "../utils/ApiMethod";

const Footer = () => {
  const [data, setData] = useState([]);

  const handleFetchData = async () => {
    try {
      const response = await fetch("https://api.biharsports.org/api/visitor");
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <section class="footer-top-bg" style={{ backgroundColor: "#0c2545;" }}>
        <div class="container">
          <div class="row">
            <div
              class="col-lg-5 col-md-5 col-sm-4 col-xs-12 addrs screenReader"
              tabindex="87"
            >
              <motion.h4
                class="screenReader"
                tabindex="88"
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                लिंक
              </motion.h4>

              <motion.ul
                class="fotr-lnk"
                initial={{
                  translateX: "-10vh",
                }}
                whileInView={{ translateX: "0" }}
                // // animate={{opacity:1, translateX:0}}
                transition={{ duration: 0.5 }}
              >
                <motion.li
                  class="screenReader"
                  tabindex="89"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    // translateX: "10px",
                  }}
                >
                  <a href="/">होम</a>
                </motion.li>

                <motion.li
                  class="screenReader"
                  tabindex="90"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "10px",
                  }}
                >
                  <a href="/policy">हमारी नीतियां</a>
                </motion.li>

                <motion.li
                  class="screenReader"
                  tabindex="91"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "10px",
                  }}
                >
                  <a href="/tenders" target="_blank" rel="noreferrer">
                    निविदाएं
                  </a>
                </motion.li>

                <motion.li
                  class="screenReader"
                  tabindex="94"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "10px",
                  }}
                >
                  <a href="/contactus">संपर्क करें</a>
                </motion.li>
              </motion.ul>

              <motion.ul
                class="fotr-lnk"
                initial={{
                  translateX: "10vh",
                }}
                whileInView={{ translateX: "0" }}
                // // animate={{opacity:1, translateX:0}}
                transition={{ duration: 0.5 }}
              >
                <motion.li
                  className="screenReader"
                  tabindex="92"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "-10px",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    fontSize: "bold",
                  }}
                >
                  <a href="/contactus">प्रतिक्रिया</a>
                </motion.li>

                <motion.li
                  tabindex="93"
                  className="screenReader"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    paddingLeft: "2%",
                    translateX: "-10px",
                    paddingRight: "2%",
                  }}
                >
                  <a href="/sportsfederation">खेल संघ</a>{" "}
                </motion.li>

                <motion.li
                  class="screenReader"
                  tabindex="95"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "-10px",
                  }}
                >
                  <a
                    href="https://rti.gov.in/"
                    title="RTI"
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      const confirmResult = window.confirm(
                        "आपको किसी बाहरी वेबसाइट पर पुनर्निर्देशित किया जा रहा है. कृपया ध्यान दें कि बिहार राज्य खेल प्राधिकरण का वेबसाइट बाहरी वेबसाइटों की सामग्री और गोपनीयता नीतियों के लिए ज़िम्मेदार नहीं है।"
                      );
                      if (confirmResult) {
                        window.open("https://rti.gov.in/", "_blank");
                      }
                    }}
                  >
                    आर. टी. आई
                  </a>
                </motion.li>

                <motion.li
                  class="screenReader"
                  tabindex="96"
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    translateX: "-10px",
                  }}
                >
                  <a href="/disclaimer" title="disclaimer">
                    डिस्क्लेमर
                  </a>{" "}
                </motion.li>
              </motion.ul>

              <motion.ul
                class="fotr-lnk"
                initial={{
                  translateX: "10vh",
                }}
                whileInView={{ translateX: "0" }}
                // // animate={{opacity:1, translateX:0}}
                transition={{ duration: 0.5 }}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "6%",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                }}
              >
                <li class="screenReader" tabindex="">
                  <p>
                    आगंतुकों की संख्या :{" "}
                    <strong className="text-warning count font">
                      {data?.totalVisitor ? data.totalVisitor : ""}
                    </strong>
                  </p>
                </li>
              </motion.ul>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 ">
              <h4 class="screenReader" tabindex="98">
                पता
              </h4>

              <ul class="fotr-lnk">
                <li class="screenReader" tabindex="99">
                  <strong>बिहार राज्य खेल प्राधिकरण </strong>{" "}
                </li>
                <li class="screenReader" tabindex="100">
                  पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना
                  <br />
                  पिन कोड-800020
                  {/* <span class="addrs-span">TEL : +91-674-2390268</span> */}
                </li>

                <li class="screenReader" tabindex="101">
                  <motion.li style={{ color: "#f05623;" }}>
                    हमसे जुड़ें :
                  </motion.li>
                  <ul class="social-network social-circle ">
                    <li class="screenReader" tabindex="102">
                      <a
                        href="https://www.facebook.com/BSSABihar"
                        target="_blank"
                        rel="noreferrer"
                        class="screenReader icoFacebook"
                        title="Facebook"
                        tabindex="103"
                      >
                        <svg viewBox="0 0 24 24">
                          <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
                        </svg>
                      </a>
                    </li>

                    <li class="screenReader" tabindex="104">
                      <a
                        href="https://twitter.com/BSSABihar/"
                        target="_blank"
                        rel="noreferrer"
                        class="screenReader icoTwitter hover-rotate"
                        title="Twitter"
                        tabindex="105"
                        style={{ backgroundColor: "#010101" }}
                      >
                        <img
                          src="./img/twitter-new-icon-2.svg"
                          width="15"
                          height="15"
                          class="media-icon"
                          style={{ backgroundColor: "#010101" }}
                        />

                        {/* twitter-new-icon-2.svg */}

                        {/* <svg viewBox="-21 -81 681.33464 681">
                          <path d="m200.964844 515.292969c241.050781 0 372.871094-199.703125 372.871094-372.871094 0-5.671875-.117188-11.320313-.371094-16.9375 25.585937-18.5 47.824218-41.585937 65.371094-67.863281-23.480469 10.441406-48.753907 17.460937-75.257813 20.636718 27.054687-16.230468 47.828125-41.894531 57.625-72.488281-25.320313 15.011719-53.363281 25.917969-83.214844 31.808594-23.914062-25.472656-57.964843-41.402344-95.664062-41.402344-72.367188 0-131.058594 58.6875-131.058594 131.03125 0 10.289063 1.152344 20.289063 3.398437 29.882813-108.917968-5.480469-205.503906-57.625-270.132812-136.921875-11.25 19.363281-17.742188 41.863281-17.742188 65.871093 0 45.460938 23.136719 85.605469 58.316407 109.082032-21.5-.660156-41.695313-6.5625-59.351563-16.386719-.019531.550781-.019531 1.085937-.019531 1.671875 0 63.46875 45.171875 116.460938 105.144531 128.46875-11.015625 2.996094-22.605468 4.609375-34.558594 4.609375-8.429687 0-16.648437-.828125-24.632812-2.363281 16.683594 52.070312 65.066406 89.960937 122.425781 91.023437-44.855469 35.15625-101.359375 56.097657-162.769531 56.097657-10.5625 0-21.003906-.605469-31.2617188-1.816407 57.9999998 37.175781 126.8710938 58.871094 200.8867188 58.871094" />
                        </svg> */}

                        {/* <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="866.000000pt"
                          height="650.000000pt"
                          viewBox="0 0 866.000000 650.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,650.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M1907 5528 c5 -7 232 -310 503 -673 271 -363 689 -922 929 -1243 240
-320 437 -586 439 -590 3 -9 69 63 -1278 -1392 -69 -74 -234 -253 -368 -397
-133 -144 -242 -264 -242 -267 0 -3 94 -6 209 -6 l210 0 252 272 c685 740
1348 1456 1379 1491 l35 39 674 -901 673 -901 725 0 c464 0 723 3 721 10 -1 5
-154 211 -338 457 -184 246 -630 843 -992 1326 l-656 879 881 951 881 952
-213 3 c-118 1 -215 1 -216 0 -1 -3 -255 -277 -1384 -1498 -74 -80 -137 -146
-140 -147 -3 -1 -53 62 -111 141 -58 78 -334 449 -615 824 l-510 682 -729 0
c-581 0 -727 -3 -719 -12z m1985 -1235 c1591 -2127 2230 -2982 2242 -3001 6
-9 -62 -12 -326 -12 l-333 1 -225 302 c-242 325 -1018 1364 -2067 2765 -359
480 -653 875 -653 877 0 3 149 5 330 5 l331 0 701 -937z"
                            />
                          </g>
                        </svg> */}
                      </a>
                    </li>

                    <li class="screenReader" tabindex="104">
                      <a
                        href="https://www.linkedin.com/company/sdabihar/"
                        target="_blank"
                        rel="noreferrer"
                        class="screenReader icoLinkedIn hover-rotate"
                        title="LinkedIn"
                        tabindex="105"
                      >
                        <img
                          src="./img/linkedin svg.svg"
                          width="15"
                          height="15"
                          class="media-icon"
                        />
                      </a>
                    </li>

                    <li class="screenReader" tabindex="104">
                      <a
                        href="https://youtube.com/@bssaa612"
                        target="_blank"
                        rel="noreferrer"
                        class="screenReader icoyoutube hover-rotate"
                        title="YouTube"
                        tabindex="105"
                      >
                        <img
                          src="./img/youtube svg.svg"
                          width="15"
                          height="15"
                          class="media-icon"
                        />
                      </a>
                    </li>

                    <li class="screenReader" tabindex="104">
                      <a
                        href="https://goo.gl/maps/UxrLvDy1is1kFiaC9"
                        target="_blank"
                        rel="noreferrer"
                        class="screenReader icoGoogleMap hover-rotate"
                        title="GBP"
                        tabindex="105"
                      >
                        <img
                          src="./img/Google svg.svg"
                          width="15"
                          height="15"
                          class="media-icon"
                        />
                      </a>
                    </li>

                    <li class="screenReader" tabindex="104">
                      <a
                        href="https://www.instagram.com/bssa.bihar/"
                        target="_blank"
                        rel="noreferrer"
                        class="screenReader icoinstagram"
                        title="Instagram"
                        tabindex="105"
                      >
                        <svg viewBox="0 0 512.00096 512.00096">
                          <path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm-117.40625 395.996094c-77.195312 0-139.996094-62.800782-139.996094-139.996094s62.800782-139.996094 139.996094-139.996094 139.996094 62.800782 139.996094 139.996094-62.800782 139.996094-139.996094 139.996094zm143.34375-246.976563c-22.8125 0-41.367188-18.554687-41.367188-41.367187s18.554688-41.371094 41.367188-41.371094 41.371094 18.558594 41.371094 41.371094-18.558594 41.367187-41.371094 41.367187zm0 0" />
                          <path d="m256 146.019531c-60.640625 0-109.980469 49.335938-109.980469 109.980469 0 60.640625 49.339844 109.980469 109.980469 109.980469 60.644531 0 109.980469-49.339844 109.980469-109.980469 0-60.644531-49.335938-109.980469-109.980469-109.980469zm0 0" />
                          <path d="m399.34375 96.300781c-6.257812 0-11.351562 5.09375-11.351562 11.351563 0 6.257812 5.09375 11.351562 11.351562 11.351562 6.261719 0 11.355469-5.089844 11.355469-11.351562 0-6.261719-5.09375-11.351563-11.355469-11.351563zm0 0" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div
              class="col-lg-3 col-md-3 col-sm-3 col-xs-6 screenReader"
              tabindex="106"
            >
              <motion.h4 class="important-link">महत्वपूर्ण लिंक</motion.h4>
              <motion.ul
                class="impntLINK"
                whileInView={{ translateX: "0" }}
                // // animate={{opacity:1, translateX:0}}
                transition={{ duration: 0.5 }}
              >
                <motion.li
                  class="screenReader"
                  tabindex="107"
                  whileHover={{
                    translateX: "-20px",
                  }}
                >
                  <a
                    href="https://yas.nic.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="img/logo-1.png" alt="Youth Affairs" />
                  </a>
                </motion.li>
                <motion.li
                  class="screenReader"
                  tabindex="108"
                  whileHover={{
                    translateX: "-30px",
                  }}
                >
                  <a
                    href="https://www.india.gov.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="img/logo_1.png" alt="India govt." />
                  </a>
                </motion.li>
                <motion.li
                  class="screenReader"
                  tabindex="109"
                  whileHover={{
                    translateX: "-40px",
                  }}
                >
                  <a
                    href="https://sportsauthorityofindia.nic.in/sai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="img/logo.png" alt="Sports Authrity" />
                  </a>
                </motion.li>
              </motion.ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Footer;
