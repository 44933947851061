import React from "react";
import ComingSoon from "../../components/common/ComingSoon";
import Breadcrumb from "../../components/common/Breadcrumb";

const SportsFederation = () => {
  return (
    <div>
      {" "}
      <Breadcrumb
        pageName="Directory"
        pageTitle="Directory"
        id="#directory"
        img_url="./img/Message-banner-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h2> खेल संघ</h2>

        <ComingSoon />
      </div>
    </div>
  );
};

export default SportsFederation;
