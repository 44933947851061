import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const SportsDevelopmentPolicy = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Sports development policy"
        pageTitle="Sports development policy"
        id="#Sports development policy"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1>खेल विकास नीति </h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  खेल विकास नीति
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              बिहार सरकार द्वारा समय समय पर खिलाड़ियों के लिए विकास संबंधित
              नीतियों का निर्माण किया जाता रहा है। सरकार द्वारा निर्मित इन विकास
              नीतियों का लक्ष्य खिलाड़ियों में खेल की क्षमता को बढ़ाना तथा खेल
              के लिए राज्य के खिलाड़ियों को एक बेहतर मंच प्रदान करना है। सरकार
              द्वारा निर्मित खेल संबंधी विकास नीतियों के क्रियान्वयन को
              सुनिश्चित करने की जिम्मेदारी राज्य सरकार द्वारा बिहार राज्य खेल
              प्राधिकरण को प्रदान किया गया है। अपनी स्थापना के उद्देश्यों एवम्
              लक्ष्यों की पूर्ति के लिए बिहार राज्य खेल प्राधिकरण प्रतिपल
              प्रयासरत है।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default SportsDevelopmentPolicy;
