import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import {
  Card,
  Grid,
  Box,
  extendTheme,
  CardHeader,
  Heading,
  CardBody,
  Center,
  Image,
  Text,
  Stack,
  CardFooter,
  Button,
  ButtonGroup,
  Divider,
  useEditable,
  SimpleGrid,
} from "@chakra-ui/react";

import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const InternationalAchievement = () => {
  const [data, setData] = useState([]);

  const radialGradient = "radial-gradient(circle, #ff9900, #ff6600)";
  const radialGradient2 = "radial-gradient(circle, #ffefba, #ffffff);";

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/internationalAchievement");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const theme = extendTheme({
    fonts: {
      heading: `'Open Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          International Sports Achievements | Bihar State Sports Authority |
          BSSA
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की अंतर्राष्ट्रीय उपलब्धियां: देखिये राज्य के खिलाड़ियों की सूची जिन्होंने विभिन्न अंतर्राष्ट्रीय प्रतियोगिताओं में उत्कृष्ट प्रदर्शन कर राज्य और देश का गौरव बढ़ाया है।"
        />
      </Helmet>
      <Breadcrumb
        pageName="International Achievements"
        pageTitle="International Achievements"
        id="#International-Achievements"
        img_url="./img/backdropImg/international achievement.jpg"
        mobile_banner="./img/backdropImg/international achievement mobile view 576 x 600.jpg"
      />
      <Heading background={radialGradient2} p={5} fontSize="25px">
        अंतर्राष्ट्रीय उपलब्धियां
      </Heading>
      {/* ------------------------------------horizontal Line---------------------------------------------------- */}
      <div
        style={{
          height: "4px",
          width: "100%",
          backgroundColor: " rgb(8, 44, 44)",
          paddingBottom: "3px",
        }}
      ></div>
      {/* ------------------------------------------------------------------------------------------------------- */}

      <Center background={radialGradient2} position="relative">
        <Grid
          templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr"]}
          gap={"4"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {data.map((item, index) => (
            <Box key={index} maxW="xl" p="2%">
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  translateX: index % 2 === 0 ? -50 : 50,
                  translateY: -50,
                }}
                whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                transition={{ duration: 0.3, delay: index * 0.2 }}
              >
                <Image
                  src={item.image}
                  alt="img"
                  paddingLeft={"6.5rem"}
                  borderRadius="1rem"
                  height="80%"
                  width="80%"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">{item.name}</Heading>
                  <Text>{item.achievement}</Text>
                </Stack>
              </motion.div>
            </Box>
          ))}
        </Grid>
      </Center>
    </div>
  );
};

export default InternationalAchievement;
