import React, { useEffect } from "react";
import Swiper from "swiper";
// import "swiper/css/swiper.min.css"; // Import Swiper styles
import { delay, motion, useAnimation } from "framer-motion";
import Shreyeasi_Singh from "../assets/images/Shreyashi_Singh-new.jpeg";

import Styles from "../styles/BasicStructureTwo.module.css";
import Gift from "./Gift";

const BasicStructureTwo = () => {
  useEffect(() => {
    var swiper = new Swiper(".whatsNewCarousel .swiper-container", {
      slidesPerView: 3,
      slidesPerColumn: 2,
      grid: {
        rows: 4,
      },
      spaceBetween: 30,
      grabCursor: true,
      navigation: {
        nextEl: ".award__arrow .swiper-button-next",
        prevEl: ".award__arrow .swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 10,
          pagination: {
            el: "#AwardPagination",
            dynamicBullets: true,
            clickable: true,
          },
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1900: {
          slidesPerView: 3,
          grid: {
            rows: 2,
          },
          spaceBetween: 20,
        },
      },
    });
  }, []);

  return (
    // <div>
    <div className="container">
      <div className="row">
        <motion.div
          className="col-sm-12 col-md-4 col-lg-4 news-bg"
          initial={{
            translateX: "-50vh",
          }}
          whileInView={{ translateX: "0" }}
          // animate={{opacity:1, translateX:0}}
          transition={{ duration: 0.5 }}
        >
          <h4 className="section-heading" style={{ color: "#fff" }}>
            आधारभूत संरचना
          </h4>
          <div className="infrastructure-Carousel ">
            <div
              id="infrastructure-carousel"
              className="carousel slide img-animate  infra-carousal-slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div
                  className="item active"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className={Styles.video_container}>
                    <video
                      autoPlay
                      muted
                      loop
                      // style={{ width: "500px", height: "200px" }}
                    >
                      <source
                        src="img/patliputra khel parisar2.mp4"
                        type="video/mp4"
                      />
                    </video>

                    <div className={Styles.overlay_content}>
                      <div className={Styles.carousel_content}>
                        <h3 className={Styles.carousel_title}>
                          पाटलिपुत्र खेल परिसर
                        </h3>
                        <span className="display-block carousel-date">
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>{" "}
                          &nbsp; पटना
                        </span>
                        <p
                          className={Styles.carousel_text}
                          style={{ textAlign: "justify" }}
                        >
                          कंकड़बाग, पटना में स्थित पाटलिपुत्र खेल प्ररिसर एक
                          बहुउद्देश्यीय स्टेडियम है । इस खेल परिसर का उद्घाटन 1
                          मार्च 2012 को भारत में पहली महिला विश्व कप कबड्डी
                          चैम्पियनशिप के उद्घाटन के साथ किया गया था । स्पोर्ट
                          कॉम्प्लेक्स एक अत्याधुनिक कॉम्प्लेक्स है, जिसमें इनडोर
                          और आउटडोर दोनों खेलों की सुविधाएं हैं। यह 16 एकड़ में
                          फैला हुआ है।
                        </p>
                      </div>
                      <a
                        href="/infrastructure"
                        className="btn btn-default button-link"
                      >
                        अधिक जानकारी के लिए{" "}
                        <i className="bi bi-arrow-up-right-circle"></i>
                      </a>
                    </div>
                  </div>
                </div>

                {/* <!-- <div className="item">
                    <img src="img/BirsaMundaAthleticStadiumComplex.jpg" alt="Birsa Munda Athletic Stadium Complex" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Birsa Munda Athletic Stadium Complex</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Rourkela</span>
                      <p className="carousel-text">
                        It features an athletics stadium with 400 m synthetic athletic track and a natural
                        turf football field with floodlights. The stadium has four galleries with a seating
                        capacity of more than 9000 spectators, including seating for differently abled
                        people.
                        It also houses an Olympic-sized indoor swimming pool along with players’ changing
                        rooms and allied facilities.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div> -->


                  <!--      <div className="item">
                    <img src="img/BirsaMundaHockeyStadium.jpg" alt="Birsa-Munda-Hockey-Stadium" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Birsa Munda Hockey Stadium</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Rourkela</span>
                      <p className="carousel-text">
                        It is the largest seated hockey stadium officially recognized by the Guinness World
                        Records as the largest fully seated hockey arena in the world. The picturesque
                        stadium, built in 15 months, also boasts two world-className FIH approved turfs for
                        hockey,
                        apart from gymnasiums and World Cup Village.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/DharaniDharSports.jpg" alt="Dharani-Dhar-Sports-Complex" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Dharani Dhar Sports Complex</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Keonjhar</span>
                      <p className="carousel-text">
                        It features a natural turf football field, 400 m synthetic track with LED
                        floodlights as well as a 1000 seating capacity gallery. Additionally, an archery
                        arena has been developed. It has an indoor hall with six badminton courts and is
                        complemented with
                        a 200-bedded fully furnished residential facilities for athletes and staff.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/BijuPatnaikstadium.jpeg" alt="biju-patnaik-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Biju Patnaik Indoor Stadium</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Rourkela</span>
                      <p className="carousel-text">
                        The Biju Patnaik Indoor Stadium in Rourkela has 3 indoor badminton court as per
                        World Badminton Federation standard and a 350 spectator seating capacity, making it
                        a perfect facility for shuttlers of the region a and good venue for competitions.The
                        stadium
                        also has a multipurpose outdoor court for Volleyball and Tennis.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/BijuPatnaikWeightliftingHall.jpeg" alt="weightlifting-hall-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Biju Patnaik Weightlifting Hall</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Bhubaneswar</span>
                      <p className="carousel-text">
                        The Biju Patnaik Weightlifting Hall within the Kalinga Stadium premises has 13
                        platforms, (12 training and 1 competition) and is equipped with latest equipment.
                        Led by a team of expert coaches and professionals, it is a training hub for the
                        young and
                        promising lifters of the state. It has recently hosted the State Weightlifting
                        Championship.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/infra-stadium.jpg" alt="kalinga-stadium-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Kalinga Stadium</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Bhubaneswar</span>
                      <p className="carousel-text">
                        Built in 1978, Kalinga Stadium is a multipurpose stadium in the smart city of
                        Bhubaneswar, Odisha. Situated in the heart of the capital, the stadium has
                        facilities for Athletics, Football, Hockey, Basketball, Tennis, Table Tennis,
                        Badminton Volleyball,
                        Wall Climbing and Swimming.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/infrakalingaHockey.jpg" alt="Hinjlicut-Indoor-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Kalinga Hockey Stadium </h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Bhubaneswar</span>
                      <p className="carousel-text">
                        Located inside the Kalinga Stadium premises, the blue turf has played the host to
                        plethora of tournaments in the recent past starting from the Odisha Hockey Men’s
                        World Cup in 2018 to FIH Men’s Pro League in 2020. It was also the home ground of
                        the Bhubaneswar-based
                        HIL team Kalinga Lancers.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div> -->
                  <!-- <div className="item">
                              <img src="img/KalingaAthletics-infra.jpg" alt="Hinjlicut-Indoor-image" />
                              <div className="carousel-content">
                                 <h3 className="carousel-title">Kalinga Athletics & Football Stadium </h3>
                                 <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i> Bhubaneswar</span>
                                 <p className="carousel-text">
                                 The stadium has international standard facility for Athletics and Football training and sporting events.  It is a proud host of many international and national athletics events including 22nd Asian Athletic Championship in 2017, Khelo India University Games 2021, National Open Athletics Championships and Hero ISL to name a few.
                                 </p>
                              </div>
                                 <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                           </div> -->
                  <!-- <div className="item">
                              <img src="img/infra-shooting.jpg" alt="infra-shooting-image" />
                              <div className="carousel-content">
                                 <h3 className="carousel-title">Odisha Aditya Birla Gagan Narang Shooting High Performance Centre</h3>
                                 <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i> Bhubaneswar</span>
                                 <p className="carousel-text">
                                To give shooting a major boost in Odisha, a state of the art facility has been set up by Sports & Youth Services Department  in partnership with  Gagan Narang Sports Foundation and Aditya Birla Group  within the premises of Kalinga Stadium. It consists of a 22 lane 10-meter range that can cater to 60 athletes each day for training.
                                 </p>
                              </div>
                                 <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                           </div> --> */}

                {/* <!-- <div className="item">
                    <img src="img/infra-jnis.jpg" alt="Hinjlicut-Indoor-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Jawaharlal Nehru Indoor Stadium </h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Cuttack</span>
                      <p className="carousel-text">
                        One of the biggest indoor stadiums of Eastern India, JNIS was the host for the
                        Commonwealth Table Tennis Championship in 2019, which got Odisha worldwide
                        recognition and appreciation. It was also one of the venues for the first-ever Khelo
                        India University
                        Games in 2020.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item ">
                    <img src="img/SportsClimbing.jpg" alt="SportsClimbing-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Sports Climbing</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Bhubaneswar</span>
                      <p className="carousel-text">
                        Odisha now has one of the best Sports Climbing facilities in the country. This world
                        className sports infrastructure at Kalinga Stadium offers an opportunity for
                        international level climbing. All safety precautions and measures are taken by
                        expert coaches
                        to impart training to the budding climbers.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item ">
                    <img src="img/swimming-pool.jpg" alt="sambalpur-swimming-pool-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Swimming Pool at Sambalpur</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Sambalpur</span>
                      <p className="carousel-text">
                        Dr Jhasketan Sahu Swimming Pool Complex in the city of Sambalpur has been developed
                        to encourage swimming as a sport among the youth of Western Odisha. The swimming
                        pool is one of the best in the district and is open for all who want to learn
                        swimming
                        by paying the requisite fee. Trained coaches have been engaged to assist beginners.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/Chatrapur-Indoor.jpg" alt="Hinjlicut-Indoor-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">पाटलिपुत्र खेल परिसर</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Ganjam</span>
                      <p className="carousel-text">
                        The Indoor Stadium, Hinjilicut, is now among the best indoor sports facility in the
                        State. With 4 badminton courts and spaces for table tennis, yoga, gym,
                        physiotherapy, board games etc. the facility will help in providing quality support
                        to the budding
                        sports persons in the area.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/Hinjlicut-Indoor.jpg" alt="Chatrapur-Indoor-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">Indoor Hall Chatrapur</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        Ganjam</span>
                      <p className="carousel-text">
                        The Chatrapur Indoor stadium has 3 badminton courts with a table tennis room & a
                        swimming pool. With state of the art facilities inside this new indoor stadium,
                        Chatrapur is all set to become a major sports center for the sports enthusiasts.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div>
                  <div className="item">
                    <img src="img/State-Swimming.jpg" alt="State-Swimming-image" />
                    <div className="carousel-content">
                      <h3 className="carousel-title">पाटलिपुत्र खेल परिसर</h3>
                      <span className="display-block carousel-date"> <i className="fa fa-map-marker" aria-hidden="true"></i>
                        पटना</span>
                      <p className="carousel-text">
                        Located inside the Kalinga Stadium, the refurbished all-weather, top-of-the-line
                        Biju Patnaik Swimming Pool has been dedicated to the swimmers of the state. It has
                        recently hosted the 61st State Swimming Championship and will host national and
                        global
                        events in future.
                      </p>
                    </div>
                    <a href="Sports-Infrastructure" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                  </div> --> */}

                {/* <!-- <a className="left carousel-control" href="#infrastructure-carousel" data-slide="prev">
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="right carousel-control" href="#infrastructure-carousel" data-slide="next">
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    <span className="sr-only">Next</span>
                  </a>
                 -->
                  <!-- <div style="border:2px solid red">

</div> --> */}
              </div>
            </div>
          </div>
        </motion.div>

        {/*-------------------------------------- sheryasi ------------------------- */}

        <div className="col-sm-4 col-md-4 col-lg-4">
          <div>
            <h4
              className="section-heading d-inline-block mobile-heading"
              style={{ color: "#fff" }}
            >
              बिहार की शान!
            </h4>
          </div>

          <div className="whatsNewCarousel__card">
            <div className="award__img" style={{width:"100%" ,height: "100%",}}>
              <img src={Shreyeasi_Singh} alt="Shreyeasi_Singh" />
            </div>
            <div className="whatsNewCarousel__text" style={{ padding: "12%" }}>
              <h4> श्रीमती श्रेयसी सिंह </h4>
              <h5>अर्जुन पुरस्कार से सम्मानित</h5>
              <h5>शूटिंग</h5>
            </div>
          </div>

          {/* <div className="h-56px">
          <img src={Shreyeasi_Singh} alt="Shreyeasi_Singh" />
          </div> */}
        </div>

        {/* --------------------------------- gifts------------------------------- */}

        <div className="col-sm-4 col-md-4 col-lg-4">
          <div>
            <h4
              className="section-heading d-inline-block mobile-heading"
              style={{ color: "#fff" }}
            >
              बिहार के उभरते सितारे।
              {/* <!-- <span className="award--title">(Arjuna Award)</span> --> */}
            </h4>

            {/*
              <!-- <Select id="awardselector" className="award_check">
                <option value="Arjuna">स्वर्ण पदक</option>
                <option value="Ratna"> रजत पदक</option>
                <option value="Arjuna">कांस्य पदक</option>
                <option value="Ratna"> खेल रत्न पुरस्कार</option>
              </Select> --> */}
          </div>
          <div className="h-56px">
            <Gift />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default BasicStructureTwo;
