import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const HostelGroundBooking = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Hostel and stadium service"
        pageTitle="Hostel and stadium service"
        id="#Sports-recruitment-Policy"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1>हॉस्टल,स्टेडियमऔर खेल मैदान बुक करने की सुविधा</h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  हॉस्टल बुकिंग की सुविधा
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              पाटलिपुत्र खेल परिसर ना सिर्फ खेल में खिलाड़ियों की प्रतिभा को
              बढ़ाने के लिए उन्हें एक बेहतर मैदान प्रदान कर रहा है बल्कि
              खिलाड़ियों के रहने का भी यहां विशेष इंतजाम किया गया है। खेल परिसर
              के अंदर कुल 3 हॉस्टल हैं। प्रत्येक हॉस्टल की इमारत अलग है। महिला
              एवम् पुरुष खिलाड़ियों के लिए अलग-अलग हॉस्टल का निर्माण किया गया
              है। जिससे खिलाड़ियों को किसी प्रकार की कोई परेशानी ना हो। इसके साथ
              ही यहां खेल प्रशिक्षकों के ठहरने का भी सुव्यवस्थित इंतजाम है।
              हॉस्टल की बुकिंग के लिए आपको कार्यालय में हॉस्टल बुकिंग संबंधित एक
              आवेदन पत्र जमा करना होगा।
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  खेल मैदान बुक करने की सुविधा
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              बिहार राज्य खेल प्राधिकरण ना सिर्फ खेल और खिलाड़ियों के विकास में
              अपना महत्वपूर्ण भूमिका निभा रहा है बल्कि राज्य में खेलों के
              आयोजनों के सफल संचालन में भी अपना पूरा सहयोग प्रदान कर रहा है।
              बिहार की राजधानी पटना के कंकड़बाग में स्थित पाटलिपुत्र खेल परिसर
              में इंडोर और आउटडोर दोनो ही स्टेडियमों को आसानी से बुक किया जा
              सकता है। स्टेडियम को बुक करने के लिए आपको एक आवेदन लिखकर बिहार
              राज्य खेल प्राधिकरण के कार्यालय में जमा करना होगा। स्टेडियमों को
              आरक्षित करने से संबंधित अधिक जानकारी हेतु नीचे दिए गए पीडीएफ को
              डाउनलोड करें।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default HostelGroundBooking;
