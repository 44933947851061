import React, { useEffect, useState } from "react";
import "./Indivisiual.css";
import { SimpleGrid } from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import { delay, motion, useAnimation } from "framer-motion";

import { Box, Text, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const SportsMinister = () => {
  const [jitendraPageData, setJitendraPageData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/jitender");
      setJitendraPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Shri Surendra Mehta | Sports Minister Of Bihar- Bihar State Sports
          Authority
        </title>
        <meta
          name="description"
          content="श्री सुरेन्द्र मेहता, खेल मंत्री, बिहार सरकार का संदेश: स्वतंत्र खेल विभाग से खेल विकास में तेजी आएगी। बिहार के खिलाड़ी राष्ट्रीय और अंतर्राष्ट्रीय स्तर पर उत्कृष्ट प्रदर्शन कर रहे हैं।"
        />
      </Helmet>
      <SimpleGrid columns={[1, 1, 2, 2]} mt="20" mb="10">
        <motion.Box
          flexShrink={0}
          initial={{
            opacity: 0,
            translateX: -50,
            translateY: 0,
          }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            translateY: 0,
          }}
          transition={{ duration: 0.3, delay: 1 }}
        >
          <Image
            borderRadius="lg"
            px={[5, 10, 20]}
            src={jitendraPageData[0]?.image}
            alt="Woman paying for a purchase"
            h="80%"
            w="100%"
          />
        </motion.Box>

        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["18", "42"]}
            letterSpacing="wide"
            color="teal.800"
            px={[4, 10, 20]}
          >
            {jitendraPageData[0]?.title}
          </Text>

          <Text
            fontWeight="bold"
            textTransform="uppercase"
            align={"right"}
            fontSize={["10", "13"]}
            letterSpacing="wide"
            color="black"
            px={[4, 10, 20]}
          >
            {jitendraPageData[0]?.designation}
          </Text>

          <motion.Box
            initial={{
              opacity: 0,
              translateX: +50,
              translateY: 0,
            }}
            whileInView={{
              opacity: 1,
              translateX: 0,
              translateY: 0,
            }}
            transition={{ duration: 0.3, delay: 1 }}
          >
            <Text
              mt={2}
              color="gray.500"
              px={[5, 10, 20]}
              align={"left"}
              letterSpacing="wide"
              fontWeight="bold"
              py="2"
            >
              {expanded ? (
                <p>{jitendraPageData[0]?.paragraph}</p>
              ) : (
                <p>{jitendraPageData[0]?.paragraph?.slice(0, 850)}...</p>
              )}
              <button
                onClick={toggleExpand}
                style={{ color: "blue", fontWeight: "500" }}
              >
                {expanded ? "कम पढ़ें" : "और पढ़ें"}
              </button>
            </Text>
          </motion.Box>
        </Box>
      </SimpleGrid>
    </>
  );
};
export default SportsMinister;
