import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const SportsSponsOrg = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Infrastructure"
        pageTitle="Infrastructure"
        id="#infrastructure"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <h1 style={{ margin: "10px auto" }}>खेल प्रायोजक संगठन</h1>

      <Box>
        <TableContainer
          boxShadow={"lg"}
          alignContent={"center"}
          justifyContent={"center"}
          marginRight={"4%"}
          marginLeft={"4%"}
          paddingTop={"5px"}
          paddingBottom={"10px"}
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th fontSize={"1.5rem"}>क्र.सं.</Th>
                <Th fontSize={"1.5rem"}>प्रायोजक</Th>
                <Th fontSize={"1.5rem"}>खेल</Th>
              </Tr>
            </Thead>

            {/* {dso.map((item, i) => (
              <Tbody>
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item?.name}</Td>
                  <Td>{item?.mob}</Td>
                  <Td>{item?.designation}</Td>
                  <Td>{item?.game}</Td>
                </Tr>
              </Tbody>
            ))} */}

            <Tbody>
              <Tr>
                <Td>1</Td>
                <Td>बिहार पुलिस भवन निगम ,पटना बिहार </Td>
                <Td>फुटबॉल </Td>
              </Tr>
              <Tr>
                <Td>2</Td>
                <Td>बिहार राज्य पावर होल्डिंग कंपनी लिमिटेड </Td>
                <Td>भारोत्तोलन </Td>
              </Tr>
              <Tr>
                <Td>3</Td>
                <Td>बिहार राज्य पुल निर्माण निगम </Td>
                <Td>सईक्लिंग </Td>
              </Tr>
              <Tr>
                <Td>4</Td>
                <Td> बिहार राज्य पथ निर्माण निगम</Td>
                <Td>निशानेबाजी </Td>
              </Tr>
              <Tr>
                <Td>5</Td>
                <Td>बिहार चिकित्सा सेवा एवम् आधारभूत संरचना निगम </Td>
                <Td>बैडमिंटन </Td>
              </Tr>
              <Tr>
                <Td>6</Td>
                <Td>बिहार शहरी आधारभूत संरचना विकास निगम </Td>
                <Td>वॉलीबॉल </Td>
              </Tr>
              <Tr>
                <Td>7</Td>
                <Td>बिहार राज्य भवन निर्माण निगम लिमिटेड </Td>
                <Td>ऐथलेटिक्स </Td>
              </Tr>
              <Tr>
                <Td>8</Td>
                <Td>बिहार राज्य शैक्षणिक आधारभूत संरचना विकास निगम </Td>
                <Td>रग्बी </Td>
              </Tr>
              <Tr>
                <Td>9</Td>
                <Td> बिहार राज्य वेयर हाउस कॉरपोरेशन लिमिटेड </Td>
                <Td>शतरंज </Td>
              </Tr>
              <Tr>
                <Td>9</Td>
                <Td>बिहार पुलिस भवन निगम ,पटना बिहार </Td>
                <Td>फुटबॉल </Td>
              </Tr>
              <Tr>
                <Td>10</Td>
                <Td>बिहार राज्य इलेक्ट्रॉनिक्स विकास निगम लिमिटेड </Td>
                <Td>हॉकी </Td>
              </Tr>
              <Tr>
                <Td>11</Td>
                <Td>बिहार राज्य माईनिंग कॉरपोरेशन लिमिटेड </Td>
                <Td>कबड्डी </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
export default SportsSponsOrg;
