import {
  Accordion,
  AccordionButton,
  Text,
  AccordionIcon,
  AccordionPanel,
  Box,
  AccordionItem,
  Center,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
// import AccordionItem from "react-bootstrap/esm/AccordionItem";

const SportEquiInf = () => {
  return (
    <div
    style={{backgroundImage: 'linear-gradient(to bottom, #0a3272, white)'}}
    >
      <Breadcrumb
        pageName="Sports-Equipment-Information"
        pageTitle="Sports-Equipment-Information"
        id="#Sports-Equipment-Information"
        img_url="./img/Sports-Equipment-Information-desktop.jpg"
        mobile_banner="./img/Sports-Equipment-Information-mobile.jpg"
      />

      <Center p={6} m={6}

      >
        <Accordion defaultIndex={[0]}>
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["18", "42"]}
            letterSpacing="wide"
            color="teal.800"
            px={[4, 10, 20]}
          >
            खेल उपकरण संबंधी जानकारी
          </Text>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Section 1 title
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Section 2 title
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} textAlign="left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Center>
    </div>
  );
};

export default SportEquiInf;
