import React from "react";
import AnimatedMarquee from "./AnimatedMarquee";
import Twitter from "./Twitter";
import NewsScroller from "./NewsScroller";
import { motion, useAnimation } from "framer-motion";
import img from "../assets/images/parallaxforsocial.jpg";
import FacebookPageEmbed from "./FacebookWidget";

const NewEvent = () => {
  return (
    <>
      <section
        className="newsEvents"
        style={{
          backgroundImage: `url(${img})`,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="row">
            <motion.div
              className="col-sm-12 col-md-4 col-lg-4"
              initial={{
                opacity: 0,
                scale: 0,
              }}
              whileInView={{ opacity: 1, scale: 1 }}
              // animate={{opacity:1, translateX:0}}
              transition={{ duration: 0.5 }}
            >
              {/* <span>
                {" "}
                <img
                  src="img/Character-animi_1.gif"
                  width="20%"
                  // margin="auto"
                />
              </span> */}
              <h4
                className="section-heading mobile-heading"
                style={{ color: "#fff" }}
              >
                {" "}
                आयोजन
              </h4>
              <div className="latestEvents">
                <div className="latestEvents__Carousel">
                  {/*  contant the whole news */}
                  <div
                    id="latestEvents-verticalCarousel"
                    className="carousel vertical slide"
                    data-ride="carousel"
                  >

                    <div className="carousel-inner">
                      {/* new event added from here */}
                      {/* -------------------------------------------------------------------- for here  */}




                      <div className="item active">
                        <img
                          src="img/picheeksilat.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            नेशनल पेंचक सिलाट चैंपियनशिप

                          </h3>
                          <span className="display-block carousel-date">
                            दिनांक 23 नवंबर से 26 नवंबर 2023
                          </span>
                          <p className="carousel-text">
                            भारतीय पेंचक सिलाट फेडरेशन, बिहार राज्य खेल प्राधिकरण एवं बिहार पेंचक सिलाट संघ के संयुक्त तत्वावधान में बिहार के पटना में स्थित पाटलिपुत्र खेल परिसर में दिनांक 23 नवंबर से 26 नवंबर 2023 तक नेशनल पेंचक सिलाट चैंपियनशिप का सफल आयोजन किया गया। इस चैंपियनशिप में देशभर से 1200 से अधिक खिलाड़ी शामिल रहें।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>



                      <div className="item">
                        <img
                          src="img/nationalgamesamaroh.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            38 वें नेशनल जूनियर एथलेटिक्स चैम्पियनशिप 2023, में बिहार के पदक विजेता खिलाड़ियों एवं प्रशिक्षकों का सम्मान सामारोह                                                  </h3>
                          <span className="display-block carousel-date">
                            23 नवंबर 2023
                          </span>
                          <p className="carousel-text">
                            बिहार राज्य खेल प्राधिकरण की ओर से 23 नवंबर 2023 को कंकड़बाग स्थित पाटलिपुत्र खेल परिसर में 38 वें नेशनल जूनियर एथलेटिक्स चैम्पियनशिप 2023,  में पदक विजेता राज्य के खिलाड़ियों एवं प्रशिक्षकों का
                            सम्मान समारोह आयोजित किया गया।

                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>



                      <div className="item">
                        <img
                          src="img/kheloindiaKhelUdghatan.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            खेलो इंडिया केंन्द्रों का उद्घाटन
                          </h3>
                          <span className="display-block carousel-date">
                            14 नवंबर 2023
                          </span>
                          <p className="carousel-text">
                            दिनांक 14 नवंबर 2023 को बाल दिवस के अवसर पर बिहार के 33 जिलों में खेलों इंडिया केंद्रों का उद्घाटन किया गया।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>


                      <div className="item">
                        <img
                          src="img/sammansamaroh.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            37 वें नेशनल गेम्स में पदक विजेता खिलाड़ियों एवं प्रशिक्षकों का सम्मान सामारोह                          </h3>
                          <span className="display-block carousel-date">
                            13 नवंबर 2023
                          </span>
                          <p className="carousel-text">
                            बिहार राज्य खेल प्राधिकरण के द्वारा कंकड़बाग स्थित पाटलिपुत्र खेल परिसर में दिनांक 13 नवंबर को गोवा में आयोजित हुए 37 वें नेशनल गेम्स में पदक विजेता बिहार के खिलाड़ियों और प्रशिक्षकों को सम्मानित किया गया।                            </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>



                      <div className="item">
                        <img
                          src="img/shootingUdghatan.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            राइफल शूट रेंज का उद्घाटन
                          </h3>
                          <span className="display-block carousel-date">
                            11 नवंबर 2023
                          </span>
                          <p className="carousel-text">
                            दिनांक 11 नवंबर 2023 को  कंकड़बाग स्थित बिहार राज्य खेल प्राधिकरण के पाटलिपुत्र खेल परिसर में 2 लेन 50 मीटर राइफल शूट रेंज का सफल उद्घाटन किया गया।                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>


                      <div className="item">
                        <img
                          src="img/sugolWeightLifting.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            प्रथम बिहार राज्य कैडेट भारोत्तोलन चैम्पियनशिप
                          </h3>
                          <span className="display-block carousel-date">
                            07 नवंबर से 10 नवंबर 2023
                          </span>
                          <p className="carousel-text">
                            07 नवंबर से 10 नवंबर 2023 को पश्चिम चंपारण के सुगौली जिले में चार दिवसीय प्रथम बिहार राज्य कैडेट भारोत्तोलन चैम्पियनशिप का आयोजन किया गया ।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/rohtashWeightlifting.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            भारोत्तोलन प्रतिभा खोज
                          </h3>
                          <span className="display-block carousel-date">
                            21 अक्टूबर 2023 को बिहार के रोहतास जिले में किया गया |
                          </span>
                          <p className="carousel-text">
                            14 वर्ष से कम आयु वर्ग के खिलाड़ियों के लिए बिहार के रोहतास जिले में दिनांक 21 अक्टूबर 2023 को बिहार राज्य खेल प्राधिकरण की ओर से भारोत्तोलन प्रतिभा खोज का आयोजन किया गया ।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/hockeyTrailar.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            हॉकी खिलाड़ियों का सलेक्शन ट्रायल
                          </h3>
                          <span className="display-block carousel-date">
                            14 अक्टूबर से 17 अक्टूबर 2023 तक बिहार रेजीमेंट सेंटर(बीआरसी) के ग्राउंड में किया गया |
                          </span>
                          <p className="carousel-text">
                            बिहार राज्य खेल प्राधिकरण एवं बिहार कला,संस्कृति एवं युवा विभाग के संयुक्त तत्वावधान में 12 से 17 आयु वर्ग के हॉकी खिलाड़ियों का सलेक्शन ट्रायल दिनांक 14 अक्टूबर से 17 अक्टूबर 2023 तक बिहार रेजीमेंट सेंटर(बीआरसी) के ग्राउंड में किया गया। खिलाड़ियों का चयन भारतीय हॉकी टीम के पूर्व कप्तान श्री अजितेश राय जी के द्वारा किया गया। इस सलेक्शन ट्रायल में पूरे बिहार से बच्चे शामिल रहें।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>


                      <div className="item">
                        <img
                          src="img/bihar-primier-league-2023.jpeg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            बिहार वॉलीबॉल प्रीमियर लीग
                          </h3>
                          <span className="display-block carousel-date">
                            30 सितम्बर 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            बिहार में पहली बार बिहार वॉलीबॉल प्रीमियर लीग का
                            आयोजन बिहार राज्य खेल प्राधिकरण एवं बिहार वॉलीबॉल
                            एसोसिएशन के संयुक्त तत्वावधान में दिनांक 30 सितम्बर
                            को पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना मेंआयोजित
                            किया गया।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>



                      <div className="item">
                        <img
                          src="img/second-badminton-competition-2023.jpeg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            द्वितीय बैडमिंटन प्रतिभा खोज कार्यक्रम
                          </h3>
                          <span className="display-block carousel-date">
                            28 - 30 सितंबर 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            बिहार राज्य खेल प्राधिकरण द्वारा दिनांक 28 सितंबर
                            2023 से 30 सितंबर 2023 तक भागलपुर इंडोर स्टेडियम में
                            अंडर 15,अंडर 17 और अंडर 19 वर्ग के बालक एवं बालिका
                            समूह के लिए द्वितीय बैडमिंटन प्रतिभा खोज कार्यक्रम
                            का सफलतापूर्वक आयोजन किया गया।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/simply-smile-workshop.jpeg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            सिंपली स्माईल्स कार्यशाला
                          </h3>
                          <span className="display-block carousel-date">
                            18 सितंबर 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            स्कूल गेम्स में भागीदारी के लिए दिनांक 26 एवं 27
                            सितंबर 2023 को छपरा में कुश्ती अंडर 14 और अंडर-17
                            आयु वर्ग के बालक एवम् बालिकाओं के लिए चयन शिविर का
                            सफल आयोजन किया गया।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/simply-period-workshop.jpeg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            सिंपली पीरियड्स कार्यशाला
                          </h3>
                          <span className="display-block carousel-date">
                            13 - 18 सितंबर 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            राज्य में महिला खिलाड़ियों और प्रशिक्षकों में
                            स्वास्थ्य के प्रति जागरूकता बढ़ाने के लक्ष्य को पूरा
                            करने हेतु बिहार राज्य खेल प्राधिकरण की ओर से दिनांक
                            13 सितंबर से 18 सितंबर के बीच सिंपली पीरियड्स की
                            कार्यशाला का सफलतापूर्वक संचालन किया गया।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/khel-samman-samaroh-2023.jpeg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            बिहार राज्य खेल सम्मान समारोह 2023
                          </h3>
                          <span className="display-block carousel-date">
                            29 अगस्त 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            पटना के ज्ञान भवन में आयोजित ‘बिहार राज्य खेल सम्मान
                            समारोह का विधिवत उद्घाटन मुख्य अतिथि कला, संस्कृति
                            एवं युवा विभाग के मंत्री श्री जितेंद्र कुमार राय
                            द्वारा 29 अगस्त 2023 को किया गया।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      {/* new event end here */}

                      <div className="item">
                        <img
                          src="img/conclave2.JPG"
                          alt="conclave-2-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">कॉन्क्लेव 2.0</h3>
                          <span className="display-block carousel-date">
                            19 मई 2023 से 20 मई 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            स्पोर्ट्स कॉन्क्लेव 2.0 का सफल आयोजन 19 और 20 मई
                            2023 को पटना के ज्ञान भवन में किया गया था। कार्यक्रम
                            का विषय था "बिहार - एक उत्कृष्ट खेल केंद्र बन रहा
                            है"।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/event-2nidjam.jpg"
                          alt="nidjam-2023"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">नीडजैम 2023</h3>
                          <span className="display-block carousel-date">
                            9 फरवरी 2023 से 12 फरवरी 2023 | पटना
                          </span>
                          <p className="carousel-text">
                            कम उम्र में ही देश भर से भविष्य के विजेताओं को
                            तलाशने और तराशने के उद्देश्य के साथ वर्ष 2003 में
                            एथलेटिक्स फेडरेशन ऑफ इंडिया AFI द्वारा NIDJAM यानि
                            नेशनल इंटर डिस्ट्रिक्ट जूनियर ऐथेलेटिक्स मीट की
                            शुरुआत हुई..
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <img
                          src="img/event-1kss.jpg"
                          alt="kss-image"
                          className="img-animate"
                        />
                        <div className="carousel-content">
                          <h3 className="carousel-title">
                            खेल सम्मान समारोह 2022
                          </h3>
                          <span className="display-block carousel-date">
                            29 अगस्त 2022 | पटना
                          </span>
                          <p className="carousel-text">
                            हर साल 29 अगस्त को राष्ट्रीय खेल दिवस के अवसर पर
                            बिहार सरकार उन एथलीटों को सम्मानित करती है जिन्होंने
                            अंतर्राष्ट्रीय और राष्ट्रीय प्रतियोगिताओं में अपने
                            उत्कृष्ट प्रदर्शन से हमारे राज्य को गौरवान्वित किया
                            है।
                          </p>
                          <a
                            href="/eventorg"
                            className="btn btn-default button-link"
                          >
                            अधिक जानकारी के लिए{" "}
                            <i className="bi bi-arrow-up-right-circle"></i>
                          </a>
                        </div>
                      </div>

                      {/* <div className="item">
                      <img src="img/FIHOdishaHockeyMensWorldCup2021.jfif"
                        alt="FIH-Odisha-Hockey-Mens_Junior-World-Cup" />
                      <div className="carousel-content">
                        <h3 className="carousel-title">FIH Odisha Hockey Men's Junior World Cup 2021</h3>
                        <span className="display-block carousel-date">24-Nov-2021 to 05-Dec-2021 |
                          Bhubaneswar</span>
                        <p className="carousel-text">
                          The event was held at the iconic Kalinga Stadium in Bhubaneswar and Argentina won
                          the title by defeating Germany in the finals.
                        </p>
                        <a href="FIHOdishaHockeyMenJunior" className="btn btn-default button-link">Visit For
                          More Info</a>
                      </div>
                    </div>

                    <div className="item">
                      <img src="img/khelo-india.jpg" alt="khelo-india image" />
                      <div className="carousel-content">
                        <h3 className="carousel-title">Khelo India University Games</h3>
                        <span className="display-block carousel-date">22-Feb-2020 to 01-Mar-2020 |
                          Bhubaneswar</span>
                        <p className="carousel-text">
                          The first edition of the Khelo India University Games was organized by the Govt.
                          of Odisha in association with Sports Authority of India (SAI).
                        </p>
                        <a href="khelo-india-event" className="btn btn-default button-link">अधिक जानकारी के लिए</a>
                      </div>
                    </div>
                    <div className="item">
                      <img src="img/fih-pro-league.jpg" alt="fih-pro-league-image" />
                      <div className="carousel-content">
                        <h3 className="carousel-title">FIH Pro League</h3>
                        <span className="display-block carousel-date">7-Jan-2020 to 25-May-2020 |
                          Bhubaneswar</span>
                        <p className="carousel-text">
                          The FIH Pro League is an international field hockey competition organized by the
                          International Hockey Federation (FIH), which replaces the FIH Hockey World
                          League.
                        </p>
                        <a href="FIH-pro-league" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                      </div>
                    </div>
                    <div className="item">
                      <img src="img/academy-cover.jpg" alt="sub-junior-academy-image" />
                      <div className="carousel-content">
                        <h3 className="carousel-title">1st Hockey India Junior and Sub-Junior Academy Nationals
                        </h3>
                        <span className="display-block carousel-date">17-Mar-2021 to 26-Mar-2021 |
                          Bhubaneswar</span>
                        <p className="carousel-text">
                          The first Hockey India Junior and Sub-Junior Academy Nationals were conducted at
                          the Kalinga Hockey Stadium in Bhubaneswar from 17-26 March 2021, which saw the
                          participation of 24 Hockey India affiliated academy teams in both categories.</p>
                        <a href="Academynational" className="btn btn-default button-link">अधिक जानकारी के लिए</a>
                      </div>
                    </div>
                    <div className="item">
                      <img src="img/SwimmingPoolCover.jpg" alt="SwimmingPoolCover-image" />
                      <div className="carousel-content">
                        <h3 className="carousel-title">61st Odisha State Swimming Championship </h3>
                        <span className="display-block carousel-date">25-Sep-2021 to 26-Sep-2021 |
                          Bhubaneswar</span>
                        <p className="carousel-text">
                          The 61st Odisha State Swimming Championship held at the world-className Biju Patnaik
                          Swimming Pool Complex within the Kalinga Stadium saw a total of 198 athletes from
                          16 districts of Odisha competing in 140 events over the two-day meet.</p>
                        <a href="SwimmingPool" className="btn btn-default button-link">अधिक जानकारी के लिए  विजिट करें</a>
                      </div>
                    </div>
                    <div className="item">
                      <img src="img/MaharashtraKhoKhoCover.jpg" alt="MaharashtraKhoKhoCover-image" />
                      <div className="carousel-content">
                        <h3 className="carousel-title">40th Junior National Kho Kho Championship 2021</h3>
                        <span className="display-block carousel-date">22-Sep-2021 to 26-Sep-2021 |
                          Bhubaneswar</span>
                        <p className="carousel-text">
                          The 40th edition of the Junior National Kho Kho Championship held from September
                          22-26, 2021 in Odisha saw more than 600 athletes from 53 teams from different
                          states and union territories vying for the crown in the men’s and women’s
                          category respectively.
                        </p>
                        <a href="MaharashtraKhoKho" className="btn btn-default button-link">अधिक जानकारी के लिए</a>
                      </div>
                    </div> */}

                      <a
                        className="carousel-control up"
                        href="#latestEvents-verticalCarousel"
                        data-slide="prev"
                      >
                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control down"
                        href="#latestEvents-verticalCarousel"
                        data-slide="next"
                      >
                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>


                  </div>

                  {/* ends  whole news  */}

                </div>
              </div>
            </motion.div>

            <NewsScroller />

            {/* <motion.div
              className="col-sm-12 col-md-4 col-lg-4"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              // animate={{opacity:1, translateX:0}}
              transition={{ duration: 0.5 }}
            > */}
            <div className="col-sm-12 col-md-4 col-lg-4">
              <h4
                className="section-heading mobile-heading"
                style={{ color: "#fff" }}
              >
                सोशल मीडिया
              </h4>
              <div className="socialWidget__area">
                <div id="social-tab">
                  {/* <ul className="nav nav-pills pull-right">
                    <li className="active">
                      <a
                        href="#facebookpost"
                        title="facebook"
                        className="socialWidget__btn socialWidget__btn--fb "
                        data-toggle="tab"
                        aria-expanded="false"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>

                    <li className="">
                      {" "}
                      <a
                        href="#twitterpost"
                        className="socialWidget__btn socialWidget__btn--twitter "
                        title="twitter"
                        data-toggle="tab"
                        aria-expanded="true"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                  </ul> */}
                  <div className="tab-content clearfix">
                    {/* twitter widget  */}

                    {/* <Twitter /> */}

                    {/* Facebook widget */}

                    <FacebookPageEmbed />

                    {/* <div
                      className="tab-pane active"
                      id="facebookpost"
                      style={{}}
                    >
                      <div id="fb-root" style={{}}>
                        <script
                          async
                          defer
                          crossOrigin="anonymous"
                          src="https://connect.facebook.net/or_IN/sdk.js#xfbml=1&version=v12.0"
                          nonce="ya8zNWEV"
                        ></script>
                        <div
                          className="fb-page"
                          data-href="https://www.facebook.com/BSSABihar"
                          data-tabs="timeline"
                          id="facebook-container"
                          data-width="350"
                          data-height="448"
                          data-small-header="false"
                          data-adapt-container-width="false"
                          data-hide-cover="true"
                          data-show-facepile="false"
                        >
                          <blockquote
                            cite="https://www.facebook.com/BSSABihar"
                            className="fb-xfbml-parse-ignore"
                          >
                            <a href="https://www.facebook.com/BSSABihar">
                              Bihar State Sports Authority
                            </a>
                          </blockquote>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="tab-pane " id="twitterpost">
                      <div className="social-widget">
                        <a
                          className="twitter-timeline"
                          data-width="500"
                          data-height="450"
                          href="https://twitter.com/BSSABihar?t=qyhd_a3IALaUaUSO4E1-SA&s=08"
                        >
                          Tweets by @BSSABihar
                        </a>
                        <script
                          async
                          src="https://platform.twitter.com/widgets.js"
                          charSet="utf-8"
                        ></script>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>

              {/* <div>
             <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F215654715566983%2F&width=300&show_text=true&height=223&appId" width="300" height="223" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
             <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&width=300&show_text=true&height=310&appId" width="300" height="310" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div> */}
              {/*  <div className="social-widget">
             <a className="twitter-timeline" data-width="300" data-height="400" href="https://twitter.com/sports_odisha?ref_src=twsrc%5Etfw">Tweets by sports_odisha</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
               </div> */}
            </div>
            {/* </motion.div> */}
          </div>
        </div>
      </section>
      {/* <AnimatedMarquee /> */}
    </>
  );
};
export default NewEvent;
