import React from "react";

import styles from "../../styles/policy.module.css";
import Header from "../../components/Header";
import Breadcrumb from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { SimpleGrid, Box, Text, Center } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Policy = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sports Recruitment Policy | State Sports Policy- BSSA</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की भर्ती नीति: राज्य सरकार खिलाड़ियों को सीधे नौकरी का अवसर प्रदान करने के लिए मेडल लाओ, नौकरी पाओ योजना के तहत राष्ट्रीय और अंतर्राष्ट्रीय खेल आयोजनों में पदक जीतने वाले खिलाड़ियों को प्रथम श्रेणी की नौकरियां दे रही है।"
        />
      </Helmet>
      <Breadcrumb
        pageName="Sports recruitment Policy"
        pageTitle="Sports recruitment Policy"
        id="#Sports-recruitment-Policy"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />

      <div
        style={{
          // backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
          backgroundColor: "#063a7a",
        }}
      >
        <Text
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="white"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          खेल नियुक्ति नीति
        </Text>

        <SimpleGrid columns={1} spacingX={8} mt={5}>
          <Center
            as={"text"}
            h="100px"
            textAlign={"left"}
            p="3%"
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["8", "16"]}
            letterSpacing="wide"
            color="white"
          >
            बिहार राज्य खेल प्राधिकरण खिलाडियों को सीधे नौकरी का अवसर प्रदान
            करने, खेल को प्रोत्साहित करने और एथलीटों/खिलाडियों के सर्वांगीण
            विकास के लिए निरंतर प्रयासरत और कार्यरत है । पाटलिपुत्र स्पोर्ट्स
            कॉम्प्लेक्स, कंकड़बाग में आयोजित 18वें NIDJAM 2023 के उद्घाटन समारोह
            के दौरान बिहार के माननीय मुख्यमंत्री श्री नीतीश कुमार जी ने
            खिलाडियों के हितों को ध्यान में रखते हुए मेडल लाओ नौकरी पाओ योजना का
            वादा किया था।जिसे पूरा करते हुए हुए बिहार सरकार द्वारा इस वर्ष से
            इसके तहत बहालियों पर भी कार्य किया जा रहा है। इस योजना के तहत, बिहार
            सरकार राष्ट्रीय और अंतर्राष्ट्रीय खेल आयोजनों में पदक(स्वर्ण,रजत
            अथवा कांस्य) जीतने वाले राज्य के खिलाड़ियों को बिहार प्रशासनिक सेवा
            ,बिहार पुलिस सेवा में उप-विभागीय मजिस्ट्रेट और पुलिस उपाधीक्षक के
            रूप में प्रथम श्रेणी तक की नौकरी प्रदान करेगी।{" "}
          </Center>
          <Center>
            <a
              href="img/Bihar_Sports_Recruitment_Policy_Hindi.pdf"
              target="_blank"
              style={{
                fontSize: "18px",
                fontWeight: "800",
                textDecoration: "none", // Remove underline from the link
                display: "inline-block", // Ensure the button takes only the necessary width
                padding: "10px 20px", // Adjust padding to your liking
                marginTop: "20px",
                marginBottom: "16px",
                backgroundColor: "#007bff", // Button background color
                color: "#fff", // Button text color
                borderRadius: "5px", // Add rounded corners to the button
                cursor: "pointer", // Change cursor to indicate it's clickable
                position: "relative",
              }}
            >
              खेल नियुक्ति नीति PDF<span> 👆</span>
            </a>
          </Center>
        </SimpleGrid>
      </div>
    </div>
  );
};

export default Policy;
