import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const SportsEventsList = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Sports Events"
        pageTitle="Sports Events"
        id="#Sports-Events"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> खेल आयोजनों की सूचि </h1>
      </GridItem>
      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  स्पेशल ओलंपिक वर्ल्ड समर गेम्स 2023
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              दिनांक 17 से 25 जून तक बर्लिन, जर्मनी में स्पेशल ओलंपिक वर्ल्ड समर
              गेम्स 2023 का आयोजन किया गया था। इस साल इन स्पेशल ओलंपिक्स में 26
              प्रतिस्पर्धाएं शामिल की गयी हैं, जिनमें 190 देशों के 7,000 विशेष
              खिलाड़ियों ने भाग लिया। इन खिलाड़ियों को 3,000 से अधिक ट्रेनर्स और
              20,000 के आसपास वालंटियर्स का सपोर्ट रहा। स्पेशल ओलंपिक मानसिक रूप
              से मंद लोगों के लिए आयोजित किये जाते हैं। इसका आयोजन स्पेशल ओलंपिक
              कमेटी (एसओसी) द्वारा होता है। इसका मकसद बौद्धिक रूप से अशक्त अथवा
              अल्पबुद्धि बच्चों और वयस्कों को समर्थन प्रदान करना है। इस खेल का
              ध्येय है - 'Joy and Happiness to All the Children of the World'
              यानि दुनिया के सभी बच्चों को खुशी और प्रसन्नता मिले।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  एशियाई साइक्लिंग चैंपियनशिप 2023
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              एशियाई साइक्लिंग चैंपियनशिप 1963 से सड़क साइकिल रेसिंग और ट्रैक
              साइक्लिंग के लिए एक वार्षिक महाद्वीपीय साइक्लिंग चैंपियनशिप है,
              विशेष रूप से राष्ट्रीय शासी निकाय (एशियाई साइक्लिंग परिसंघ के
              सदस्य देशों) द्वारा चुने गए एशियाई साइकिल चालकों के लिए। 2023
              एशियाई ट्रैक साइक्लिंग चैंपियनशिप का 42वां संस्करण दिनांक 14 जून
              से 19 जून 2023 तक निलाई, मलेशिया में आयोजित किया गया था।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  पेरिस ओलंपिक्स 2024
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              2024 में पेरिस ओलंपिक खेलों का तीसरी बार आयोजन करने जा रहा है, ऐसा
              करने वाला लंदन के बाद दूसरा शहर बन जाएगा। लंदन ने तीन बार ओलंपिक
              ग्रीष्मकालीन खेलों की मेजबानी की है। 1908, 1984 और 2012 के ओलंपिक
              खेलों का आयोजन लंदन में किया गया था। पेरिस ने पहली बार ओलंपिक
              खेलों की मेजबानी साल 1900 में की थी। जिसके चार साल बाद एथेंस में
              मल्टी स्पोर्ट्स आयोजन को फिर से शुरू किया गया। 2024 ग्रीष्मकालीन
              ओलंपिक इवेंट में 32 खेलों को शामिल किया जाएगा जिसमें 306 इवेंट
              शामिल हैं; इन खेलों में ब्रेकिंग (ब्रेक डांस) ओलंपिक में पदार्पण
              करेगा। यह प्रतीक तीन प्रतिष्ठित प्रतीकों का एक रूप है: स्वर्ण पदक,
              द फ्लेम, और मैरिएन – जो कि क्रांति और फ्रांस के लोगों का एक प्रतीक
              है। ओलंपिक खेलों के लिए पहली बार शौकिया एथलीटों के लिए एक मास
              मैराथन उसी दिन आयोजित होगी, जिस दिन एलिट इवेंट्स का आयोजन होगा।{" "}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  19वीं एशियाई खेल 2023
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              आगामी 23 सितंबर से 8 अक्टूबर, 2023 तक पीपुल्स रिपब्लिक ऑफ चाइना के
              हांगझू में 19वीं एशियाई खेल 2023 का आयोजन किया जा रहा है। 15 दिनों
              तक चलने वाले महाद्वीपीय शोपीस, जिसे कोरोना वायरस के कारण 2022 से
              2023 में स्थानांतरित कर दिया गया है, इसमें इस वर्ष 40 खेल शामिल
              होंगे। इस चतुष्कोणीय आयोजन में भारत का एक गौरवशाली इतिहास रहा है
              और पिछले कुछ दशकों में एशियाई खेलों में भारतीय भागीदारी में काफी
              वृद्धि देखी गई है। पिछले संस्करण, जकार्ता 2018 में, भारत ने 36
              विभिन्न खेलों में प्रतिस्पर्धा करने के लिए 570 का दल भेजा था।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  वर्ल्ड आउटडोर बाउल्स चैंपियनशिप 2023
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              आगामी 29 अगस्त से 10 सितंबर तक ऑस्ट्रेलिया के क्वींसलैंड के गोल्ड
              कोस्ट में आयोजित होने वाली वर्ल्ड आउटडोर बाउल्स चैंपियनशिप 2023,
              वर्ल्ड बाउल्स चैंपियनशिप का 14वां संस्करण होगा। खेल द्वारा इस
              आयोजन का लंबे समय से इंतजार किया जा रहा था क्योंकि 2020 विश्व
              आउटडोर बाउल्स चैंपियनशिप को COVID-19 महामारी के कारण रद्द कर दिया
              गया था, जिसके परिणामस्वरूप 2016 में आखिरी चैंपियनशिप के बाद से सात
              साल का इंतजार करना पड़ा। वहाँ पाँच स्थान होंगे; ब्रॉडबीच बाउल्स
              क्लब, मुस्ग्रेव हिल बाउल्स क्लब, क्लब हेलेंसवेल, पैराडाइज़ पॉइंट
              क्लब और मुडगेराबा क्लब। इस चैंपियनशिप में 8 प्रतियोगिताएं होंगी जो
              2023 विश्व चैंपियन का निर्धारण करेंगी, पुरुष एकल, युगल, ट्रिपल और
              फोर और महिला एकल, युगल, ट्रिपल और फोर इसके अलावा, दो समग्र टीम
              विजेता हैं जो क्रमशः लियोनार्ड और टेलर ट्रॉफी प्राप्त करेंगे।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>{" "}
    </div>
  );
};
export default SportsEventsList;
