import React, { useState, useEffect } from "react";
import styled from "../tenders/style.module.css";
import { useStatStyles } from "@chakra-ui/react";
import goldImg from '../../assets/images/gold_icon.png'
import silverImg from '../../assets/images/silver_icon.png'
import bronzeImg from '../../assets/images/bronze_icon.png'
import api from "../../utils/ApiMethod";
// import Styles from "../../styles/Tender.module.css";


const Styles = {
    th: {
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
    },
    head: {
        backgroundColor: '#28A745',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
    },
    table_cell: {
        padding: '8px',
        borderBottom: '1px solid #dee2e6',
        backgroundColor: '#f8d7da', // Light red background
        color: '#721c24', // Dark red text color
    },
    colorfulContent: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
        backgroundColor: '#28A745 !important', // Green background
        color: 'white !important', // White text color
        padding: '8px !important',
        borderRadius: '4px !important',
    },
};

const MedalTally = () => {


    const [data, setData] = useState([]);
    const handleFetchData = async () => {
        try {
            // const data = await api.fetchData("http://localhost:5000/api/latestinformation");
            const res = await api.fetchData("/prokabaddipointtable");
            setData(res);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    // console.log('data', data)
    return (
        <>
            <div className={`container ${styled.medal} `} style={{ width: "100%" }}>
                <div style={{ background: "#08549c", borderRadius: "5px", paddingTop: "20px" }}>
                    <h4
                        style={{
                            color: "white",
                            fontWeight: "800",
                            fontSize: "18px",
                            // marginTop: "40px",
                            textAlign: "center",
                            marginBottom: "20px"
                        }}
                    >
                        प्रो कबड्डी लीग 10वां सीजन 2023-24
                    </h4>
                    <div className={Styles.resp} style={{ paddingBottom: "15px" }}>
                        {/* <table class="table table-striped ">
                            <thead>
                                <tr class="table-primary">
                                    <th className={`${Styles.th}`} scope="col">
                                        क्र.सं.{" "}
                                    </th>
                                    <th className={`${Styles.head}`} scope="col">
                                    टीम
                                    </th>

                                    <th className={`${Styles.head}`} scope="col">
                                    पॉइंट टेबल
                                    </th>
                                </tr>
                            </thead>
                            {data && data?.map((item, index) => (
                                <tbody>
                                    <tr>
                                        <td scope="row">{index + 1}</td>
                                        <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                                            {item?.name}
                                        </td>
                                        <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table> */}
                        <table class="table table-bordered table-striped " >
                            <thead>
                                <tr class="table-primary">
                                    <th className={`${Styles.th} ${styled.prokabaddisl}`} scope="col" style={{width:".6em"}}>
                                        क्र.सं.{" "}
                                    </th>
                                    <th className={`${Styles.head} ${styled.prokabaddiName}`} scope="col" style={{width:"10em"}}>
                                        टीम
                                    </th>

                                    <th className={`${Styles.head}`} scope="col">
                                        पॉइंट टेबल
                                        <div className={` ${Styles.colorfulContent}`} style={{ display: "flex", gap:"7%" }}>
                                            {/* 42px */}
                                            <span>GP</span>
                                            <span>{" "}W</span>
                                            <span>L</span>
                                            <span>{`${"        "}`}D</span>
                                            <span>PD</span>
                                            <span>Pts</span>

                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            {/* {data && data?.map((item, index) => ( */}
                            {data && data.sort((a, b) => b.Pts - a.Pts).map((item, index) => (
                                <tbody key={index}>
                                    <tr>
                                        <td scope="row">{index + 1}</td>
                                        <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                                            {item?.name}
                                        </td>
                                        <td className={Styles.colorfulContent} style={{ textAlign: "left" }}>
                                            {/* You can add content inside this cell if needed */}
                                            <div style={{ display: "flex", gap:"7%" }}>
                                                {/* 40px */}
                                                {/* Your content here */}
                                                <p>{item?.GP}</p>
                                                <p>{item?.Win}</p>
                                                <p>{item?.Loss}</p>
                                                <p>{item?.D}</p>
                                                <p>{item?.PD}</p>
                                                <p>{item?.Pts}</p>
                                                {/* {item?.GP}
                                            {item?.Loss}
                                            {item?.Win}
                                            {item?.D}
                                            {item?.PD}
                                            {item?.Pts} */}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>

                    </div>
                </div>
            </div>
        </>
    )
}
export default MedalTally

