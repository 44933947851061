import React from "react";

import stylesgfi from "./NationalSchoolGames.module.css";
import Breadcrumb from "../../components/common/Breadcrumb";
// import Button from "react-bootstrap/Button";
import { Button, Container, Row, Col } from "react-bootstrap";

// import Athlite from "./Athlite";

export default function BiharWomenKabaddiLeague() {
  return (
    <>
      <Breadcrumb
        pageName={BiharWomenKabaddiLeague}
        pageTitle={"BiharWomenKabaddiLeague"}
        img_url={"./img/WEBSITE BANNER_Destop.jpg"}
        mobile_banner="./img/WEBSITE BANNER_Mobile.jpg"
      />
      <div></div>
      <div>
        <h1 className={`${stylesgfi.title}`}>
          प्रथम बिहार वुमेन कबड्डी लीग 2024
        </h1>
      </div>

      <Container className="button-container mt-5">
        <div className="mt-5" style={{ marginTop: "40px" }}>
          <Row>
            <Col
              xs={12}
              md={3}
              className="text-center "
              style={{ marginTop: "20px" }}
            >
              <Button
                className="animated-button"
                variant="success"
                href="/bihar-Women-Kabaddi-Match-Schedule"
              >
                मैच शेड्यूल ↗
              </Button>
            </Col>
            <Col
              xs={12}
              md={3}
              className="text-center "
              style={{ marginTop: "20px" }}
            >
              <Button
                className="animated-button"
                variant="success"
                href="https://www.elev8sportz.com/bihar-women-kabaddi-league/schedule"
                target="_blank"
              >
                लेटेस्ट परिणाम ↗
              </Button>
            </Col>

            <Col
              xs={12}
              md={3}
              className="text-center"
              style={{ marginTop: "20px" }}
            >
              <Button
                className="animated-button"
                variant="success"
                // href="https://www.youtube.com/live/rMLo-Q4Loh8"
                // href="https://www.youtube.com/watch?v=kZjncxfox9s"
                //href="https://www.youtube.com/watch?v=ydF07OqSsNU"
                // href="https://www.youtube.com/watch?v=fz3-K7K5VYU"
                // href="https://www.youtube.com/watch?v=YTUTzj1cOV0"
                // href="https://www.youtube.com/watch?v=Gda8hY8oXJk"
                href="https://www.youtube.com/watch?v=XGCx6cxUg_I"
                target="_blank"
              >
                मैच का सीधा प्रसारण ↗
              </Button>
            </Col>
            <Col
              xs={12}
              md={3}
              className="text-center"
              style={{ marginTop: "20px" }}
            >
              <Button
                className="animated-button"
                variant="success"
                href="https://linktr.ee/univsports"
                target="_blank"
              >
                विस्तृत जानकारी के लिए क्लिक करें ↗
              </Button>
            </Col>
          </Row>
        </div>
      </Container>

      <div className={`container ${stylesgfi.game_sgfi}`}>
        <a
        // href="/athlet"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/sitamarhi_bihar_balvikash.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>सीतामढ़ी सेंटीनेल्स </p>
          </div>
        </a>

        <a
        // href="/cricket"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/magadh _apr.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>मगध वरियर्स</p>
          </div>
        </a>

        <a
        //  href="/sepakTakra"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/saran_ninti_hos.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>सारण स्ट्राइकर्स</p>
          </div>
        </a>

        <a
        //  href="/weightlifting"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/patna_bbihar.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p>पटना पेलिकन </p>
          </div>
        </a>

        <a
        //  href="/football"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/ninja_cdot.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p> नालंदा निंजास</p>
          </div>
        </a>
        <a
        //  href="/football"
        >
          <div className={`${stylesgfi.game_card}`}>
            <img
              src="./img/siwan_par.png"
              alt=""
              style={{ height: "200px", width: "200px", padding: "5px" }}
            />
            <p> सीवान टाइटेंस</p>
          </div>
        </a>
      </div>
      <a
      // href="https://sgfionline.net/reports/MedalTally.aspx?NsgNbr=66"
      // target="_blank"
      >
        {/* <Button
          variant="warning"
          style={{ marginBottom: "20px", color: "black" }}
        >
          मेडल तालिका ↗
        </Button> */}
      </a>
    </>
  );
}
