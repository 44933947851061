import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const Training = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Sports Training"
        pageTitle="Sports Training"
        id="#Sports-Training"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> प्रशिक्षण </h1>
      </GridItem>
      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  प्रशिक्षण
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              बिहार के खिलाड़ियों को बेहतर प्रशिक्षण दिलाने के लिए बिहार सरकार
              द्वारा राज्य में भिन्न भिन्न खेलों के लिए प्रशिक्षकों का इंतजाम
              किया जा रहा है। राज्य के 33 जिलों में खेल केंद्र के निर्माण के साथ
              ही हर केंद्र के लिए एक प्रशिक्षक की नियुक्ति की जानी है। राज्य में
              पहले से मौजूद प्रशिक्षक भी राज्य के खिलाड़ियों को प्रशिक्षण प्रदान
              कर उनके प्रतिभा को संवार रहे हैं।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>{" "}
    </div>
  );
};
export default Training;
