import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import api from "../../utils/ApiMethod";
import { Box, Center, Heading, Grid, Image } from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

const PressGallery = () => {
  const category1Images = [
    { name: "KSS", img: "/img/tarang1.JPG" },
    { name: "NIDJAM", img: "/img/tarang1.JPG" },
    { name: "NSG", img: "/img/tarang1.JPG" },
    { name: "NSG", img: "/img/tarang1.JPG" },
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sports Photos | Bihar State Sports Authority Gallery | BSSA
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की गैलरी: इस पेज पर विभिन्न खेल आयोजनों, समारोहों, और खिलाड़ियों की उपलब्धियों की तस्वीरें और वीडियो प्रदर्शित किए गए हैं। "
        />
      </Helmet>
      <Breadcrumb
        pageName="Gallery"
        pageTitle="gallery"
        id="#gallery"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <Heading p={5} fontSize="50px">
        तस्वीरें
      </Heading>
      <div
        style={{
          height: "4px",
          width: "100%",
          backgroundColor: " rgb(8, 44, 44)",
          paddingBottom: "13px",
        }}
      ></div>
      <div className="container">
        <Row className="justify-content-center">
          {category1Images.map((item, index) => (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="mb-4"
              style={{ marginTop: "15px" }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <Link
                  to={`/Gallery/${item.name}`}
                  className="text-decoration-none"
                >
                  <div className="aspect-ratio-container">
                    <img
                      src={item.img}
                      alt={item.name}
                      className="aspect-ratio-item"
                    />
                  </div>
                  <h3 className="text-center mt-2">{item.name}</h3>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
        ;
      </div>
    </div>
  );
};

export default PressGallery;
