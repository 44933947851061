import React from "react";
import styles from "../styles/loader.module.css";

const Loader = () => {
  return (
    <div className={styles.loader_container}>
      <img
        src="https://miro.medium.com/v2/resize:fit:640/0*U2RiSXJx8U9K4thZ.gif"
        alt="Loading"
      />
    </div>
  );
};

export default Loader;
