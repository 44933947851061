import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import ServiceCard from "./ServiceCard";
import api from "../utils/ApiMethod";
import { useState } from "react";
import { useEffect } from "react";
import AnimatedMarquee from "./AnimatedMarquee";
import { delay, motion, useAnimation } from "framer-motion";

const srvices = [
  {
    id: 1,
    heading: " ",
    body: "",
  },
  {
    id: 1,
    heading: " ",
    body: "",
  },
  {
    id: 1,
    heading: " ",
    body: "",
  },
  {
    id: 1,
    heading: " ",
    body: "",
  },
];

const ServiceNew = () => {
  const [servicesData, setServicesData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/services");
      setServicesData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div
      style={{
        width: "",
        marginTop: "",
        backgroundColor: "#063a7a",
        border: "1px solid #063a7a",
      }}
    >
      <div class="container">
        <div style={{ width: "90%", margin: "40px auto" }}>
          <h4
            className="section-heading mobile-heading"
            style={{ paddingTop: "15px", color: "white" }}
          >
            सेवाएं एवम् सुविधाएं
          </h4>
        </div>

        <SimpleGrid columns={[1, 2, 3, 5]} spacing="20px" m="auto" width="100%">
          {servicesData &&
            servicesData.map((data, index) => (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  translateX: index % 2 === 0 ? -50 : 50,
                  translateY: -50,
                }}
                whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                transition={{ duration: 0.3, delay: index * 0.2 }}
              >
                <ServiceCard data={data} />
              </motion.div>
            ))}

          {/* <Box bg="tomato" height="200px" width="100%"></Box> */}
          {/* <ServiceCard /> */}
          {/* <Box bg="tomato" height="200px" width="100%"></Box>
          <Box bg="tomato" height="200px" width="100%"></Box>
          <Box bg="tomato" height="200px" width="100%"></Box>
          <Box bg="tomato" height="200px" width="100%"></Box> */}
          {/* <ServiceCard />
          <ServiceCard />
          <ServiceCard />
          <ServiceCard />
          <ServiceCard />
          <ServiceCard />
          <ServiceCard />
          <ServiceCard />
          <ServiceCard /> */}
        </SimpleGrid>
      </div>
      {/* <AnimatedMarquee /> */}
    </div>
  );
};

export default ServiceNew;
