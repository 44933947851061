import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import ComingSoon from "../../components/common/ComingSoon";

const Schemes = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Schema"
        pageTitle="Schema"
        id="#Schema"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />
      <h1 style={{ marginTop: "10px auto" }}>योजना</h1>
      <ComingSoon />
    </div>
  );
};
export default Schemes;
