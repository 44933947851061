import React, { useState } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";

const Message = () => {
  const [formStatus, setFormStatus] = useState("भेजें");
  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, email, message } = e.target.elements;
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    setTimeout(() => {
      setFormStatus("भेजें");
      alert("संदेश सफलतापूर्वक भेजा गया");
    }, 2000);
    // console.log(conFom);
  };
  return (
    <div>
      <Breadcrumb
        pageName="Contact us"
        pageTitle="Contact us"
        id="#contact-us"
        img_url="./img/Message-banner-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <div style={{ margin: "30px 0px" }}>
        <div className="container mt-5">
          <h2 className="mb-20">अपना सवाल लिखें</h2>
          <form
            onSubmit={onSubmit}
            style={{
              marginBottom: "50px",
              marginTop: "30px",
              border: "2px solid gray",
              width: "50%",
              margin: "40px auto",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "1px 1px 10px 0px green",
            }}
          >
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                नाम
              </label>
              <input className="form-control" type="text" id="name" required />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                ईमेल
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="message">
                संदेश
              </label>
              <textarea className="form-control" id="message" required />
            </div>
            <button
              className="btn"
              type="submit"
              style={{
                margin: "20px",
                padding: "8px 18px",
                fontWeight: "800",
                backgroundColor: "#fcb606",
                color: "#fff",
              }}
            >
              {formStatus}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Message;
