import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function Athlite() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67 वां नेशनल स्कूल गेम्स 2023-24 </strong>एथेलेटिक्स
          चैंपियनशिप
        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
            67 वें नेशनल स्कूल गेम्स 2023 के एथेलेटिक्स चैंपियनशिप अंडर-17
            (बालक/बालिका वर्ग) का आयोजन
          </p>
          <p>
          दिनांक 06अप्रैल 2024 से 09 अप्रैल 2024 तक बिहार की राजधानी पटना के कंकड़बाग में स्थित पाटलिपुत्र खेल परिसर में किया जा रहा है।
          </p>
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
          एथेलेटिक्स चैंपियनशिप में खिलाड़ियों के प्रवेश करने की अंतिम तिथि 31 मार्च 2024 तय की गई है। इसके साथ ही इस चैंपियनशिप में हिस्सा ले रहे खिलाड़ियों के लिए रिपोर्टिंग की तिथि 05 अप्रैल 2024 निर्धारित की गई है।
          </p>
          <br />
          <p>नियंत्रण कक्ष के प्रभारी का नाम : प्रेम कुंज</p>

          <p>मोबाइल नंबर 8210624562</p>
          <br />
          <p>नोडल ऑफिसर का नाम: श्री राजेंद्र कुमार, जिला खेल पदाधिकारी</p>
          <p>मोबाइल नंबर : 9934274167</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/running_sgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>एथलेटिक्स</p>
          <Button  variant="warning" style={{ margin: "20px", color: "black" }}>
            <a href="img/LETTER_NO_986.PDF" target="_blank">
              विवरण देखें
            </a>
          </Button>
        </div>
      </div>
      {/* <button className="btn">अधिक जानकारी के लिए क्लिक करें ↗</button> */}
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          अधिक जानकारी के लिए क्लिक करें ↗
        </Button>
      </a>
    </>
  );
}

export default Athlite;
