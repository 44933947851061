import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const Infrastructure = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Infrastructure"
        pageTitle="Infrastructure"
        id="#infrastructure"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />

      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> आधारभूत संरचनाएं </h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  आधारभूत संरचनाएं
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              बिहार राज्य खेल प्राधिकरण का कार्यालय पाटलिपुत्र खेल परिसर में
              स्थित है। इंडोर स्टेडियम से सटे इस दो मंजिले इमारत में बिहार राज्य
              खेल प्राधिकरण के महानिदेशक सह मुख्य कार्यकारी अधिकारी, निदेशक सह
              सचिव एवम् क्रीड़ा कार्यपालक सहित बिहार राज्य खेल प्राधिकरण के
              कर्मचारियों का कार्यालय स्थित है। राज्य भर में खेल एवम् खिलाड़ियों
              के चौतरफा विकास में प्राधिकरण के सभी सदस्यों का महत्वपूर्ण योगदान
              है। इस दो मंजिले इमारत में खेल संबंधित आयोजनों के सभा के लिए 2
              कांफ्रेंस हॉल भी मौजूद है। बिहार राज्य खेल प्राधिकरण पूरे राज्य
              में खेल एवम् खिलाड़ियों के सम्पूर्ण विकास के लिए सदैव प्रतिबद्ध
              है। बिहार की राजधानी पटना में भिन्न भिन्न खेलों के अभ्यास के लिए
              सुव्यवस्थित इंतजाम किया गया है। जिसके अंतर्गत
              रग्बी,फेंसिंग,बास्केट बॉल,मुक्केबाजी,एथेलेटिक्स, बैडमिंटन,कब्बड़ी,
              कुश्ती, भारोत्तोलन आदि खेलों के खिलाड़ियों के लिए अभ्यास के स्थान
              का प्रबंध पाटलिपुत्र खेल परिसर में किया गया है। जहां काफी संख्या
              में प्रतिदिन राज्य के युवा खिलाड़ी अभ्यास के लिए एकत्रित होते हैं
              एवम् खेल की कौशल को बढ़ाने का अभ्यास करते हैं।
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default Infrastructure;
