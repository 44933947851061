import React from "react";

import {
    Box,
    Center,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Image,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const Kss = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Khel Samman Samaroh | Sports Awards | BSSA</title>
                <meta
                    name="description"
                    content="बिहार खेल सम्मान समारोह: राष्ट्रीय और अंतर्राष्ट्रीय स्तर के खेलों में उपलब्धि प्राप्त कर राज्य को गौरवान्वित करने वाले खिलाड़ियों और प्रशिक्षकों को प्रतिवर्ष 29 अगस्त को 'राष्ट्रीय खेल दिवस' पर सम्मानित किया जाता है। इसका उद्देश्य खिलाड़ियों को उच्च स्तरीय प्रदर्शन के लिए प्रेरित करना है।"
                />
            </Helmet>
            {/* Khelm-Samman-Samaroh-desktop.jpg */}
            <Breadcrumb
                pageName="Khel Samman Samaroh"
                pageTitle="Khel Samman Samaroh"
                id="#kss"
                img_url="./img/Khelm-Samman-Samaroh-desktop.jpg"
                mobile_banner="./img/Khelm-Samman-Samaroh-mobile.jpg"
            />
            <Box
                p={4}
                display={{ md: "flex" }}
                h="70vh"
                width="100%"
                px={[4, 10, 20]}
                py={[4, 10, 20]}
            >
                <Box flexShrink={0}>
                    {/* <Image
                        borderRadius="lg"
                        // width={{ md: 100 }}
                        px={[5, 10, 20]}
                        src="https://bssadev.brandradiator.in/img/event-1kss.jpg"
                        alt="Woman paying for a purchase"
                        h="90%"
                        w="100%"
                    /> */}
                </Box>

                <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
                    <Text
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize={["18", "42"]}
                        letterSpacing="wide"
                        color="teal.800"
                        px={[4, 10, 20]}
                    >
                        खेल सम्मान समारोह
                    </Text>

                    <Text
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize={["8", "16"]}
                        letterSpacing="wide"
                        color="teal.800"
                        textAlign={'left'}
                    >
                        अधिकारिक अंतर्राष्ट्रीय एवं राष्ट्रीय स्तर के खेलों में निर्धारित मानदंड के अनुसार उपलब्धि प्राप्त कर राज्य को गौरवान्वित करने वाले सभी विशेष खिलाड़ियों/ प्रशिक्षकों को ‘राष्ट्रीय खेल दिवस ‘ प्रतिवर्ष 29 अगस्त के अवसर पर सम्मानित किया जाता है। सरकार द्वारा संचालित खेल सम्मान समारोह का मुख्य उद्देश्य खिलाड़ियों को भविष्य में उच्च स्तरीय प्रदर्शन एवं उपलब्धि प्राप्त करने के लिए प्रेरित करना है। ताकि खेल जगत में राष्ट्र एवं बिहार राज्य गौरवान्वित हो सके।                    </Text>

                    <a
                        // href="https://docs.google.com/forms/d/e/1FAIpQLSe3YnCHWBW8OXogi37FO_qT_KmMvrM36Oy2rxSnMWUPhyYMsA/closedform"
                        // href="https://kss.biharsports.org/"
                        href="#"
                        target="_blank"
                        style={{
                            fontSize: "18px",
                            fontWeight: "800",
                            textDecoration: "none", // Remove underline from the link
                            display: "inline-block", // Ensure the button takes only the necessary width
                            padding: "10px 20px", // Adjust padding to your liking
                            backgroundColor: "#007bff", // Button background color
                            color: "#fff", // Button text color
                            borderRadius: "5px", // Add rounded corners to the button
                            cursor: "pointer", // Change cursor to indicate it's clickable
                            margin: "15px 0px"
                        }}
                    >
                        आवेदन करें <span> 👆</span>
                    </a>

                </Box>
            </Box>
        </div>
    );
};
export default Kss;
