import React, { useEffect, useState } from "react";
import "./Indivisiual.css";
import { SimpleGrid, border } from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import { delay, motion, useAnimation } from "framer-motion";

import { Box, Text, Image } from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const ChiefMinister = () => {
  const [harjotPageData, setHarjotPageData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/chiefminister");
      setHarjotPageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      {/* dil-se-khelo-milke-jito-banner-mobile */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Sports Authority | CM Shri Nitish Kumar | Bihar State Sports Authority
        </title>
        <meta
          name="description"
          content="बिहार खेल प्राधिकरण पर मुख्यमंत्री का संदेश: राज्य में खेलों के विकास, खिलाड़ियों की प्रगति और खेल के माध्यम से युवा पीढ़ी के सशक्तिकरण पर सरकार की प्रतिबद्धता को रेखांकित करता है।"
        />
      </Helmet>
      <Breadcrumb
        pageName=" Bihar Chief minister message"
        pageTitle="Chief minister message"
        id="#chief-minister"
        img_url="./img/dil-se-khelo-milke-jito-banner-desktop.jpg"
        mobile_banner="./img/dil-se-khelo-milke-jito-banner-mobile.jpg"
      />
      <SimpleGrid columns={[1, 1, 2, 2]} mt="10" mb="10">
        <motion.Box
          flexShrink={0}
          initial={{
            opacity: 0,
            translateX: -50,
            translateY: 0,
          }}
          whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
          transition={{ duration: 0.3, delay: 1 }}
        >
          <Image
            // style={{
            //   borderTop: "6px solid red",
            //   borderBottom: "6px solid red",
            // }}
            borderRadius="lg"
            px={[5, 10, 20]}
            src={harjotPageData[0]?.image}
            alt="Woman paying for a purchase"
            h="350px"
            w="100%"
          />
        </motion.Box>

        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["14", "32"]}
            letterSpacing="wide"
            color="teal.800"
            px={[4, 10, 20]}
          >
            {harjotPageData[0]?.title}
          </Text>

          <Text
            fontWeight="bold"
            textTransform="uppercase"
            align={"right"}
            fontSize={["10", "13"]}
            letterSpacing="wide"
            color="black"
            px={[4, 10, 20]}
          >
            {harjotPageData[0]?.designation}
          </Text>

          <Text
            mt={2}
            color="gray.500"
            align={"left"}
            px={[5, 10, 20]}
            letterSpacing="wide"
            fontWeight="bold"
            py="2"
          >
            {expanded ? (
              <p>{harjotPageData[0]?.paragraph}</p>
            ) : (
              <p>{harjotPageData[0]?.paragraph?.slice(0, 850)}...</p>
            )}
            <button
              onClick={toggleExpand}
              style={{ color: "blue", fontWeight: "500" }}
            >
              {expanded ? "कम पढ़ें" : "और पढ़ें"}
            </button>

            {/* {harjotPageData[0]?.paragraph} */}
          </Text>
        </Box>
      </SimpleGrid>
    </div>
  );
};
export default ChiefMinister;
