import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const EklavyaKheloCentres = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Eklavya Khelo India Centres"
        pageTitle="Eklavya Khelo India Centres"
        id="#Eklavya-Khelo-India-Centres"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />
      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> एकलव्य केंद्र खेलो इंडिया केंद्र </h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  एकलव्य केंद्र
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              राज्य में खिलाड़ियों को उत्तम प्रशिक्षण प्रदान करने तथा उनमें खेल
              संबंधी प्रतिभा के विकास के लिए बिहार सरकार द्वारा राज्य के कई
              जिलों में खेल संबंधी केंद्रों के निर्माण का कार्य प्रगति पर है।
              राज्य भर में लगभग 41 एकलव्य केंद्रों के निर्माण के बाद राज्य में
              खेल और खिलाड़ियों को मजबूती मिलेगी। प्रतिभा से भरे बिहार के
              खिलाड़ी बेहतर प्रशिक्षण और बड़े मंच पर खेल प्रदर्शन के सुलभ अवसर
              के कारण विश्व भर में देश एवम् राज्य का नाम गौरवान्वित कर पाएंगे।
              <span style={{ color: "#ffd53f" }}>
                <a href="img/eklavya_Kendra.pdf" target="_blank">
                  {" "}
                  एकलव्य केंद्रों की सूची देखें
                  <i
                    class="fas fa-file"
                    style={{ padding: "8px", color: "#ffd53f" }}
                  ></i>
                </a>
              </span>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  खेलो इंडिया केंद्र
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              बिहार सरकार राज्य में खेल को बढ़ावा देने हेतु तीव्र गति से कार्य
              कर रही है। बिहार राज्य खेल प्राधिकरण द्वारा राज्य के युवा
              खिलाड़ियों में खेल की प्रतिभा को तराशने और उन्हें
              राष्ट्रीय/अंतर्राष्ट्रीय प्रदर्शन लायक बनाने के लिए राज्य के 33
              जिलों में प्रशिक्षकों की पूर्ति के साथ ही खेल केंद्रों का व्यापक
              स्तर पर निर्माण किया जा रहा है। इसके अंतर्गत पटना,
              मुज्जफरपुर,अररिया,जहानाबाद, पश्चिमी
              चंपारण,रोहतास,समस्तीपुर,लखीसराय,
              मधेपुरा,किशनगंज,सहरसा,औरंगाबाद,गया,भागलपुर,नालंदा,गोपालगंज,भोजपुर,शेखपुरा,सीतामढ़ी,बेगूसराय,बक्सर,कैमूर,जमुई,छपरा,सुपौल,खगड़िया,
              सिवान,कटिहार, वैशाली, मुंगेर,मधुबनी, पूर्वी चंपारण एवम् दरभंगा में
              खेल केंद्रों का निर्माण चल रहा है।
              <span style={{ color: "#ffd53f" }}>
                <a href="img/Kic.pdf" target="_blank">
                  {" "}
                  खेलो इंडिया केंद्रों की सूची देखें
                  <i
                    class="fas fa-file"
                    style={{ padding: "8px", color: "#ffd53f" }}
                  ></i>
                </a>
              </span>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default EklavyaKheloCentres;
