import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";

const KheloIndiaCenter = () => {
    return (
        <div>
            <Breadcrumb
                pageName="Organizational Structure"
                pageTitle="Organizational Structure"
                id="#organizational-structure"
                img_url="./img/dil-se-khelo-milke-jito-banner-desktop.jpg"
                mobile_banner="./img/dil-se-khelo-milke-jito-banner-mobile.jpg"
            />
            <GridItem
                paddingBottom={"30px"}
                paddingTop={"10px"}
                bgGradient="linear(to-b, #ddd4d4, gray)"
                // bgGradient="linear-gradient(to b, blue, black)"
                color={"black"}
            >
                <h1> खेलो इंडिया केंद्र </h1>
            </GridItem>

            <SimpleGrid
                column={1}
                padding={"14px"}
                paddingLeft={"20%"}
                alignContent={'center'}
                justifyContent={'center'}
                // paddingRight={"20%"}
                bg={"gray"}
            >
                <GridItem
                    borderTop={"1px solid lightblue"}
                    // borderRadius={"8px"}
                    alignItems={"center"}
                    color={"black"}
                    paddingTop={"1%"}
                    paddingBottom={"1%"}
                    marginLeft={"2%"}
                    marginRight={"2%"}
                    width={'80%'}
                    textAlign={'left'}
                    // _hover={{
                    //     bg: "white",
                    //     color: "black",
                    // }}
                    fontSize={"2rem"}
                >
                    बिहार सरकार राज्य में खेल को बढ़ावा देने हेतु तीव्र गति से कार्य कर रही है। बिहार राज्य खेल प्राधिकरण द्वारा राज्य के युवा खिलाड़ियों में खेल की प्रतिभा को तराशने और उन्हें राष्ट्रीय/अंतर्राष्ट्रीय प्रदर्शन लायक बनाने के लिए राज्य के 33 जिलों में  प्रशिक्षकों की पूर्ति के साथ ही खेल केंद्रों का व्यापक स्तर पर निर्माण किया जा रहा है। इसके अंतर्गत पटना, मुज्जफरपुर,अररिया,जहानाबाद, पश्चिमी चंपारण,रोहतास,समस्तीपुर,लखीसराय,मधेपुरा,किशनगंज,सहरसा,औरंगाबाद,गया,भागलपुर,नालंदा,गोपालगंज,भोजपुर,शेखपुरा,सीतामढ़ी,बेगूसराय,बक्सर,कैमूर,जमुई,छपरा,सुपौल,खगड़िया,सिवान,कटिहार, वैशाली, मुंगेर,मधुबनी, पूर्वी चंपारण एवम् दरभंगा में खेल केंद्रों का निर्माण चल रहा है।
                </GridItem>
            </SimpleGrid>
        </div>
    );
};
export default KheloIndiaCenter;
