import {
  Accordion,
  AccordionButton,
  Text,
  AccordionIcon,
  AccordionPanel,
  Box,
  AccordionItem,
  Center,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";

const EventsAndPrograms = () => {
  return (
    <div>
      <Breadcrumb
        pageName="State level Achievements"
        pageTitle="State level Achievements"
        id="#State-level-Achievements"
        img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"
      />
      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
        }}
      >
        <Center p={6}>
          <Accordion defaultIndex={[0]}>
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              fontSize={["18", "30"]}
              letterSpacing="wide"
              color="white"
              px={[4, 10, 20]}
            >
              आयोजन एवं कार्यक्रम
            </Text>
            <AccordionItem>
              <h2>
                <AccordionButton fontSize={["14", "24"]}>
                  <Box as="span" flex="1" textAlign="left">
                    खेल सम्मान समारोह 2022
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontWeight={"bold"}
                color={"black.600"}
              >
                हर साल 29 अगस्त को राष्ट्रीय खेल दिवस के अवसर पर बिहार सरकार उन
                एथलीटों को सम्मानित करती है जिन्होंने अंतर्राष्ट्रीय और
                राष्ट्रीय प्रतियोगिताओं में अपने उत्कृष्ट प्रदर्शन से हमारे
                राज्य को गौरवान्वित किया है। पिछले वर्ष खेल सम्मान समारोह का
                आयोजन ऊर्जा सभागार, पटना में किया गया था, जहाँ कला, संस्कृति एवं
                युवा विभाग, बिहार के तत्वावधान में बिहार राज्य खेल प्राधिकरण
                द्वारा 211 खिलाड़ियों को सम्मानित किया गया था।राष्ट्रीय खेल दिवस
                के अवसर पर राज्य के छह प्रशिक्षकों को भी सम्मानित किया गया था।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton fontSize={["10", "20"]}>
                  <Box as="span" flex="1" textAlign="left">
                    राजा कर्ण तीरंदाजी
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontWeight={"bold"}
                color={"black.600"}
              >
                प्रथम राजा कर्ण ओपन बिहार स्टेट तीरंदाजी प्रतियोगिता का आयोजन
                प्रतिवर्ष जुलाई के महीने में पाटलिपुत्र खेल परिसर ,कंकड़बाग में
                किया जाता रहा है। इस प्रतियोगिता में राज्य भर से खिलाड़ी भाग
                लेते हैं।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton fontSize={["10", "20"]}>
                  <Box as="span" flex="1" textAlign="left">
                    नीडजैम 2023
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontWeight={"bold"}
                color={"black.600"}
              >
                कम उम्र में ही देश भर से भविष्य के विजेताओं को तलाशने और तराशने
                के उद्देश्य के साथ वर्ष 2003 में एथलेटिक्स फेडरेशन ऑफ इंडिया AFI
                द्वारा NIDJAM यानि नेशनल इंटर डिस्ट्रिक्ट जूनियर ऐथेलेटिक्स मीट
                की शुरुआत हुई। निडजैम को विश्व की सबसे बड़ी बुनियादी प्रतिभा खोज
                प्रतियोगिता माना गया है। इसमें जिला स्तर पर प्रतियोगिता में एक
                लाख से ज्यादा खिलाड़ी हिस्सा लेते हैं जो अपने जिले से चुने जाने
                के बाद अंतर जिला प्रतियोगिता के लिए आते हैं। 9 से 12 फरवरी तक
                पाटलिपुत्र खेल परिसर , कंकड़बाग , पटना में आयोजित 18 वें निडजैम
                2023 में देश के 600 जिलों से 6000 से ज्यादा 14 और 16 वर्ष से कम
                आयुवर्ग के लड़के लड़कियों के साथ 1500 से ज्यादा उनके प्रशिक्षक
                और मैनेजर इस निडजैम में शामिल हुए । बिहार के 38 जिलों से 513
                खिला
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton fontSize={["10", "20"]}>
                  <Box as="span" flex="1" textAlign="left">
                    स्पोर्ट्स कॉन्क्लेव 2.0
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontWeight={"bold"}
                color={"black.600"}
              >
                स्पोर्ट्स कॉन्क्लेव 2.0 का सफल आयोजन 19 और 20 मई 2023 को पटना के
                ज्ञान भवन में हुआ । कार्यक्रम का विषय था "बिहार - एक उत्कृष्ट
                खेल केंद्र बन रहा है"। स्पोर्ट्स कॉन्क्लेव का उद्देश्य खेल के
                बुनियादी ढांचे, एथलीटों की देखभाल एवं उनका प्रबंधन, जमीनी स्तर
                के खेल विकास और ईस्पोर्ट्स से सर्वोत्तम प्रथाओं पर ध्यान
                केंद्रित करना है। सरकार द्वारा आयोजित इस कार्यक्रम में
                अधिकारियों, राष्ट्रीय और राज्य खेल महासंघों, नीति निर्माताओं,
                व्यावसायिक उद्यमों और खेल उद्योग के कई अन्य प्रमुख हितधारकों ने
                भाग लिया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton fontSize={["10", "20"]}>
                  <Box as="span" flex="1" textAlign="left">
                    दक्ष
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontWeight={"bold"}
                color={"black.600"}
              >
                दक्ष एक वार्षिक प्रतियोगिता है जिसका आयोजन प्रखंड , जिला ,
                कमिश्नरी एवम् राज्य स्तर पर किया जाता है। इस प्रतियोगिता का
                आयोजन अंडर-14, अंडर-17 और अंडर-19 आयु वर्ग के बालक और बालिका के
                लिए किया जाता है।{" "}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton fontSize={["10", "20"]}>
                  <Box as="span" flex="1" textAlign="left">
                    तरंग
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontWeight={"bold"}
                color={"black.600"}
              >
                तरंग एक स्कूल स्तरीय प्रतियोगिता है जिसका संचालन बिहार राज्य खेल
                प्राधिकरण एवम् शिक्षा विभाग के तत्वधान में किया जाता है।इस
                प्रतियोगिता में राज्य भर के सभी सरकारी एवम् प्राइवेट स्कूल के
                विद्यार्थी भाग लेते हैं। यह प्रतियोगिता अंडर-12, अंडर-14,
                अंडर-17 और अंडर-19 आयु वर्ग के विद्यार्थियों के लिए आयोजित की
                जाती है।
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Center>
      </div>
    </div>
  );
};

export default EventsAndPrograms;
