import {
  SimpleGrid,
  Square,
  Text,
  Box,
  Center,
  Container,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const SportsScholarshipPolicy = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sports Scholarship Policy | Athletic Scholarship | BSSA</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की खेल छात्रवृत्ति नीति: खिलाड़ियों को आर्थिक सहायता प्रदान करने के लिए खेल छात्रवृत्ति योजना प्रस्तावित है। प्रमुख योजनाएं हैं प्रेरणा, सक्षम, और उड़ान। "
        />
      </Helmet>
      <Breadcrumb
        pageName="Policy"
        pageTitle="Policy"
        id="#policy"
        img_url="./img/backdropImg/scholarship policy.jpg"
        mobile_banner="./img/backdropImg/scholarship policy mobile view 576 x 600.jpg"
      />

      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
        }}
      >
        <Text
          // fontWeight="bold"
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          // align={"right"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="black"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          खेल छात्रवृति नीति
        </Text>

        <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
          <Center
            as={"text"}
            h="100px"
            textAlign={"left"}
            p="3%"
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["8", "16"]}
            letterSpacing="wide"
            color="teal.800"
          >
            बिहार राज्य खेल प्राधिकरण की ओर से राज्य के खिलाड़ियों को आर्थिक
            सहायता प्रदान करने के लिए "खेल छात्रवृति" योजना का प्रस्ताव सरकार के
            समक्ष रखा गया है। प्रस्तावित खेल छात्रवृति योजनाओं की सूची इस प्रकार
            है- प्रेरणा,सक्षम,उड़ान । <br></br>
            "युवा खेल छात्रवृति" योजना का उद्देश्य राज्य के प्रतिभावान एवम्
            उत्कृष्ट खिलाड़ियों को वित्तीय सहायता प्रदान कर उनके कौशल को बढ़ाना
            है।
          </Center>
          <Center>
            <a
              href="img/SportScholarshipPolicy.pdf"
              target="_blank"
              style={{
                fontSize: "18px",
                fontWeight: "800",
                textDecoration: "none", // Remove underline from the link
                display: "inline-block", // Ensure the button takes only the necessary width
                padding: "10px 20px", // Adjust padding to your liking
                marginTop: "20px",
                marginBottom: "16px",
                backgroundColor: "#007bff", // Button background color
                color: "#fff", // Button text color
                borderRadius: "5px", // Add rounded corners to the button
                cursor: "pointer", // Change cursor to indicate it's clickable
                position: "relative",
              }}
            >
              खेल छात्रवृति नीति PDF<span> 👆</span>
            </a>
          </Center>
        </SimpleGrid>
      </div>
    </div>
  );
};
export default SportsScholarshipPolicy;
