import React from "react";
import { motion, useAnimation } from "framer-motion";

const NewsScroller = () => {
  return (
    <motion.div
    // initial={{ opacity: 0, scale: 0 }}
    // whileInView={{ opacity: 1, scale: 1 }}
    // // animate={{opacity:1, translateX:0}}
    // transition={{ duration: 0.5 }}
    >
      <motion.div class="col-sm-12 col-md-4 col-lg-4">
        <h4 class="section-heading mobile-heading" style={{ color: "white" }}>
          समाचार
        </h4>
        <div
          class="lastestNews-container"
          style={{
            scrollBehavior: "smooth",
            overflowY: "scroll",
            scrollbarColor: "teal",
            scrollbarWidth: "thin",
          }}
        >
          <style>
            {`
    ::-webkit-scrollbar {
      width: 5px; /* Adjust the width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #fcb606; /* Customize the thumb color */
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: darkteal; /* Customize the thumb color on hover */
    }
    `}
          </style>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/durga-singh-of-bihar-won-gold-medal-in-38th-national-junior-athletics-championship-2023/bh20231108083615028028387"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/DurgaSingh.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    बिहार की बेटी दुर्गा सिंह ने 38वें नेशनल जूनियर एथलेटिक्स
                    चैंपियनशिप में स्वर्ण पदक जीतकर प्रदेश का नाम रौशन किया है.
                  </h5>
                  <p class="lastestNews__content__text">
                    बिहार की बेटी दुर्गा सिंह ने 38वें नेशनल जूनियर एथलेटिक्स
                    चैंपियनशिप में स्वर्ण पदक जीतकर प्रदेश का नाम रौशन किया है.
                    दुर्गा ने अंडर 18 आयुवर्ग के 1500 मीटर दौड़ में अपना परचम
                    लहराया है. आगे पढ़ें पूरी खबर.
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.bhaskar.com/local/bihar/patna/news/grand-welcome-for-gold-medal-winner-shailesh-kumar-132103394.html"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/gold_medlist_shailesh_kumar.webp"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    शैलेश कुमार का अभिनंदन करते महानिदेशक
                  </h5>
                  <p class="lastestNews__content__text">
                    हाल ही में हांगझोऊ, चीन में आयोजित चौथे एशियन पैरा गेम्स
                    2023 में ऊंची कूद में स्वर्ण पदक विजेता रहे बिहार के जमुई
                    जिले के शैलेश कुमार के पटना वापस लौटने पर बिहार राज्य खेल
                    प्राधिकरण के महानिदेशक सह मुख्य कार्यकारी अधिकारी श्री
                    रवीन्द्रण शंकरण ने अपने आवास पर उन्हें सम्मानित किया।
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/bihar-women-rugby-team-lost-to-odisha-in-37th-national-games/bh20231028090040078078449"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/RubyGirls.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    37वें नेशनल गेम्स -2023 में बिहार की महिला रग्बी टीम ने
                    फाइनल में जीता रजत पदक
                  </h5>
                  <p class="lastestNews__content__text">
                    गोवा में चल रहे 37वें नेशनल गेम्स में बिहार की महिला रग्बी
                    टीम ने रजत पदक अपने नाम किया है। सेमीफाइनल मुकाबले में
                    महाराष्ट्र को हराकर फाइनल में जगह बनाने वाली महिला टीम को
                    ओडिशा के हाथों रोमांचक मुकाबले में हार का सामना करना पड़ा।
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/anuj-kumar-singh-and-sushant-singh-from-bihar-selected-for-training-camp-world-volleyball-championship/bh20230704123914220220314"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/vollyvall-2023.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    विश्व वॉलीबॉल चैम्पियनशिप 2023
                  </h5>
                  <p class="lastestNews__content__text">
                    बिहार के अनुज और सुशांत का भारतीय टीम के प्रशिक्षण शिविर में
                    हुआ चयन बिहार के अनुज कुमार सिंह और सुशांत सिंह का वर्ल्ड
                    वॉलीबाल चैम्पियनशिप के लिए भारतीय टीम के प्रशिक्षण शिविर में
                    चयन हुआ है.
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/heroes-of-special-olympics-world-summer-games-in-germany-warmly-welcomed-in-patna/bh20230628225936256256078"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner">
                <div class="lastestNews__image">
                  <img
                    src="img/olymic-award.webp"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    जर्मनी में स्पेशल ओलंपिक वर्ल्ड समर गेम्स के नायकों का पटना
                    में स्वागत
                  </h5>
                  <p class="lastestNews__content__text">
                    जर्मनी में स्पेशल ओलंपिक वर्ल्ड समर गेम्स के नायकों का पटना
                    में स्वागत स्पेशल ओलंपिक वर्ल्ड समर गेम्स में पदक जीत कर
                    लौटे बिहार के खिलाड़ियों का जोरदार स्वागत किया गया. एयरपोट पर
                    खिलाड़ी गजेन्द्र कुमार, सिंटू कुमार और कोच..
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a href="#" class="lastestNews__link">
              <div class="lastestNews__inner">
                <div class="lastestNews__image">
                  <img
                    src="img/jobs-img.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    बिहार में खिलाड़ियों की विभिन्न पदों पर सीधी बहाली, यहां
                    करें आवेदन
                  </h5>
                  <p class="lastestNews__content__text">
                    बिहार में खिलाड़ियों की अलग-अलग पदों पर सीधी बहाली होने वाली
                    है. राज्य के मुख्यमंत्री नीतीश कुमार की ओर से इसकी घोषणा
                    पहले ही की गई थी.
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="text-center visit-btn">
          <a
            href="https://www.bhaskar.com/local/bihar/patna/news/grand-welcome-for-gold-medal-winner-shailesh-kumar-132103394.html"
            class="btn btn-default button-link"
          >
            अधिक जानकारी के लिए <i class="bi bi-arrow-up-right-circle"></i>
          </a>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default NewsScroller;
