import {
  SimpleGrid,
  Square,
  Text,
  Box,
  Center,
  Container,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../../components/common/Breadcrumb";

const OurPolicy = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Policy"
        pageTitle="Policy"
        id="#policy"
        img_url="./img/Policy-and-Scheme-page-desktop.jpg"
        mobile_banner="./img/Policy-and-Scheme-page-mobile.jpg"
      />
      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #0a3272, white)",
        }}
      >
        <Text
          textTransform="uppercase"
          p={"4px"}
          pb={"12px"}
          fontSize={["30", "33"]}
          letterSpacing="wide"
          color="black"
          pt={[2, 5, 10]}
          px={[4, 10, 20]}
          fontWeight="extrabold"
        >
          हमारी नीतियां
        </Text>

        <SimpleGrid columns={1} spacingX={8} mb={7} mt={5}>
          <Center
            as={"text"}
            h="100px"
            textAlign={"left"}
            p="3%"
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["8", "16"]}
            letterSpacing="wide"
            color="teal.800"
          >
            इस योजना के अंतर्गत प्रत्येक वर्ष में एक बार चयनित प्रत्येक खिलाड़ी
            को 10 लाख रुपए तक की राशि वित्तीय सहायता के रूप में प्रदान की जाएगी।
          </Center>
        </SimpleGrid>
      </div>
    </div>
  );
};
export default OurPolicy;
