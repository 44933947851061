import { SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "../tenders/style.module.css";
import Container from "react-bootstrap/Container";
import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
// import Styles from "../../styles/Tender.module.css";
import Styles from "../../styles/Tender.module.css";
import { Helmet } from "react-helmet";
const Tenders = () => {
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/careerandopportunity");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    // <div>
    <div className={`container`} style={{ marginBottom: "30px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Jobs In Sports | Career In Sports | BSSA | Bihar State Sports
          Authority
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की करियर और अवसर पेज: इस पेज पर खेल क्षेत्र में विभिन्न करियर अवसरों और नौकरी की संभावनाओं की जानकारी उपलब्ध है।"
        />
      </Helmet>
      <h1 className="pt-2" style={{ marginBottom: "20px", marginTop: "20px" }}>
        नौकरियां
      </h1>
      {/* input field for search the item */}
      {/* <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for tender"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div> */}

      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col">
              क्र.सं
            </th>
            <th className={`${Styles.head}`} scope="col">
              शीर्षक
            </th>

            <th className={`${Styles.head}`} scope="col">
              आवेदन की अंतिम तिथि{" "}
            </th>
            <th
              className={`${Styles.head}`}
              scope="col"
              style={{ width: "25%" }}
            >
              अधिक जानकारी के लिए क्लिक करें
            </th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, i) => (
              <tr>
                <th scope="row" style={{ width: "3rem" }}>
                  {i + 1}
                </th>
                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.name}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  {item.expiryDate}
                </td>

                <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                  <a href={item.url} target="_blank">
                    <img
                      className={Styles.table_image}
                      src={"img/icons8-view-100.png"}
                    />
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
    // </div>
  );
};

export default Tenders;
