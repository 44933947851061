import React from "react";
import { Image, Text, Box, Link } from "@chakra-ui/react";
import { useState } from "react";
import patliputraKhel from "../../assets/images/patliputrakhel-parisar.jpg";

const ProfileCard = ({ heading, quotes, id, img_url }) => {
  // passed from common introduction
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div style={{}}>
      <Box p={4} display={{ md: "flex" }} margin={"auto"}>
        <Box flexShrink={0}>
          <Image
            borderRadius="lg"
            width="95%"
            // width="700px"
            height="400px"
            src={patliputraKhel}
            // src="https://english.cdn.zeenews.com/sites/default/files/styles/zm_700x400/public/2023/03/21/1170683-nitish-kumar-jdu.png"
            alt="Patliputrakhel parisar"
          />
        </Box>
        <Box mt={{ base: 4, md: 0 }}>
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize="4xl"
            letterSpacing="wide"
            color="teal.900"
          >
            {heading}
          </Text>
          {/* <Link
            mt={1}
            display="block"
            fontSize="lg"
            lineHeight="normal"
            fontWeight="semibold"
            href="#"
          >
            Finding customers for your new business
          </Link> */}
          <Text mt={2} color="gray.500" textAlign="left" fontWeight={"800"}>
            {expanded ? <p>{quotes}</p> : <p>{quotes?.slice(0, 1000)}...</p>}
            <button
              onClick={toggleExpand}
              style={{ color: "blue", fontWeight: "500" }}
            >
              {expanded ? "Read Less" : "Read More"}
            </button>

            {/* {quotes} */}
          </Text>
          <a
            href="img/BSSA-REGISTRATION.pdf"
            target="_blank"
            style={{
              fontSize: "18px",
              fontWeight: "800",
              textDecoration: "none", // Remove underline from the link
              display: "inline-block", // Ensure the button takes only the necessary width
              padding: "10px 20px", // Adjust padding to your liking
              backgroundColor: "#007bff", // Button background color
              color: "#fff", // Button text color
              borderRadius: "5px", // Add rounded corners to the button
              cursor: "pointer", // Change cursor to indicate it's clickable
            }}
          >
            निबंधन का प्रमाण पत्र देखें <span> 👆</span>
          </a>
        </Box>
      </Box>

      {/* <Text
        mt={2}
        paddingLeft={4}
        paddingRight={4}
        color="gray.500"
        textAlign="left"
      >
        {quotes}
      </Text> */}
    </div>
  );
};

export default ProfileCard;
