import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import style from "./Athlite.module.css";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";

function SepakTakra() {
  return (
    <>
      <Breadcrumb
        pageName={""}
        pageTitle={"SGFI"}
        img_url={"./img/nationalschoolgames_Dbanner.jpg"}
        mobile_banner="./img/nationalschool_games_mobile _banner.jpg"
      />

      <div>
        <h2 className={`${style.title}`}>
          <strong>67 वां नेशनल स्कूल गेम्स 2023-24 </strong>सेपक टाकरा
          चैंपियनशिप
        </h2>
      </div>

      <div className={`container ${style.athlite_details}`}>
        <div className={`${style.athlite_description}`}>
          <p>
            67 वें नेशनल स्कूल गेम्स 2023 के सेपक टाकरा चैंपियनशिप अंडर-17
            (बालक/बालिका वर्ग) का आयोजन
          </p>
          <p>
            दिनांक 03 जनवरी 2024 से 07 जनवरी 2024 तक बिहार की राजधानी पटना के
            कंकड़बाग में स्थित पाटलिपुत्र खेल परिसर में किया जा रहा है।
          </p>
          <p style={{ color: `rgb(30, 30, 90)`, fontWeight: "600" }}>
            सेपक टाकरा चैंपियनशिप में खिलाड़ियों के प्रवेश करने की अंतिम तिथि 24
            दिसंबर 2023 तय की गई है। इसके साथ ही इस चैंपियनशिप में हिस्सा ले रहे
            खिलाड़ियों के लिए रिपोर्टिंग की तारीख 02 जनवरी 2024 निर्धारित की गई
            है।
          </p>
          <br />
          <p>नियंत्रण कक्ष का पता: पाटलिपुत्र खेल परिसर, कंकड़बाग, पटना</p>
          <p>नियंत्रण कक्ष के प्रभारी का नाम : गजेंद्र सहनी</p>

          <p>मोबाइल नंबर: 7483241475</p>
          <br />
          <p>नोडल ऑफिसर का नाम: श्री जय नारायण कुमार, जिला खेल पदाधिकारी</p>
          <p>मोबाइल नंबर : 9661261352</p>
          <br />
        </div>
        <div className={`${style.athlite_img}`}>
          <img
            src="./img/net_sgfi.png"
            alt=""
            style={{ height: "auto", width: "30rem", padding: "2rem" }}
          />
          <p>सेपक टाकरा</p>

          <Button  variant="warning" style={{ margin: "20px", color: "black" }}>
            <a href="img/sepak_takraw.PDF" target="_blank">
              विवरण देखें
            </a>
          </Button>

        </div>
      </div>
      <a href="http://www.sgfi.org.in/" target="_blank">
        <Button variant="warning" style={{ margin: "20px", color: "black" }}>
          अधिक जानकारी के लिए क्लिक करें ↗
        </Button>
      </a>
    </>
  );
}

export default SepakTakra;
