import React, { useState, useEffect } from "react";
import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const DSODirectory = () => {
  const [dso, setDso] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/dso");
      setDso(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          District Sports Officer | Bihar State Sports Authority | BSSA
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण का DSO डायरेक्टरी पेज: इस पेज पर जिला खेल अधिकारियों (DSO) की संपर्क जानकारी और उनके संबंधित क्षेत्रों का विवरण उपलब्ध है।"
        />
      </Helmet>
      <Breadcrumb
        pageName="D.S.O-Directory"
        pageTitle="D.S.O-Directory"
        id="#dso-directory"
        img_url="./img/DSO-directory-desktop-banner.jpg"
        mobile_banner="./img/DSO-directory-mobile-banner.jpg"
      />
      <Box>
        <TableContainer
          boxShadow={"lg"}
          alignContent={"center"}
          justifyContent={"center"}
          marginRight={"4%"}
          marginLeft={"4%"}
          paddingTop={"5px"}
          paddingBottom={"10px"}
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th fontSize={"1.5rem"}>क्र.सं.</Th>
                <Th fontSize={"1.5rem"}>पदाधिकारी का नाम</Th>
                <Th fontSize={"1.5rem"}>मोबाइल नंबर</Th>
                <Th fontSize={"1.5rem"}>पदनाम</Th>
                <Th fontSize={"1.5rem"}>खेल</Th>
              </Tr>
            </Thead>

            {dso.map((item, i) => (
              <Tbody>
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item?.name}</Td>
                  <Td>{item?.mob}</Td>
                  <Td>{item?.designation}</Td>
                  <Td>{item?.game}</Td>
                </Tr>
              </Tbody>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default DSODirectory;
