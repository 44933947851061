import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";

const ComingSoon = () => {
  return (
    <div>
      <SimpleGrid
        columns={1}
        spacing={0}
        alignItems="flex-start"
        height="auto"
        w="100%"
        mt="5"
        // border={"2px solid red"}
      >
        <img
          src="https://64.media.tumblr.com/c0633cf8c0fb5f7104e084779de44a01/tumblr_nd6iaupNZA1thij9do1_1280.gif"
          width="100%"
        />
        <img
          src="https://i.pinimg.com/originals/5c/5d/66/5c5d6684644136c4b1442f1db30af6bf.gif"
          width="100%"
        />
      </SimpleGrid>
    </div>
  );
};

export default ComingSoon;
