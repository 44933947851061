import { Container, Image, Text, SimpleGrid, GridItem } from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";

const Vision_Mission = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Vision And Mission | Sports Culture | Bihar State Sprts Authority | BSSA</title>
                <meta
                    name="description"
                    content="बिहार खेल प्राधिकरण की दृष्टि और मिशन: राज्य में खेलों के विकास और प्रोत्साहन के लिए योजनाएं, उत्कृष्टता की प्राप्ति और युवा खिलाड़ियों का समग्र विकास।"
                />
            </Helmet>



            <Breadcrumb
                pageName="Vision and Mission"
                pageTitle="Vision and Mission"
                id="#mission"

                img_url="./img/Mission-and-vision-page-desktop.jpg"
                mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
            />




            <Container maxW='container' mt="10" p='0' w='95%' mb="10" >


                {/* <Flex h={{ base: 'auto', md: "100vh" }} py={[0, 10, 20]}
                    direction={{
                        base: 'column-reverse',
                        md: 'row'
                    }}
                > */}
                {/*----------------------------------- leftside */}
                <SimpleGrid columns={[1, 1, 2, 2]} columnGap={3} rowGap={2}>

                    {/* <VStack> */}

                    <Image src='img/5 Vision.jpg'></Image>

                    <GridItem colSpan={1} >



                        <Text
                            fontWeight='bold'
                            textTransform='uppercase'
                            fontSize={['18', '42']}
                            letterSpacing='wide'
                            color="teal.800"
                            px={[4, 10, 20]}
                            py='2'
                        // opacity={0.4}

                        >
                            दूरदर्शिता
                        </Text>
                        {/*------------------OUR MISSION CONTENT---------------------------  */}

                        <Text
                            fontWeight='bold'
                            py='2'
                            color='gray.500'
                        >
                            खेल को एक जन आंदोलन बनाना। खेल और खिलाड़ियों के सर्वांगीण विकास में उनकी मदद करना तथा बिहार में खेल संस्कृति का विकास करना।
                        </Text>
                    </GridItem >
                    {/* </VStack> */}



                    {/* <VStack> */}
                    <GridItem colSpan={1} >
                        <Text
                            fontWeight='bold'
                            textTransform='uppercase'
                            fontSize={['18', '42']}
                            letterSpacing='wide'
                            color="teal.800"
                            px={[4, 10, 20]}
                        >
                            लक्ष्य
                        </Text>
                        {/*------------------OUR VISION CONTENT---------------------------  */}
                        <Text py='10'
                            fontWeight='bold'
                            color='gray.500'
                        >
                            बिहार के प्रतिभावान युवा खिलाड़ियों का व्यापक स्तर पर चयन कर उन्हें  खेल के उत्तम आधारभूत संरचना प्रदान करना, उनमें खेल विज्ञान की बेहतर समझ विकसित करने के साथ उनकी संभावित प्रतिभा को तराशना और उन्हें अंतर्राष्ट्रीय स्तर की प्रतियोगिता के लिए तैयार करना।
                        </Text>

                    </GridItem >
                    <Image src='./img/vission-photo-new.jpeg' alt="vission"></Image>
                    {/* </VStack> */}




                    {/* ------------------------------------------Objective section--------------------------------- */}




                    <Image src='img/motive.jpg'></Image>

                    <GridItem colSpan={1} >



                        <Text
                            fontWeight='bold'
                            textTransform='uppercase'
                            fontSize={['18', '42']}
                            letterSpacing='wide'
                            color="teal.800"
                            px={[4, 10, 20]}
                            py='2'
                        // opacity={0.4}

                        >
                            उद्देश्य
                        </Text>
                        {/*------------------OUR MISSION CONTENT---------------------------  */}

                        <Text
                            fontWeight='bold'
                            py='2'
                            color='gray.500'
                        >

                            <ul>
                                <li>राज्य भर से प्रतिभावान खिलाड़ियों को तलाशना और तराशना।</li>
                                <li>राष्ट्रीय और अंतर्राष्ट्रीय प्रतियोगिताओं के लिए खिलाड़ियों में उचित कौशल का विकास करना।</li>
                                <li>खिलाड़ियों के निरंतर अभ्यास के लिए समय-समय पर विभिन्न स्तरों की प्रतियोगिताओं का आयोजन करना।</li>
                            </ul>

                        </Text>
                    </GridItem >




                </SimpleGrid>
                {/*----------------------------------- right side */}
                {/* </Flex> */}
            </Container>
        </div>

    )
}

export default Vision_Mission