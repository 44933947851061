import React from 'react'
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';


const SportsBudget = () => {

    return (
        <>
            <h1>खेल बजट</h1>
            return (
            <motion.div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: '200px',
                    height: '100px',
                    backgroundColor: 'blue',
                    color: 'white',
                    borderRadius: '8px',
                    padding: '16px',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
            >
                This is a floating box
            </motion.div>
            );
        </>
    )
}

export default SportsBudget